// src/pages/signup/SignUp.tsx
import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { registerUser } from "../../api/userApi";
import { useUser } from "../../context/UserContext";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const SignUpForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007882;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #005f5f;
  }
`;

const SignUpLinkContainer = styled.div`
  text-align: center;
  margin-top: 10px;

  a {
    color: #007882;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { login } = useUser();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Add validation to check if passwords match etc.
    try {
      const result = await registerUser(email, password);
      console.log(result); // Handle the response appropriately
    } catch (error) {
      console.error(error); // Handle the error appropriately
    }
  };

  const handleGoogleSuccess = async (tokenResponse: any) => {
    // Similar to the login page, send the Google token to your backend
    // Adjust this to your backend endpoint and logic
    const tokenBlob = new Blob(
      [JSON.stringify({ token: tokenResponse.credential }, null, 2)],
      { type: "application/json" }
    );
    const response = await fetch(`${API_BASE_URL}?url=googleSignIn`, {
      method: "POST",
      body: tokenBlob,
    });
    const user = await response.json();
    if (user && user.message === "Login successful") {
      login(user);
      navigate("/"); // Navigate to the homepage or dashboard
    } else {
      // Handle registration or login error
    }
  };

  const handleGoogleFailure = (error: any) => {
    // Handle Google login failure
    console.error("Google Sign In Error", error);
  };

  return (
    <SignUpContainer>
      <SignUpForm onSubmit={handleSubmit}>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <Button type="submit">Sign Up</Button>
      </SignUpForm>
      <GoogleLogin
        onSuccess={handleGoogleSuccess}
        onError={() => handleGoogleFailure}
      />
      <SignUpLinkContainer>
        Already have an account? <Link to="/login">Log in</Link>
      </SignUpLinkContainer>
    </SignUpContainer>
  );
};

export default SignUp;
