const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getMessagesBetweenUsers = async (
  userId1: number,
  userId2: number
) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=getMessages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId1, userId2 }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during fetching messages:", error);
    throw error;
  }
};

export const sendMessage = async (
  senderId: number,
  content: string,
  recipients: { from_id: number; to_id: number }[],
  attachments: { file_path: string; file_type: string }[] = [],
  recipientId: number
) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=sendMessage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        senderId,
        content,
        recipients,
        attachments,
        recipientId,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during sending message:", error);
    throw error;
  }
};
