// src/pages/profile/Profile.tsx
import React, { useState, useEffect } from "react";
import Navbar from "../../components/navigationbar/Navbar";
import styled from "styled-components";
import profilePic from "../../images/mockProfile.png";
import homePic from "../../images/mockHome.png";
import { Tab, Tabs, Typography, Box, Avatar } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { last } from "pdf-lib";
import { useUser } from "../../context/UserContext";
import { useDashboard } from "../../context/DashboardContext";
import { getUserHomes } from "../../api";

// Mock data
// Styled Components
const ProfileContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #f8f8f8;
`;

const LeftColumn = styled.div`
  width: 25%;
  padding-right: 20px;
`;

const InfoSection = styled.div`
  width: 75%;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
`;

const ProfileCardContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const ProfileImage = styled.img`
  border-radius: 12px;
  width: 100%;
  height: auto;
`;

const StyledLink = styled(Typography)`
  color: #9d86f4;
  cursor: pointer;
  margin-top: 5px;
`;

const PreapprovalCardContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 12px;
  margin-top: 20px;
`;

const UploadText = styled.p`
  margin: 0;
  color: #777;
`;

const UploadButton = styled.button`
  background-color: #9d86f4;
  color: white;
  border: none;
  border-radius: 22px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const HomeListingCard = styled.div`
  display: flex;
  background-color: white;
  padding: 16px; /* Increased padding for more spacing */
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: space-between;
  gap: 20px; /* Added gap between image and details */
  border: 1px solid #e5e5e5; /* Light border to match the mockup */
`;

const HomeListingDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Make details section grow */
`;

const HomeTitle = styled(Typography)`
  font-weight: bold;
  color: #2c2c2c;
`;

const Address = styled(Typography)`
  font-size: 14px;
  color: #494f57;
`;

const HomeDetailRow = styled.div`
  display: flex;
  gap: 16px; /* Increased gap between details */
  margin-top: 8px;
  margin-bottom: 8px;
  color: #9d86f4;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #9d86f4;
`;

const EditButton = styled.div`
  color: #9d86f4;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  padding: 8px 16px;
  border: 1px solid #9d86f4;
  border-radius: 8px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Status = styled.div<{ status: string }>`
  color: ${(props) =>
    props.status === "active"
      ? "green"
      : props.status === "sold"
      ? "red"
      : "#FFA500"};
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StatusDot = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;
const ProfileCard: React.FC = () => {
  const { user } = useUser();
  return (
    <ProfileCardContainer>
      <ProfileImage src={user?.user_profile_img} alt="profile picture" />
      <Typography variant="h6">
        {user?.first_name} {user?.last_name}
        <VerifiedIcon sx={{ color: "#9D86F4", ml: 1 }} />
      </Typography>
      <Typography variant="body2">Buyers Club</Typography>
      <Typography variant="body2">Seller Club</Typography>
      {/* <Typography variant="body2">{mockProfileInfo.email}</Typography>
      <Typography variant="body2">{mockProfileInfo.phone}</Typography> */}
      <StyledLink>Reset Password</StyledLink>
      <StyledLink>Terms & Conditions</StyledLink>
      <StyledLink>Notification Settings</StyledLink>
    </ProfileCardContainer>
  );
};

const PreapprovalCard: React.FC = () => {
  return (
    <PreapprovalCardContainer>
      <Typography variant="h6">Pre-Approval Letter</Typography>
      <UploadContainer>
        <UploadText>Drag & Drop to upload file OR</UploadText>
        <UploadButton>Browse File</UploadButton>
      </UploadContainer>
    </PreapprovalCardContainer>
  );
};

const ConnectionsTab: React.FC = () => {
  const { allConnections } = useDashboard();

  return (
    <TabContainer>
      {allConnections.map((connection) => (
        <HomeListingCard key={connection.connection_id}>
          <img
            src={process.env.PUBLIC_URL + connection.image_src}
            alt={connection.address}
            style={{
              width: "150px",
              height: "100px",
              borderRadius: "8px",
              objectFit: "cover",
            }}
          />
          <HomeListingDetails>
            <HomeTitle variant="h6">{connection.home_label}</HomeTitle>
            <Address variant="body2">{connection.address}</Address>
            <HomeDetailRow>
              <DetailItem>{connection.beds} Beds</DetailItem>
              <DetailItem>{connection.baths} Baths</DetailItem>
              <DetailItem>{connection.square_footage} sqft</DetailItem>
            </HomeDetailRow>
            <Status status={connection.connection_status.toLowerCase()}>
              <StatusDot color="green" />
              {connection.connection_status}
            </Status>
          </HomeListingDetails>
          {/* <EditButton>Edit</EditButton> */}
        </HomeListingCard>
      ))}
    </TabContainer>
  );
};
const ListingTab: React.FC<{ homes: any[] }> = ({ homes }) => {
  return (
    <TabContainer>
      {homes.map((home) => (
        <HomeListingCard key={home.id}>
          <img
            src={process.env.PUBLIC_URL + home.images[0].image_src}
            alt={home.name}
            style={{
              width: "150px",
              height: "100px",
              borderRadius: "8px",
              objectFit: "cover",
            }}
          />
          <HomeListingDetails>
            <HomeTitle variant="h6">{home.home_label}</HomeTitle>
            <Address variant="body2">{home.address}</Address>
            <HomeDetailRow>
              <DetailItem>{home.beds} Beds</DetailItem>
              <DetailItem>{home.baths} Baths</DetailItem>
              <DetailItem>{home.square_footage} sqft</DetailItem>
            </HomeDetailRow>
            <Status status={home.home_status.toLowerCase()}>
              <StatusDot color="green" />
              {home.home_status}
            </Status>
          </HomeListingDetails>
          <EditButton>Edit</EditButton>
        </HomeListingCard>
      ))}
    </TabContainer>
  );
};
interface ColumnProps {
  children: React.ReactNode;
}

const ProfileLeftColumn: React.FC<ColumnProps> = ({ children }) => {
  return <LeftColumn>{children}</LeftColumn>;
};

const ProfileInfoSection: React.FC<ColumnProps> = ({ children }) => {
  return <InfoSection>{children}</InfoSection>;
};

const ProfilePage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [homes, setHomes] = useState<any[]>([]);
  const { user } = useUser();
  const fetchUserHomes = async () => {
    if (!user) return;
    const fetchHomes = await getUserHomes(user.user_id);
    const userHomes = fetchHomes?.data || [];
    setHomes(userHomes);
  };

  useEffect(() => {
    fetchUserHomes();
  }, [user]);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Navbar />
      <ProfileContainer>
        <ProfileLeftColumn>
          <ProfileCard />
          <PreapprovalCard />
        </ProfileLeftColumn>
        <ProfileInfoSection>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#9D86F4" } }}
          >
            <Tab
              label="Connections"
              style={{ color: selectedTab === 0 ? "#9D86F4" : "inherit" }}
            />
            <Tab
              label="My Listings"
              style={{ color: selectedTab === 1 ? "#9D86F4" : "inherit" }}
            />
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            <ConnectionsTab />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <ListingTab homes={homes} />
          </TabPanel>
        </ProfileInfoSection>
      </ProfileContainer>
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export default ProfilePage;
