// src/taskModals/modals/FHAFinancingModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const FHAFinancingModal: Modal = {
  modalId: ModalID.FHAFinancing,
  title: "FHA Financing",
  formFields: [
    { fieldId: FieldID.loan_amount },
    { fieldId: FieldID.interest_rate },
    { fieldId: FieldID.term_of_note },
    // Add other FHA-specific fields
  ],
};
