import React, { useState } from "react";
import styled from "styled-components";
import FolderIcon from "@mui/icons-material/Folder";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SearchIcon from "@mui/icons-material/Search";

const mockData = [
  {
    document_id: 1,
    document_name: "Buyer Required Documents",
    document_type: "PDF",
    document_status: "completed",
    connection_id: 101,
    upload_date: "2024-03-20",
    field_id: 1,
    document_label: "Important",
    uploading_user: 2,
    accessing_user: 3,
    document_due_date: "2024-03-24",
  },
  {
    document_id: 2,
    document_name: "Home Inspection",
    document_type: "DOCX",
    document_status: "in_progress",
    connection_id: 102,
    upload_date: "2024-03-18",
    field_id: 2,
    document_label: "Inspection",
    uploading_user: 3,
    accessing_user: 4,
    document_due_date: "2024-03-24",
  },
  {
    document_id: 3,
    document_name: "Environmental Assessments",
    document_type: "PDF",
    document_status: "requested",
    connection_id: 103,
    upload_date: "2024-03-15",
    field_id: 3,
    document_label: "Assessment",
    uploading_user: 4,
    accessing_user: 2,
    document_due_date: "2024-03-24",
  },
  {
    document_id: 4,
    document_name: "Neighborhood and Community Research",
    document_type: "XLSX",
    document_status: "completed",
    connection_id: 104,
    upload_date: "2024-03-17",
    field_id: 4,
    document_label: "Research",
    uploading_user: 5,
    accessing_user: 6,
    document_due_date: "2024-03-24",
  },
  {
    document_id: 5,
    document_name: "Financing Contingency",
    document_type: "PDF",
    document_status: "draft",
    connection_id: 105,
    upload_date: "2024-03-21",
    field_id: 5,
    document_label: "Finance",
    uploading_user: 1,
    accessing_user: 7,
    document_due_date: "2024-03-24",
  },
  {
    document_id: 6,
    document_name: "Property Appraisal",
    document_type: "PDF",
    document_status: "completed",
    connection_id: 106,
    upload_date: "2024-03-19",
    field_id: 6,
    document_label: "Appraisal",
    uploading_user: 6,
    accessing_user: 1,
    document_due_date: "2024-03-24",
  },
  {
    document_id: 7,
    document_name: "Escrow Setup",
    document_type: "PDF",
    document_status: "in_progress",
    connection_id: 107,
    upload_date: "2024-03-22",
    field_id: 7,
    document_label: "Escrow",
    uploading_user: 2,
    accessing_user: 5,
    document_due_date: "2024-03-24",
  },
  {
    document_id: 8,
    document_name: "Home Warranty Plan",
    document_type: "PDF",
    document_status: "uploaded",
    connection_id: 108,
    upload_date: "2024-03-18",
    field_id: 8,
    document_label: "Warranty",
    uploading_user: 7,
    accessing_user: 4,
    document_due_date: "2024-03-24",
  },
  {
    document_id: 9,
    document_name: "Sale of Current Home",
    document_type: "PDF",
    document_status: "update_requested",
    connection_id: 109,
    upload_date: "2024-03-16",
    field_id: 9,
    document_label: "Sale",
    uploading_user: 3,
    accessing_user: 6,
    document_due_date: "2024-03-24",
  },
  {
    document_id: 10,
    document_name: "Rental Lease Termination",
    document_type: "DOCX",
    document_status: "completed",
    connection_id: 110,
    upload_date: "2024-03-20",
    field_id: 10,
    document_label: "Lease",
    uploading_user: 4,
    accessing_user: 1,
    document_due_date: "2024-03-24",
  },
];

const PageContainer = styled.div`
  padding: 20px;
  background-color: white;
  height: 70vh;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #333;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 8px 12px;
  border-radius: 22px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  margin-left: 8px;

  &::placeholder {
    color: #aaa;
  }
`;

const FolderList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  padding-right: 10px;
`;

const FolderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const FolderDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FolderName = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #333;
`;

const FolderDate = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  gap: 5px;
`;

const DocumentsPage = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredDocuments = mockData.filter(
    (document) =>
      document.document_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      document.document_label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <PageContainer>
      <Header>
        <Title>Document Folders</Title>
        <SearchContainer>
          <SearchIcon style={{ color: "#aaa" }} />
          <SearchInput
            type="text"
            placeholder="Search documents..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </SearchContainer>
      </Header>
      <FolderList>
        {filteredDocuments.map((document) => (
          <FolderItem key={document.document_id}>
            <FolderDetails>
              <FolderIcon style={{ color: "#FFC107", fontSize: 36 }} />
              <FolderName>{document.document_name}</FolderName>
            </FolderDetails>
            <FolderDate>
              <CalendarTodayIcon style={{ fontSize: 16, color: "#666" }} />
              {new Date(document.document_due_date).toLocaleDateString(
                "en-GB",
                {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                }
              )}
            </FolderDate>
          </FolderItem>
        ))}
      </FolderList>
    </PageContainer>
  );
};

export default DocumentsPage;
