// src/taskSystem/tasks/sellerCounterTask.tsx

import { Subtask, Task, UserRoleRequirements, FormField, Modal } from "./tasks";

export const sellerCounterTask: Subtask[] = [
  {
    taskId: null,
    offerId: null,
    dealId: null,
    taskTitle: "Seller Submit Offer",
    label: "Seller Submit Offer",
    date: null,
    status: "pending",
    state: "MD",
    modalId: 3,
    taskType: "counter",
    description: "Seller Submit Offer",
    seller: {
      required: true,
      optional: false,
      inputType: "modal",
      documentId: null,
      modalId: null,
      completed: true,
    },
    buyer: {
      required: false,
      optional: false,
      inputType: "modal",
      documentId: null,
      modalId: 1,
      completed: true,
    },
    agent: {
      required: false,
      optional: true,
      inputType: "modal",
      documentId: null,
      modalId: null,
      completed: true,
    },
  },
];
