// src/taskModals/modals/ConventionalFinancingModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const ConventionalFinancingModal: Modal = {
  modalId: ModalID.ConventionalFinancing,
  title: "Conventional Financing",
  formFields: [
    // Title
    {
      content: `<h1>CONVENTIONAL FINANCING</h1>`,
    },
    // Section 1: LOAN DETAILS
    {
      content: `<h2>1. LOAN DETAILS:</h2>`,
    },
    {
      fieldId: FieldID.loan_amount,
    },
    {
      fieldId: FieldID.term_of_note,
    },
    {
      fieldId: FieldID.interest_rate,
    },
    {
      fieldId: FieldID.loan_program,
    },
    {
      content: `<p><strong>Buyer agrees to pay Loan Origination/Discount Fees:</strong></p>`,
    },
    {
      fieldId: FieldID.loan_origination_fee,
    },
    {
      content: ` / `,
    },
    {
      fieldId: FieldID.loan_discount_fee,
    },
    {
      content: `% of the loan amount</p>`,
    },
    // Section 2: LOAN INSURANCE PREMIUMS
    {
      content: `<h2>2. LOAN INSURANCE PREMIUMS:</h2>`,
    },
    {
      content: `<p>Buyer shall pay all loan insurance premiums required by the Lender.</p>`,
    },
    // Section 3: LOCK-IN
    {
      content: `<h2>3. LOCK-IN:</h2>`,
    },
    {
      content: `<p>
        Buyer accepts that the interest rate and loan discount fees are not locked
        in. Buyer agrees to accept the rate as charged by Lender at the time of
        lock-in and is responsible for any additional fees charged.
      </p>`,
    },
    // Section 4: MONTHLY PAYMENT
    {
      content: `<h2>4. MONTHLY PAYMENT:</h2>`,
    },
    {
      content: `<p>
        Payments to the Lender may include monthly principal and interest, plus
        one-twelfth of the annual real estate taxes, ground rent, special
        assessments or charges, hazard (fire) insurance premium, flood insurance,
        and private mortgage insurance.
      </p>`,
    },
    // Section 5: LOAN UNDERWRITING
    {
      content: `<h2>5. LOAN UNDERWRITING:</h2>`,
    },
    {
      content: `<p>
        Buyer agrees to comply with Lender's request for additional or updated
        information to approve the loan if such changes do not conflict with the
        Contract's conditions.
      </p>`,
    },
    // Section 6: LENDER REQUIRED REPAIRS
    {
      content: `<h2>6. LENDER REQUIRED REPAIRS:</h2>`,
    },
    {
      content: `<p>
        Seller agrees to perform and pay for Required Repairs to the Property,
        provided the total cost does not exceed
      </p>`,
    },
    {
      fieldId: FieldID.lender_required_repair_amount,
    },
    {
      content: `. If the price exceeds this amount,
        Seller may elect to pay the total cost, terminate the Contract, or Buyer
        may pay the difference. If neither party chooses to pay, the Contract
        becomes null and void.
      </p>`,
    },
    // Section 7: APPRAISAL CONTINGENCY
    {
      content: `<h2>7. APPRAISAL CONTINGENCY:</h2>`,
    },
    {
      content: `<p>
        Buyer agrees to obtain an appraisal of the Property at Buyer's expense. If
        the appraised value is less than the Purchase Price, the Buyer may proceed
        with the purchase at the Purchase Price or request a reduction. The seller
        may agree to reduce the Purchase Price or decline it. If Seller declines
        or fails to respond, Buyer may declare the Contract null and void or agree
        to purchase at the Purchase Price.
      </p>`,
    },
    // Line Break
    {
      content: `<br />`,
    },
    // Buyer Signatures
    {
      content: `<div style="display: flex; justify-content: space-between">`,
    },
    {
      content: `<div>`,
    },
    {
      fieldId: FieldID.buyer_conventional_financing_signature,
      options: { buyerOnly: true },
    },

    {
      fieldId: FieldID.buyer_conventional_financing_signature_date,
      options: { buyerOnly: true },
    },
    {
      fieldId: FieldID.buyer_two_conventional_financing_signature,
      options: { buyerOnly: true },
    },

    {
      fieldId: FieldID.buyer_two_conventional_financing_signature_date,
      options: { buyerOnly: true },
    },
    // Line Break
    {
      content: `<br />`,
    },
    // Seller Signatures
    {
      content: `<div style="display: flex; justify-content: space-between">`,
    },
    {
      content: `<div>`,
    },
    {
      fieldId: FieldID.seller_conventional_financing_signature,
      options: { sellerOnly: true },
    },

    {
      fieldId: FieldID.seller_conventional_financing_signature_date,
      options: { sellerOnly: true },
    },

    {
      fieldId: FieldID.seller_two_conventional_financing_signature,
      options: { sellerOnly: true },
    },
    {
      fieldId: FieldID.seller_two_conventional_financing_signature_date,
      options: { sellerOnly: true },
    },
    {
      content: `</div>`,
    },
    {
      content: `</div>`,
    },
  ],
};
