import { FieldID } from "../../taskFields/fieldIds";
import { Modal } from "../../tasks/tasks";

export const SellerReviewOffer: Modal = {
  modalId: 2, // Assuming 1 is for Buyer Offer
  title: "Seller Counter Offer",
  approveButton: true,
  declineButton: true,
  counterButton: true,
  isNegotiation: true,
  displayOnly: true,
  formFields: [
    { fieldId: FieldID.purchase_price },
    { fieldId: FieldID.earnest_money_deposit },
    { fieldId: FieldID.financing_contingency },
    { fieldId: FieldID.home_inspection_contingency },
    { fieldId: FieldID.radon_inspection },
    { fieldId: FieldID.structural_mechanical_inspection },
    { fieldId: FieldID.mold_inspection },
    { fieldId: FieldID.environmental_inspection },
    { fieldId: FieldID.chimney_inspection },
    { fieldId: FieldID.lead_based_paint_disclosure },
    { fieldId: FieldID.wood_destroying_insect_inspection },
    { fieldId: FieldID.due_diligence_amount, options: { hidden: true } },
    { fieldId: FieldID.additional_offer_conditions },
    // Add other fields as necessary
  ],
};
