import { FieldID } from "./fieldIds";
import { DataField } from "../tasks/tasks";

export const dataFieldList: DataField[] = [
  {
    fieldId: FieldID.buyer_first_name,
    fieldName: "buyer_first_name",
    fieldType: "text",
    label: "Buyer's First Name",
  },
  {
    fieldId: FieldID.buyer_last_name,
    fieldName: "buyer_last_name",
    fieldType: "text",
    label: "Buyer's Last Name",
  },
  {
    fieldId: FieldID.buyer_middle_name,
    fieldName: "buyer_middle_name",
    fieldType: "text",
    label: "Buyer's Middle Name",
  },
  {
    fieldId: FieldID.buyer_email,
    fieldName: "buyer_email",
    fieldType: "text",
    label: "Buyer's Email",
  },
  {
    fieldId: FieldID.buyer_phone,
    fieldName: "buyer_phone",
    fieldType: "text",
    label: "Buyer's Phone Number",
  },
  {
    fieldId: FieldID.buyer_two_first_name,
    fieldName: "buyer_two_first_name",
    fieldType: "text",
    label: "Buyer Two's First Name",
  },
  {
    fieldId: FieldID.buyer_two_last_name,
    fieldName: "buyer_two_last_name",
    fieldType: "text",
    label: "Buyer Two's Last Name",
  },
  {
    fieldId: FieldID.buyer_two_middle_name,
    fieldName: "buyer_two_middle_name",
    fieldType: "text",
    label: "Buyer Two's Middle Name",
  },
  {
    fieldId: FieldID.buyer_two_email,
    fieldName: "buyer_two_email",
    fieldType: "text",
    label: "Buyer Two's Email",
  },
  {
    fieldId: FieldID.buyer_two_phone,
    fieldName: "buyer_two_phone",
    fieldType: "text",
    label: "Buyer Two's Phone Number",
  },
  {
    fieldId: FieldID.buyer_mailing_address,
    fieldName: "buyer_mailing_address",
    fieldType: "text",
    label: "Buyer's Mailing Address",
  },
  {
    fieldId: FieldID.seller_first_name,
    fieldName: "seller_first_name",
    fieldType: "text",
    label: "Seller's First Name",
  },
  {
    fieldId: FieldID.seller_last_name,
    fieldName: "seller_last_name",
    fieldType: "text",
    label: "Seller's Last Name",
  },
  {
    fieldId: FieldID.seller_middle_name,
    fieldName: "seller_middle_name",
    fieldType: "text",
    label: "Seller's Middle Name",
  },
  {
    fieldId: FieldID.seller_email,
    fieldName: "seller_email",
    fieldType: "text",
    label: "Seller's Email",
  },
  {
    fieldId: FieldID.seller_phone,
    fieldName: "seller_phone",
    fieldType: "text",
    label: "Seller's Phone Number",
  },
  {
    fieldId: FieldID.seller_two_first_name,
    fieldName: "seller_two_first_name",
    fieldType: "text",
    label: "Seller Two's First Name",
  },
  {
    fieldId: FieldID.seller_two_last_name,
    fieldName: "seller_two_last_name",
    fieldType: "text",
    label: "Seller Two's Last Name",
  },
  {
    fieldId: FieldID.seller_two_middle_name,
    fieldName: "seller_two_middle_name",
    fieldType: "text",
    label: "Seller Two's Middle Name",
  },
  {
    fieldId: FieldID.seller_two_email,
    fieldName: "seller_two_email",
    fieldType: "text",
    label: "Seller Two's Email",
  },
  {
    fieldId: FieldID.seller_two_phone,
    fieldName: "seller_two_phone",
    fieldType: "text",
    label: "Seller Two's Phone Number",
  },
  {
    fieldId: FieldID.seller_mailing_address,
    fieldName: "seller_mailing_address",
    fieldType: "text",
    label: "Seller's Mailing Address",
  },
  {
    fieldId: FieldID.property_address_line_1,
    fieldName: "property_address_line_1",
    fieldType: "text",
    label: "Property Address Line 1",
  },
  {
    fieldId: FieldID.property_address_line_2,
    fieldName: "property_address_line_2",
    fieldType: "text",
    label: "Property Address Line 2",
  },
  {
    fieldId: FieldID.property_city,
    fieldName: "property_city",
    fieldType: "text",
    label: "Property City",
  },
  {
    fieldId: FieldID.property_state,
    fieldName: "property_state",
    fieldType: "text",
    label: "Property State",
  },
  {
    fieldId: FieldID.property_zip,
    fieldName: "property_zip",
    fieldType: "text",
    label: "Property ZIP Code",
  },
  {
    fieldId: FieldID.property_type,
    fieldName: "property_type",
    fieldType: "text",
    label: "Property Type",
  },
  {
    fieldId: FieldID.property_price,
    fieldName: "property_price",
    fieldType: "currency",
    label: "Property Price",
  },
  {
    fieldId: FieldID.property_ground_rent,
    fieldName: "property_ground_rent",
    fieldType: "currency",
    label: "Ground Rent",
  },
  {
    fieldId: FieldID.property_inclusions,
    fieldName: "property_inclusions",
    fieldType: "text",
    label: "Property Inclusions",
  },
  {
    fieldId: FieldID.property_exclusions,
    fieldName: "property_exclusions",
    fieldType: "text",
    label: "Property Exclusions",
  },
  {
    fieldId: FieldID.purchase_price,
    fieldName: "purchase_price",
    fieldType: "currency",
    label: "Purchase Price",
  },
  {
    fieldId: FieldID.earnest_money_deposit,
    fieldName: "earnest_money_deposit",
    fieldType: "currency",
    label: "Earnest Money Deposit",
  },
  {
    fieldId: FieldID.earnest_money_held_by,
    fieldName: "earnest_money_held_by",
    fieldType: "text",
    label: "Earnest Money Held By",
  },
  {
    fieldId: FieldID.financing_contingency,
    fieldName: "financing_contingency",
    fieldType: "boolean",
    label: "Financing Contingency",
  },
  {
    fieldId: FieldID.appraisal_contingency,
    fieldName: "appraisal_contingency",
    fieldType: "boolean",
    label: "Appraisal Contingency",
  },
  {
    fieldId: FieldID.settlement_date,
    fieldName: "settlement_date",
    fieldType: "date",
    label: "Settlement Date",
  },
  {
    fieldId: FieldID.home_inspection_contingency,
    fieldName: "home_inspection_contingency",
    fieldType: "boolean",
    label: "Home Inspection Contingency",
  },
  {
    fieldId: FieldID.inspection_due_date,
    fieldName: "inspection_due_date",
    fieldType: "date",
    label: "Inspection Due Date",
  },
  {
    fieldId: FieldID.contingencies,
    fieldName: "contingencies",
    fieldType: "text",
    label: "Contingencies",
  },
  {
    fieldId: FieldID.escrow_company,
    fieldName: "escrow_company",
    fieldType: "text",
    label: "Escrow Company",
  },
  {
    fieldId: FieldID.escrow_company_contact,
    fieldName: "escrow_company_contact",
    fieldType: "text",
    label: "Escrow Company Contact",
  },
  {
    fieldId: FieldID.escrow_company_phone,
    fieldName: "escrow_company_phone",
    fieldType: "text",
    label: "Escrow Company Phone",
  },
  {
    fieldId: FieldID.deposit_in_escrow,
    fieldName: "deposit_in_escrow",
    fieldType: "boolean",
    label: "Deposit in Escrow",
  },
  {
    fieldId: FieldID.payment_method,
    fieldName: "payment_method",
    fieldType: "text",
    label: "Payment Method",
  },
  {
    fieldId: FieldID.disclosures,
    fieldName: "disclosures",
    fieldType: "text",
    label: "Disclosures",
  },
  {
    fieldId: FieldID.general_contract_provisions,
    fieldName: "general_contract_provisions",
    fieldType: "boolean",
    label: "General Contract Provisions",
  },
  {
    fieldId: FieldID.buyer_pre_approval_documentation,
    fieldName: "buyer_pre_approval_documentation",
    fieldType: "boolean",
    label: "Buyer Pre-Approval Documentation",
  },
  {
    fieldId: FieldID.property_disclosures,
    fieldName: "property_disclosures",
    fieldType: "boolean",
    label: "Property Disclosures",
  },
  {
    fieldId: FieldID.hoa_disclosure,
    fieldName: "hoa_disclosure",
    fieldType: "boolean",
    label: "HOA Disclosure",
  },
  {
    fieldId: FieldID.lead_based_paint_disclosure,
    fieldName: "lead_based_paint_disclosure",
    fieldType: "boolean",
    label: "Lead-Based Paint Disclosure",
  },
  {
    fieldId: FieldID.flood_zone_disclosure,
    fieldName: "flood_zone_disclosure",
    fieldType: "boolean",
    label: "Flood Zone Disclosure",
  },
  {
    fieldId: FieldID.environmental_disclosure,
    fieldName: "environmental_disclosure",
    fieldType: "boolean",
    label: "Environmental Disclosure",
  },
  {
    fieldId: FieldID.conservation_easement_disclosure,
    fieldName: "conservation_easement_disclosure",
    fieldType: "boolean",
    label: "Conservation Easement Disclosure",
  },
  {
    fieldId: FieldID.foreclosure_protection_notice,
    fieldName: "foreclosure_protection_notice",
    fieldType: "boolean",
    label: "Foreclosure Protection Notice",
  },
  {
    fieldId: FieldID.electronic_execution,
    fieldName: "electronic_execution",
    fieldType: "boolean",
    label: "Electronic Execution",
  },
  {
    fieldId: FieldID.property_condition_as_is,
    fieldName: "property_condition_as_is",
    fieldType: "boolean",
    label: "Property Condition As-Is",
  },
  {
    fieldId: FieldID.assignment_allowed,
    fieldName: "assignment_allowed",
    fieldType: "boolean",
    label: "Assignment Allowed",
  },
  {
    fieldId: FieldID.mediation_agreement,
    fieldName: "mediation_agreement",
    fieldType: "boolean",
    label: "Mediation Agreement",
  },
  {
    fieldId: FieldID.contract_summary,
    fieldName: "contract_summary",
    fieldType: "text",
    label: "Contract Summary",
  },
  {
    fieldId: FieldID.radon_inspection,
    fieldName: "radon_inspection",
    fieldType: "boolean",
    label: "Radon Inspection",
  },
  {
    fieldId: FieldID.structural_mechanical_inspection,
    fieldName: "structural_mechanical_inspection",
    fieldType: "boolean",
    label: "Structural and Mechanical Inspection",
  },
  {
    fieldId: FieldID.mold_inspection,
    fieldName: "mold_inspection",
    fieldType: "boolean",
    label: "Mold Inspection",
  },
  {
    fieldId: FieldID.environmental_inspection,
    fieldName: "environmental_inspection",
    fieldType: "boolean",
    label: "Environmental Inspection",
  },
  {
    fieldId: FieldID.chimney_inspection,
    fieldName: "chimney_inspection",
    fieldType: "boolean",
    label: "Chimney Inspection",
  },
  {
    fieldId: FieldID.wood_destroying_insect_inspection,
    fieldName: "wood_destroying_insect_inspection",
    fieldType: "boolean",
    label: "Wood Destroying Insect Inspection",
  },
  {
    fieldId: FieldID.due_diligence_amount,
    fieldName: "due_diligence_amount",
    fieldType: "currency",
    label: "Due Diligence Amount",
  },
  {
    fieldId: FieldID.additional_offer_conditions,
    fieldName: "additional_offer_conditions",
    fieldType: "text",
    label: "Additional Offer Conditions",
  },
  {
    fieldId: FieldID.task_type,
    fieldName: "task_type",
    fieldType: "text",
    label: "Task Type",
  },
  // New Fields
  {
    fieldId: FieldID.loan_amount,
    fieldName: "loan_amount",
    fieldType: "currency",
    label: "Loan Amount",
  },
  {
    fieldId: FieldID.term_of_note,
    fieldName: "term_of_note",
    fieldType: "number",
    label: "Term of Note",
  },
  {
    fieldId: FieldID.interest_rate,
    fieldName: "interest_rate",
    fieldType: "number",
    label: "Interest Rate",
  },
  {
    fieldId: FieldID.loan_program,
    fieldName: "loan_program",
    fieldType: "text",
    label: "Loan Program",
  },
  {
    fieldId: FieldID.loan_origination_fee,
    fieldName: "loan_origination_fee",
    fieldType: "currency",
    label: "Loan Origination Fee",
  },
  {
    fieldId: FieldID.loan_discount_fee,
    fieldName: "loan_discount_fee",
    fieldType: "currency",
    label: "Loan Discount Fee",
  },
  {
    fieldId: FieldID.base_loan_amount,
    fieldName: "base_loan_amount",
    fieldType: "currency",
    label: "Base Loan Amount",
  },
  {
    fieldId: FieldID.initial_interest_rate,
    fieldName: "initial_interest_rate",
    fieldType: "number",
    label: "Initial Interest Rate",
  },
  {
    fieldId: FieldID.total_loan_amount,
    fieldName: "total_loan_amount",
    fieldType: "currency",
    label: "Total Loan Amount",
  },
  {
    fieldId: FieldID.mortgage_insurance_premium_amount,
    fieldName: "mortgage_insurance_premium_amount",
    fieldType: "currency",
    label: "Mortgage Insurance Premium Amount",
  },
  {
    fieldId: FieldID.lender_required_repair_amount,
    fieldName: "lender_required_repair_amount",
    fieldType: "currency",
    label: "Lender Required Repair Amount",
  },
  {
    fieldId: FieldID.buyer_appraisal_contingency_signature,
    fieldName: "buyer_appraisal_contingency_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Appraisal Contingency Signature",
  },
  {
    fieldId: FieldID.buyer_appraisal_contingency_signature_date,
    fieldName: "buyer_appraisal_contingency_signature_date",
    fieldType: "date",
    label: "Buyer Appraisal Contingency Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_appraisal_contingency_signature,
    fieldName: "buyer_two_appraisal_contingency_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Appraisal Contingency Signature",
  },
  {
    fieldId: FieldID.buyer_two_appraisal_contingency_signature_date,
    fieldName: "buyer_two_appraisal_contingency_signature_date",
    fieldType: "date",
    label: "Buyer 2 Appraisal Contingency Signature Date",
  },
  {
    fieldId: FieldID.buyer_fha_financing_signature,
    fieldName: "buyer_fha_financing_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer FHA Financing Signature",
  },
  {
    fieldId: FieldID.buyer_fha_financing_signature_date,
    fieldName: "buyer_fha_financing_signature_date",
    fieldType: "date",
    label: "Buyer FHA Financing Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_fha_financing_signature,
    fieldName: "buyer_two_fha_financing_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 FHA Financing Signature",
  },
  {
    fieldId: FieldID.buyer_two_fha_financing_signature_date,
    fieldName: "buyer_two_fha_financing_signature_date",
    fieldType: "date",
    label: "Buyer 2 FHA Financing Signature Date",
  },
  {
    fieldId: FieldID.buyer_va_financing_signature,
    fieldName: "buyer_va_financing_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer VA Financing Signature",
  },
  {
    fieldId: FieldID.buyer_va_financing_signature_date,
    fieldName: "buyer_va_financing_signature_date",
    fieldType: "date",
    label: "Buyer VA Financing Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_va_financing_signature,
    fieldName: "buyer_two_va_financing_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 VA Financing Signature",
  },
  {
    fieldId: FieldID.buyer_two_va_financing_signature_date,
    fieldName: "buyer_two_va_financing_signature_date",
    fieldType: "date",
    label: "Buyer 2 VA Financing Signature Date",
  },
  {
    fieldId: FieldID.inspection_days,
    fieldName: "inspection_days",
    fieldType: "number",
    label: "Number of Days to Complete Inspection",
  },
  {
    fieldId: FieldID.buyer_structural_mechanical_initial,
    fieldName: "buyer_structural_mechanical_initial",
    fieldType: "initials",
    label: "Buyer Structural and Mechanical Initial",
  },
  {
    fieldId: FieldID.buyer_mold_initial,
    fieldName: "buyer_mold_initial",
    fieldType: "initials",
    label: "Buyer Mold Initial",
  },
  {
    fieldId: FieldID.buyer_environmental_initial,
    fieldName: "buyer_environmental_initial",
    fieldType: "initials",
    label: "Buyer Environmental Initial",
  },
  {
    fieldId: FieldID.buyer_radon_initial,
    fieldName: "buyer_radon_initial",
    fieldType: "initials",
    label: "Buyer Radon Initial",
  },
  {
    fieldId: FieldID.buyer_chimney_initial,
    fieldName: "buyer_chimney_initial",
    fieldType: "initials",
    label: "Buyer Chimney Initial",
  },
  {
    fieldId: FieldID.buyer_lead_based_paint_initial,
    fieldName: "buyer_lead_based_paint_initial",
    fieldType: "initials",
    label: "Buyer Lead-Based Paint and Hazard Inspection Initial",
  },
  {
    fieldId: FieldID.buyer_wood_destroying_insect_initial,
    fieldName: "buyer_wood_destroying_insect_initial",
    fieldType: "initials",
    label: "Buyer Wood Destroying Insect Initial",
  },
  {
    fieldId: FieldID.buyer_additional_inspections_initial,
    fieldName: "buyer_additional_inspections_initial",
    fieldType: "initials",
    label: "Buyer Additional Inspections Initial",
  },
  {
    fieldId: FieldID.buyer_two_structural_mechanical_initial,
    fieldName: "buyer_two_structural_mechanical_initial",
    fieldType: "initials",
    label: "Buyer 2 Structural and Mechanical Initial",
  },
  {
    fieldId: FieldID.buyer_two_mold_initial,
    fieldName: "buyer_two_mold_initial",
    fieldType: "initials",
    label: "Buyer 2 Mold Initial",
  },
  {
    fieldId: FieldID.buyer_two_environmental_initial,
    fieldName: "buyer_two_environmental_initial",
    fieldType: "initials",
    label: "Buyer 2 Environmental Initial",
  },
  {
    fieldId: FieldID.buyer_two_radon_initial,
    fieldName: "buyer_two_radon_initial",
    fieldType: "initials",
    label: "Buyer 2 Radon Initial",
  },
  {
    fieldId: FieldID.buyer_two_chimney_initial,
    fieldName: "buyer_two_chimney_initial",
    fieldType: "initials",
    label: "Buyer 2 Chimney Initial",
  },
  {
    fieldId: FieldID.buyer_two_lead_based_paint_initial,
    fieldName: "buyer_two_lead_based_paint_initial",
    fieldType: "initials",
    label: "Buyer 2 Lead-Based Paint and Hazard Inspection Initial",
  },
  {
    fieldId: FieldID.buyer_two_wood_destroying_insect_initial,
    fieldName: "buyer_two_wood_destroying_insect_initial",
    fieldType: "initials",
    label: "Buyer 2 Wood Destroying Insect Initial",
  },
  {
    fieldId: FieldID.buyer_two_additional_inspections_initial,
    fieldName: "buyer_two_additional_inspections_initial",
    fieldType: "initials",
    label: "Buyer 2 Additional Inspections Initial",
  },
  {
    fieldId: FieldID.buyer_property_inspection_signature,
    fieldName: "buyer_property_inspection_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Property Inspection Signature",
  },
  {
    fieldId: FieldID.buyer_property_inspection_signature_date,
    fieldName: "buyer_property_inspection_signature_date",
    fieldType: "date",
    label: "Buyer Property Inspection Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_property_inspection_signature,
    fieldName: "buyer_two_property_inspection_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Property Inspection Signature",
  },
  {
    fieldId: FieldID.buyer_two_property_inspection_signature_date,
    fieldName: "buyer_two_property_inspection_signature_date",
    fieldType: "date",
    label: "Buyer 2 Property Inspection Signature Date",
  },
  {
    fieldId: FieldID.buyer_property_disclosure_signature,
    fieldName: "buyer_property_disclosure_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Property Disclosure Signature",
  },
  {
    fieldId: FieldID.buyer_property_disclosure_signature_date,
    fieldName: "buyer_property_disclosure_signature_date",
    fieldType: "date",
    label: "Buyer Property Disclosure Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_property_disclosure_signature,
    fieldName: "buyer_two_property_disclosure_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Property Disclosure Signature",
  },
  {
    fieldId: FieldID.buyer_two_property_disclosure_signature_date,
    fieldName: "buyer_two_property_disclosure_signature_date",
    fieldType: "date",
    label: "Buyer 2 Property Disclosure Signature Date",
  },
  {
    fieldId: FieldID.buyer_md_property_disclosure_signature,
    fieldName: "buyer_md_property_disclosure_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer MD Property Disclosure Signature",
  },
  {
    fieldId: FieldID.buyer_md_property_disclosure_signature_date,
    fieldName: "buyer_md_property_disclosure_signature_date",
    fieldType: "date",
    label: "Buyer MD Property Disclosure Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_md_property_disclosure_signature,
    fieldName: "buyer_two_md_property_disclosure_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 MD Property Disclosure Signature",
  },
  {
    fieldId: FieldID.buyer_two_md_property_disclosure_signature_date,
    fieldName: "buyer_two_md_property_disclosure_signature_date",
    fieldType: "date",
    label: "Buyer 2 MD Property Disclosure Signature Date",
  },
  {
    fieldId: FieldID.buyer_inclusions_exclusions_signature,
    fieldName: "buyer_inclusions_exclusions_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Inclusions Exclusions Signature",
  },
  {
    fieldId: FieldID.buyer_inclusions_exclusions_signature_date,
    fieldName: "buyer_inclusions_exclusions_signature_date",
    fieldType: "date",
    label: "Buyer Inclusions Exclusions Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_inclusions_exclusions_signature,
    fieldName: "buyer_two_inclusions_exclusions_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Inclusions Exclusions Signature",
  },
  {
    fieldId: FieldID.buyer_two_inclusions_exclusions_signature_date,
    fieldName: "buyer_two_inclusions_exclusions_signature_date",
    fieldType: "date",
    label: "Buyer 2 Inclusions Exclusions Signature Date",
  },
  {
    fieldId: FieldID.buyer_lead_notice_initial,
    fieldName: "buyer_lead_notice_initial",
    fieldType: "initials",
    label: "Buyer Lead Notice Initial",
  },
  {
    fieldId: FieldID.buyer_two_lead_notice_initial,
    fieldName: "buyer_two_lead_notice_initial",
    fieldType: "initials",
    label: "Buyer 2 Lead Notice Initial",
  },
  {
    fieldId: FieldID.buyer_lead_waiver_initial,
    fieldName: "buyer_lead_waiver_initial",
    fieldType: "initials",
    label: "Buyer Lead Waiver Initial",
  },
  {
    fieldId: FieldID.buyer_two_lead_waiver_initial,
    fieldName: "buyer_two_lead_waiver_initial",
    fieldType: "initials",
    label: "Buyer 2 Lead Waiver Initial",
  },
  {
    fieldId: FieldID.buyer_lead_pamphlet_initial,
    fieldName: "buyer_lead_pamphlet_initial",
    fieldType: "initials",
    label: "Buyer Lead Pamphlet Initial",
  },
  {
    fieldId: FieldID.buyer_two_lead_pamphlet_initial,
    fieldName: "buyer_two_lead_pamphlet_initial",
    fieldType: "initials",
    label: "Buyer 2 Lead Pamphlet Initial",
  },
  {
    fieldId: FieldID.buyer_lead_information_initial,
    fieldName: "buyer_lead_information_initial",
    fieldType: "initials",
    label: "Buyer Lead Information Initial",
  },
  {
    fieldId: FieldID.buyer_two_lead_information_initial,
    fieldName: "buyer_two_lead_information_initial",
    fieldType: "initials",
    label: "Buyer 2 Lead Information Initial",
  },
  {
    fieldId: FieldID.buyer_lead_disclosure_signature,
    fieldName: "buyer_lead_disclosure_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Lead Disclosure Signature",
  },
  {
    fieldId: FieldID.buyer_lead_disclosure_signature_date,
    fieldName: "buyer_lead_disclosure_signature_date",
    fieldType: "date",
    label: "Buyer Lead Disclosure Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_lead_disclosure_signature,
    fieldName: "buyer_two_lead_disclosure_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Lead Disclosure Signature",
  },
  {
    fieldId: FieldID.buyer_two_lead_disclosure_signature_date,
    fieldName: "buyer_two_lead_disclosure_signature_date",
    fieldType: "date",
    label: "Buyer 2 Lead Disclosure Signature Date",
  },
  {
    fieldId: FieldID.buyer_maryland_consumers_notice_signature,
    fieldName: "buyer_maryland_consumers_notice_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Maryland Consumers Notice Signature",
  },
  {
    fieldId: FieldID.buyer_maryland_consumers_notice_signature_date,
    fieldName: "buyer_maryland_consumers_notice_signature_date",
    fieldType: "date",
    label: "Buyer Maryland Consumers Notice Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_maryland_consumers_notice_signature,
    fieldName: "buyer_two_maryland_consumers_notice_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Maryland Consumers Notice Signature",
  },
  {
    fieldId: FieldID.buyer_two_maryland_consumers_notice_signature_date,
    fieldName: "buyer_two_maryland_consumers_notice_signature_date",
    fieldType: "date",
    label: "Buyer 2 Maryland Consumers Notice Signature Date",
  },
  {
    fieldId: FieldID.buyer_contribution_signature,
    fieldName: "buyer_contribution_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Contribution Signature",
  },
  {
    fieldId: FieldID.buyer_contribution_signature_date,
    fieldName: "buyer_contribution_signature_date",
    fieldType: "date",
    label: "Buyer Contribution Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_contribution_signature,
    fieldName: "buyer_two_contribution_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Contribution Signature",
  },
  {
    fieldId: FieldID.buyer_two_contribution_signature_date,
    fieldName: "buyer_two_contribution_signature_date",
    fieldType: "date",
    label: "Buyer 2 Contribution Signature Date",
  },
  {
    fieldId: FieldID.buyer_property_address,
    fieldName: "buyer_property_address",
    fieldType: "text",
    label: "Buyer Property Address",
  },
  {
    fieldId: FieldID.buyer_property_contract_date,
    fieldName: "buyer_property_contract_date",
    fieldType: "date",
    label: "Buyer Property Contract Date",
  },
  {
    fieldId: FieldID.buyer_property_financing_date,
    fieldName: "buyer_property_financing_date",
    fieldType: "date",
    label: "Buyer Property Financing Date",
  },
  {
    fieldId: FieldID.buyer_property_settlement_date,
    fieldName: "buyer_property_settlement_date",
    fieldType: "date",
    label: "Buyer Property Settlement Date",
  },
  {
    fieldId: FieldID.buyer_property_lease_date,
    fieldName: "buyer_property_lease_date",
    fieldType: "date",
    label: "Buyer Property Lease Date",
  },
  {
    fieldId: FieldID.buyer_days_to_list_property,
    fieldName: "buyer_days_to_list_property",
    fieldType: "number",
    label: "Buyer Days To List Property",
  },
  {
    fieldId: FieldID.seller_days_to_null_and_void,
    fieldName: "seller_days_to_null_and_void",
    fieldType: "number",
    label: "Seller Days to Null and Void",
  },
  {
    fieldId: FieldID.buyer_hoa_acknowledgement_signature,
    fieldName: "buyer_hoa_acknowledgement_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer HOA Acknowledgement Signature",
  },
  {
    fieldId: FieldID.buyer_hoa_acknowledgement_signature_date,
    fieldName: "buyer_hoa_acknowledgement_signature_date",
    fieldType: "date",
    label: "Buyer HOA Acknowledgement Signature Date",
  },
  {
    fieldId: FieldID.end_of_occupancy_date,
    fieldName: "end_of_occupancy_date",
    fieldType: "date",
    label: "End of Occupancy Date",
  },
  {
    fieldId: FieldID.buyer_comp_nominal_consideration_initial,
    fieldName: "buyer_comp_nominal_consideration_initial",
    fieldType: "initials",
    label: "Buyer Comp Nominal Consideration Initial",
  },
  {
    fieldId: FieldID.buyer_two_comp_nominal_consideration_initial,
    fieldName: "buyer_two_comp_nominal_consideration_initial",
    fieldType: "initials",
    label: "Buyer 2 Comp Nominal Consideration Initial",
  },
  {
    fieldId: FieldID.buyer_occupancy_comp_carry_cost_initial,
    fieldName: "buyer_occupancy_comp_carry_cost_initial",
    fieldType: "initials",
    label: "Buyer Occupancy Comp Carry Cost Initial",
  },
  {
    fieldId: FieldID.buyer_two_occupancy_comp_carry_cost_initial,
    fieldName: "buyer_two_occupancy_comp_carry_cost_initial",
    fieldType: "initials",
    label: "Buyer 2 Occupancy Comp Carry Cost Initial",
  },
  {
    fieldId: FieldID.buyer_occupancy_comp_flat_rate_initial,
    fieldName: "buyer_occupancy_comp_flat_rate_initial",
    fieldType: "initials",
    label: "Buyer Occupancy Comp Flat Rate Initial",
  },
  {
    fieldId: FieldID.buyer_two_occupancy_comp_flat_rate_initial,
    fieldName: "buyer_two_occupancy_comp_flat_rate_initial",
    fieldType: "initials",
    label: "Buyer 2 Occupancy Comp Flat Rate Initial",
  },
  {
    fieldId: FieldID.occupancy_comp_day_rate,
    fieldName: "occupancy_comp_day_rate",
    fieldType: "currency",
    label: "Occupancy Comp Day Rate",
  },
  {
    fieldId: FieldID.occupancy_compensation_total,
    fieldName: "occupancy_compensation_total",
    fieldType: "currency",
    label: "Occupancy Compensation Total",
  },
  {
    fieldId: FieldID.post_occupancy_deposit,
    fieldName: "post_occupancy_deposit",
    fieldType: "currency",
    label: "Post Occupancy Deposit",
  },
  {
    fieldId: FieldID.post_occupancy_escrow_agent,
    fieldName: "post_occupancy_escrow_agent",
    fieldType: "text",
    label: "Post Occupancy Escrow Agent",
  },
  {
    fieldId: FieldID.contract_of_sale_date,
    fieldName: "contract_of_sale_date",
    fieldType: "date",
    label: "Contract of Sale Date",
  },
  {
    fieldId: FieldID.final_inspection_date,
    fieldName: "final_inspection_date",
    fieldType: "date",
    label: "Final Inspection Date",
  },
  {
    fieldId: FieldID.buyer_post_occupancy_signature,
    fieldName: "buyer_post_occupancy_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Post Occupancy Signature",
  },
  {
    fieldId: FieldID.buyer_post_occupancy_signature_date,
    fieldName: "buyer_post_occupancy_signature_date",
    fieldType: "date",
    label: "Buyer Post Occupancy Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_post_occupancy_signature,
    fieldName: "buyer_two_post_occupancy_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Post Occupancy Signature",
  },
  {
    fieldId: FieldID.buyer_two_post_occupancy_signature_date,
    fieldName: "buyer_two_post_occupancy_signature_date",
    fieldType: "date",
    label: "Buyer 2 Post Occupancy Signature Date",
  },
  {
    fieldId: FieldID.buyer_anne_arundel_signature,
    fieldName: "buyer_anne_arundel_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Anne Arundel Signature",
  },
  {
    fieldId: FieldID.buyer_anne_arundel_signature_date,
    fieldName: "buyer_anne_arundel_signature_date",
    fieldType: "date",
    label: "Buyer Anne Arundel Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_anne_arundel_signature,
    fieldName: "buyer_two_anne_arundel_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Anne Arundel Signature",
  },
  {
    fieldId: FieldID.buyer_two_anne_arundel_signature_date,
    fieldName: "buyer_two_anne_arundel_signature_date",
    fieldType: "date",
    label: "Buyer 2 Anne Arundel Signature Date",
  },
  {
    fieldId: FieldID.buyer_baltimore_signature,
    fieldName: "buyer_baltimore_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Baltimore Signature",
  },
  {
    fieldId: FieldID.buyer_baltimore_signature_date,
    fieldName: "buyer_baltimore_signature_date",
    fieldType: "date",
    label: "Buyer Baltimore Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_baltimore_signature,
    fieldName: "buyer_two_baltimore_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Baltimore Signature",
  },
  {
    fieldId: FieldID.buyer_two_baltimore_signature_date,
    fieldName: "buyer_two_baltimore_signature_date",
    fieldType: "date",
    label: "Buyer 2 Baltimore Signature Date",
  },
  {
    fieldId: FieldID.baltimore_tax_seller_responsibility,
    fieldName: "baltimore_tax_seller_responsibility",
    fieldType: "currency",
    label: "Baltimore Tax Seller Responsibility",
  },
  {
    fieldId: FieldID.baltimore_tax_buyer_responsibility,
    fieldName: "baltimore_tax_buyer_responsibility",
    fieldType: "currency",
    label: "Baltimore Tax Buyer Responsibility",
  },
  {
    fieldId: FieldID.baltimore_tax_split,
    fieldName: "baltimore_tax_split",
    fieldType: "number",
    label: "Baltimore Tax Split",
  },
  {
    fieldId: FieldID.buyer_baltimore_tax_signature,
    fieldName: "buyer_baltimore_tax_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Baltimore Tax Signature",
  },
  {
    fieldId: FieldID.buyer_baltimore_tax_signature_date,
    fieldName: "buyer_baltimore_tax_signature_date",
    fieldType: "date",
    label: "Buyer Baltimore Tax Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_baltimore_tax_signature,
    fieldName: "buyer_two_baltimore_tax_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Baltimore Tax Signature",
  },
  {
    fieldId: FieldID.buyer_two_baltimore_tax_signature_date,
    fieldName: "buyer_two_baltimore_tax_signature_date",
    fieldType: "date",
    label: "Buyer 2 Baltimore Tax Signature Date",
  },
  {
    fieldId: FieldID.buyer_baltimore_acknowledge_signature,
    fieldName: "buyer_baltimore_acknowledge_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Baltimore Acknowledge Signature",
  },
  {
    fieldId: FieldID.buyer_baltimore_acknowledge_signature_date,
    fieldName: "buyer_baltimore_acknowledge_signature_date",
    fieldType: "date",
    label: "Buyer Baltimore Acknowledge Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_baltimore_acknowledge_signature,
    fieldName: "buyer_two_baltimore_acknowledge_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Baltimore Acknowledge Signature",
  },
  {
    fieldId: FieldID.buyer_two_baltimore_acknowledge_signature_date,
    fieldName: "buyer_two_baltimore_acknowledge_signature_date",
    fieldType: "date",
    label: "Buyer 2 Baltimore Acknowledge Signature Date",
  },
  {
    fieldId: FieldID.buyer_baltimore_master_plan_signature,
    fieldName: "buyer_baltimore_master_plan_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Baltimore Master Plan Signature",
  },
  {
    fieldId: FieldID.buyer_baltimore_master_plan_signature_date,
    fieldName: "buyer_baltimore_master_plan_signature_date",
    fieldType: "date",
    label: "Buyer Baltimore Master Plan Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_baltimore_master_plan_signature,
    fieldName: "buyer_two_baltimore_master_plan_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Baltimore Master Plan Signature",
  },
  {
    fieldId: FieldID.buyer_two_baltimore_master_plan_signature_date,
    fieldName: "buyer_two_baltimore_master_plan_signature_date",
    fieldType: "date",
    label: "Buyer 2 Baltimore Master Plan Signature Date",
  },
  {
    fieldId: FieldID.buyer_radionuclides_initials,
    fieldName: "buyer_radionuclides_initials",
    fieldType: "initials",
    label: "Buyer Radionuclides Initials",
  },
  {
    fieldId: FieldID.buyer_two_radionuclides_initials,
    fieldName: "buyer_two_radionuclides_initials",
    fieldType: "initials",
    label: "Buyer 2 Radionuclides Initials",
  },
  {
    fieldId: FieldID.buyer_dewatering_zone_signature,
    fieldName: "buyer_dewatering_zone_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Dewatering Zone Signature",
  },
  {
    fieldId: FieldID.buyer_dewatering_zone_signature_date,
    fieldName: "buyer_dewatering_zone_signature_date",
    fieldType: "date",
    label: "Buyer Dewatering Zone Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_dewatering_zone_signature,
    fieldName: "buyer_two_dewatering_zone_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Dewatering Zone Signature",
  },
  {
    fieldId: FieldID.buyer_two_dewatering_zone_signature_date,
    fieldName: "buyer_two_dewatering_zone_signature_date",
    fieldType: "date",
    label: "Buyer 2 Dewatering Zone Signature Date",
  },
  {
    fieldId: FieldID.buyer_howard_county_signature,
    fieldName: "buyer_howard_county_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Howard County Signature",
  },
  {
    fieldId: FieldID.buyer_howard_county_signature_date,
    fieldName: "buyer_howard_county_signature_date",
    fieldType: "date",
    label: "Buyer Howard County Signature Date",
  },
  {
    fieldId: FieldID.buyer_two_howard_county_signature,
    fieldName: "buyer_two_howard_county_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Howard County Signature",
  },
  {
    fieldId: FieldID.buyer_two_howard_county_signature_date,
    fieldName: "buyer_two_howard_county_signature_date",
    fieldType: "date",
    label: "Buyer 2 Howard County Signature Date",
  },
  {
    fieldId: FieldID.seller_appraisal_contingency_signature,
    fieldName: "seller_appraisal_contingency_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Appraisal Contingency Signature",
  },
  {
    fieldId: FieldID.seller_appraisal_contingency_signature_date,
    fieldName: "seller_appraisal_contingency_signature_date",
    fieldType: "date",
    label: "Seller Appraisal Contingency Signature Date",
  },
  {
    fieldId: FieldID.seller_two_appraisal_contingency_signature,
    fieldName: "seller_two_appraisal_contingency_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Appraisal Contingency Signature",
  },
  {
    fieldId: FieldID.seller_two_appraisal_contingency_signature_date,
    fieldName: "seller_two_appraisal_contingency_signature_date",
    fieldType: "date",
    label: "Seller 2 Appraisal Contingency Signature Date",
  },
  {
    fieldId: FieldID.seller_fha_financing_signature,
    fieldName: "seller_fha_financing_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller FHA Financing Signature",
  },
  {
    fieldId: FieldID.seller_fha_financing_signature_date,
    fieldName: "seller_fha_financing_signature_date",
    fieldType: "date",
    label: "Seller FHA Financing Signature Date",
  },
  {
    fieldId: FieldID.seller_two_fha_financing_signature,
    fieldName: "seller_two_fha_financing_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 FHA Financing Signature",
  },
  {
    fieldId: FieldID.seller_two_fha_financing_signature_date,
    fieldName: "seller_two_fha_financing_signature_date",
    fieldType: "date",
    label: "Seller 2 FHA Financing Signature Date",
  },
  {
    fieldId: FieldID.seller_va_financing_signature,
    fieldName: "seller_va_financing_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller VA Financing Signature",
  },
  {
    fieldId: FieldID.seller_va_financing_signature_date,
    fieldName: "seller_va_financing_signature_date",
    fieldType: "date",
    label: "Seller VA Financing Signature Date",
  },
  {
    fieldId: FieldID.seller_two_va_financing_signature,
    fieldName: "seller_two_va_financing_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 VA Financing Signature",
  },
  {
    fieldId: FieldID.seller_two_va_financing_signature_date,
    fieldName: "seller_two_va_financing_signature_date",
    fieldType: "date",
    label: "Seller 2 VA Financing Signature Date",
  },
  {
    fieldId: FieldID.seller_structural_mechanical_initial,
    fieldName: "seller_structural_mechanical_initial",
    fieldType: "initials",
    label: "Seller Structural and Mechanical Initial",
  },
  {
    fieldId: FieldID.seller_mold_initial,
    fieldName: "seller_mold_initial",
    fieldType: "initials",
    label: "Seller Mold Initial",
  },
  {
    fieldId: FieldID.seller_environmental_initial,
    fieldName: "seller_environmental_initial",
    fieldType: "initials",
    label: "Seller Environmental Initial",
  },
  {
    fieldId: FieldID.seller_radon_initial,
    fieldName: "seller_radon_initial",
    fieldType: "initials",
    label: "Seller Radon Initial",
  },
  {
    fieldId: FieldID.seller_chimney_initial,
    fieldName: "seller_chimney_initial",
    fieldType: "initials",
    label: "Seller Chimney Initial",
  },
  {
    fieldId: FieldID.seller_lead_based_paint_inspection_initial,
    fieldName: "seller_lead_based_paint_inspection_initial",
    fieldType: "initials",
    label: "Seller Lead-Based Paint Inspection Initial",
  },
  {
    fieldId: FieldID.seller_wood_destroying_insect_initial,
    fieldName: "seller_wood_destroying_insect_initial",
    fieldType: "initials",
    label: "Seller Wood Destroying Insect Initial",
  },
  {
    fieldId: FieldID.seller_additional_inspections_initial,
    fieldName: "seller_additional_inspections_initial",
    fieldType: "initials",
    label: "Seller Additional Inspections Initial",
  },
  {
    fieldId: FieldID.seller_two_structural_mechanical_initial,
    fieldName: "seller_two_structural_mechanical_initial",
    fieldType: "initials",
    label: "Seller 2 Structural and Mechanical Initial",
  },
  {
    fieldId: FieldID.seller_two_mold_initial,
    fieldName: "seller_two_mold_initial",
    fieldType: "initials",
    label: "Seller 2 Mold Initial",
  },
  {
    fieldId: FieldID.seller_two_environmental_initial,
    fieldName: "seller_two_environmental_initial",
    fieldType: "initials",
    label: "Seller 2 Environmental Initial",
  },
  {
    fieldId: FieldID.seller_two_radon_initial,
    fieldName: "seller_two_radon_initial",
    fieldType: "initials",
    label: "Seller 2 Radon Initial",
  },
  {
    fieldId: FieldID.seller_two_chimney_initial,
    fieldName: "seller_two_chimney_initial",
    fieldType: "initials",
    label: "Seller 2 Chimney Initial",
  },
  {
    fieldId: FieldID.seller_two_lead_based_paint_inspection_initial,
    fieldName: "seller_two_lead_based_paint_inspection_initial",
    fieldType: "initials",
    label: "Seller 2 Lead-Based Paint Inspection Initial",
  },
  {
    fieldId: FieldID.seller_two_wood_destroying_insect_initial,
    fieldName: "seller_two_wood_destroying_insect_initial",
    fieldType: "initials",
    label: "Seller 2 Wood Destroying Insect Initial",
  },
  {
    fieldId: FieldID.seller_two_additional_inspections_initial,
    fieldName: "seller_two_additional_inspections_initial",
    fieldType: "initials",
    label: "Seller 2 Additional Inspections Initial",
  },
  {
    fieldId: FieldID.seller_property_inspection_signature,
    fieldName: "seller_property_inspection_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Property Inspection Signature",
  },
  {
    fieldId: FieldID.seller_property_inspection_signature_date,
    fieldName: "seller_property_inspection_signature_date",
    fieldType: "date",
    label: "Seller Property Inspection Signature Date",
  },
  {
    fieldId: FieldID.seller_two_property_inspection_signature,
    fieldName: "seller_two_property_inspection_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Property Inspection Signature",
  },
  {
    fieldId: FieldID.seller_two_property_inspection_signature_date,
    fieldName: "seller_two_property_inspection_signature_date",
    fieldType: "date",
    label: "Seller 2 Property Inspection Signature Date",
  },
  {
    fieldId: FieldID.water_supply,
    fieldName: "water_supply",
    fieldType: "select",
    label: "Water Supply",
    list: ["Public", "Well", "Other"],
  },
  {
    fieldId: FieldID.sewage_disposal,
    fieldName: "sewage_disposal",
    fieldType: "select",
    label: "Sewage Disposal",
    list: ["Public", "Septic"],
  },
  {
    fieldId: FieldID.septic_system_number_of_bedrooms,
    fieldName: "septic_system_number_of_bedrooms",
    fieldType: "number",
    label: "Septic System Number of Bedrooms",
  },
  {
    fieldId: FieldID.garbage_disposal,
    fieldName: "garbage_disposal",
    fieldType: "boolean",
    label: "Garbage Disposal",
  },
  {
    fieldId: FieldID.dishwasher,
    fieldName: "dishwasher",
    fieldType: "boolean",
    label: "Dish Washer",
  },
  {
    fieldId: FieldID.heating,
    fieldName: "heating",
    fieldType: "select",
    label: "Heating",
    list: ["Oil", "Natural Gas", "Electric", "Heat Pump", "Other"],
  },
  {
    fieldId: FieldID.heating_other,
    fieldName: "heating_other",
    fieldType: "text",
    label: "Heating Other",
  },
  {
    fieldId: FieldID.heat_pump_age,
    fieldName: "heat_pump_age",
    fieldType: "number",
    label: "Heat Pump Age",
  },
  {
    fieldId: FieldID.air_conditioning,
    fieldName: "air_conditioning",
    fieldType: "select",
    label: "Air Conditioning",
    list: ["Oil", "Natural Gas", "Electric", "Heat Pump", "Other"],
  },
  {
    fieldId: FieldID.air_conditioning_other,
    fieldName: "air_conditioning_other",
    fieldType: "text",
    label: "Air Conditioning Other",
  },
  {
    fieldId: FieldID.hot_water,
    fieldName: "hot_water",
    fieldType: "select",
    label: "Hot Water",
    list: ["Oil", "Natural Gas", "Electric", "Other"],
  },
  {
    fieldId: FieldID.hot_water_other,
    fieldName: "hot_water_other",
    fieldType: "text",
    label: "Hot Water Other",
  },
  {
    fieldId: FieldID.foundation_issues,
    fieldName: "foundation_issues",
    fieldType: "select",
    label: "Foundation Issues",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.basement_leaks_or_moisture,
    fieldName: "basement_leaks_or_moisture",
    fieldType: "select",
    label: "Basement Leaks or Moisture",
    list: ["Yes", "No", "Unknown", "N/A"],
  },
  {
    fieldId: FieldID.roof_leaks_or_moisture,
    fieldName: "roof_leaks_or_moisture",
    fieldType: "select",
    label: "Roof Leaks or Moisture",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.type_of_roof,
    fieldName: "type_of_roof",
    fieldType: "text",
    label: "Type of Roof",
  },
  {
    fieldId: FieldID.roof_age,
    fieldName: "roof_age",
    fieldType: "number",
    label: "Roof Age",
  },
  {
    fieldId: FieldID.fire_retardant_plywood,
    fieldName: "fire_retardant_plywood",
    fieldType: "select",
    label: "Fire Retardant Plywood",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.other_structural_systems,
    fieldName: "other_structural_systems",
    fieldType: "text",
    label: "Other Structural Systems",
  },
  {
    fieldId: FieldID.any_defects,
    fieldName: "any_defects",
    fieldType: "select",
    label: "Any Defects",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.any_defects_comments,
    fieldName: "any_defects_comments",
    fieldType: "text",
    label: "Any Defects Comments",
  },
  {
    fieldId: FieldID.plumbing_system_working,
    fieldName: "plumbing_system_working",
    fieldType: "select",
    label: "Plumbing System Working",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.heat_supply_to_all_rooms,
    fieldName: "heat_supply_to_all_rooms",
    fieldType: "select",
    label: "Heat Supply to All Rooms",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.heat_system_working,
    fieldName: "heat_system_working",
    fieldType: "select",
    label: "Heat System Working",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.heat_system_comments,
    fieldName: "heat_system_comments",
    fieldType: "text",
    label: "Heat System Comments",
  },
  {
    fieldId: FieldID.ac_supply_to_all_rooms,
    fieldName: "ac_supply_to_all_rooms",
    fieldType: "select",
    label: "AC Supply to All Rooms",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.ac_system_working,
    fieldName: "ac_system_working",
    fieldType: "select",
    label: "AC System Working",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.ac_system_comments,
    fieldName: "ac_system_comments",
    fieldType: "text",
    label: "AC System Comments",
  },
  {
    fieldId: FieldID.electrical_system_issues,
    fieldName: "electrical_system_issues",
    fieldType: "select",
    label: "Electrical System Issues",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.electrical_system_comments,
    fieldName: "electrical_system_comments",
    fieldType: "text",
    label: "Electrical System Comments",
  },
  {
    fieldId: FieldID.smoke_alarms,
    fieldName: "smoke_alarms",
    fieldType: "select",
    label: "Smoke Alarms",
    list: ["Yes", "No"],
  },
  {
    fieldId: FieldID.smoke_alarms_older_than_10_years,
    fieldName: "smoke_alarms_older_than_10_years",
    fieldType: "select",
    label: "Smoke Alarms Older Than 10 Years",
    list: ["Yes", "No"],
  },
  {
    fieldId: FieldID.smoke_alarms_md_compliant,
    fieldName: "smoke_alarms_md_compliant",
    fieldType: "select",
    label: "Smoke Alarms MD Compliant",
    list: ["Yes", "No"],
  },
  {
    fieldId: FieldID.septic_system_working,
    fieldName: "septic_system_working",
    fieldType: "select",
    label: "Septic System Working",
    list: ["Yes", "No", "Unknown", "N/A"],
  },
  {
    fieldId: FieldID.septic_system_pump_date,
    fieldName: "septic_system_pump_date",
    fieldType: "date",
    label: "Septic System Pump Date",
  },
  {
    fieldId: FieldID.septic_system_pump_date_unknown,
    fieldName: "septic_system_pump_date_unknown",
    fieldType: "boolean",
    label: "Septic System Pump Date Unknown",
  },
  {
    fieldId: FieldID.septic_system_comments,
    fieldName: "septic_system_comments",
    fieldType: "text",
    label: "Septic System Comments",
  },
  {
    fieldId: FieldID.water_supply_issues,
    fieldName: "water_supply_issues",
    fieldType: "select",
    label: "Water Supply Issues",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.home_water_treatment_system,
    fieldName: "home_water_treatment_system",
    fieldType: "select",
    label: "Home Water Treatment System",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.home_water_treatment_system_comments,
    fieldName: "home_water_treatment_system_comments",
    fieldType: "text",
    label: "Home Water Treatment System Comments",
  },
  {
    fieldId: FieldID.fire_sprinkler_system,
    fieldName: "fire_sprinkler_system",
    fieldType: "select",
    label: "Fire Sprinkler System",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.fire_sprinkler_system_comments,
    fieldName: "fire_sprinkler_system_comments",
    fieldType: "text",
    label: "Fire Sprinkler System Comments",
  },
  {
    fieldId: FieldID.are_systems_operating,
    fieldName: "are_systems_operating",
    fieldType: "select",
    label: "Are Systems Operating",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.exterior_walls_insulated,
    fieldName: "exterior_walls_insulated",
    fieldType: "select",
    label: "Exterior Walls Insulated",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.ceiling_and_attic_insulated,
    fieldName: "ceiling_and_attic_insulated",
    fieldType: "select",
    label: "Ceiling and Attic Insulated",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.insulation_comments,
    fieldName: "insulation_comments",
    fieldType: "text",
    label: "Insulation Comments",
  },
  {
    fieldId: FieldID.exterior_drainage_standing_water,
    fieldName: "exterior_drainage_standing_water",
    fieldType: "select",
    label: "Exterior Drainage Standing Water",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.exterior_drainage_issues,
    fieldName: "exterior_drainage_issues",
    fieldType: "select",
    label: "Exterior Drainage Issues",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.gutter_working,
    fieldName: "gutter_working",
    fieldType: "select",
    label: "Gutter Working",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.gutter_comments,
    fieldName: "gutter_comments",
    fieldType: "text",
    label: "Gutter Comments",
  },
  {
    fieldId: FieldID.wood_insect_damage,
    fieldName: "wood_insect_damage",
    fieldType: "select",
    label: "Wood Insect Damage",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.wood_insect_comments,
    fieldName: "wood_insect_comments",
    fieldType: "text",
    label: "Wood Insect Comments",
  },
  {
    fieldId: FieldID.wood_damage_repair,
    fieldName: "wood_damage_repair",
    fieldType: "select",
    label: "Wood Damage Repair",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.wood_insect_warranty,
    fieldName: "wood_insect_warranty",
    fieldType: "select",
    label: "Wood Insect Warranty",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.hazardous_materials,
    fieldName: "hazardous_materials",
    fieldType: "select",
    label: "Hazardous Materials",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.hazardous_materials_comments,
    fieldName: "hazardous_materials_comments",
    fieldType: "text",
    label: "Hazardous Materials Comments",
  },
  {
    fieldId: FieldID.monoxide_alarm,
    fieldName: "monoxide_alarm",
    fieldType: "select",
    label: "Monoxide Alarm",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.monoxide_alarm_comments,
    fieldName: "monoxide_alarm_comments",
    fieldType: "text",
    label: "Monoxide Alarm Comments",
  },
  {
    fieldId: FieldID.zoning_violations,
    fieldName: "zoning_violations",
    fieldType: "select",
    label: "Zoning Violations",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.zoning_comments,
    fieldName: "zoning_comments",
    fieldType: "text",
    label: "Zoning Comments",
  },
  {
    fieldId: FieldID.home_improvements,
    fieldName: "home_improvements",
    fieldType: "select",
    label: "Home Improvements",
    list: ["Yes", "No", "Does Not Apply", "Unknown"],
  },
  {
    fieldId: FieldID.property_in_flood_zone,
    fieldName: "property_in_flood_zone",
    fieldType: "select",
    label: "Property In Flood Zone",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.flood_zone_comments,
    fieldName: "flood_zone_comments",
    fieldType: "text",
    label: "Flood Zone Comments",
  },
  {
    fieldId: FieldID.hoa_restrictions,
    fieldName: "hoa_restrictions",
    fieldType: "select",
    label: "HOA Restrictions",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.hoa_restriction_comments,
    fieldName: "hoa_restriction_comments",
    fieldType: "text",
    label: "HOA Restriction Comments",
  },
  {
    fieldId: FieldID.any_other_defects,
    fieldName: "any_other_defects",
    fieldType: "select",
    label: "Any Other Defects",
    list: ["Yes", "No", "Unknown"],
  },
  {
    fieldId: FieldID.other_defect_comments,
    fieldName: "other_defect_comments",
    fieldType: "text",
    label: "Other Defect Comments",
  },
  {
    fieldId: FieldID.seller_property_disclosure_signature,
    fieldName: "seller_property_disclosure_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Property Disclosure Signature",
  },
  {
    fieldId: FieldID.seller_property_disclosure_signature_date,
    fieldName: "seller_property_disclosure_signature_date",
    fieldType: "date",
    label: "Seller Property Disclosure Signature Date",
  },
  {
    fieldId: FieldID.seller_two_property_disclosure_signature,
    fieldName: "seller_two_property_disclosure_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Property Disclosure Signature",
  },
  {
    fieldId: FieldID.seller_two_property_disclosure_signature_date,
    fieldName: "seller_two_property_disclosure_signature_date",
    fieldType: "date",
    label: "Seller 2 Property Disclosure Signature Date",
  },
  {
    fieldId: FieldID.seller_md_property_disclosure_signature,
    fieldName: "seller_md_property_disclosure_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller MD Property Disclosure Signature",
  },
  {
    fieldId: FieldID.seller_md_property_disclosure_signature_date,
    fieldName: "seller_md_property_disclosure_signature_date",
    fieldType: "date",
    label: "Seller MD Property Disclosure Signature Date",
  },
  {
    fieldId: FieldID.seller_two_md_property_disclosure_signature,
    fieldName: "seller_two_md_property_disclosure_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 MD Property Disclosure Signature",
  },
  {
    fieldId: FieldID.seller_two_md_property_disclosure_signature_date,
    fieldName: "seller_two_md_property_disclosure_signature_date",
    fieldType: "date",
    label: "Seller 2 MD Property Disclosure Signature Date",
  },
  {
    fieldId: FieldID.seller_disclosure_of_inclusions_exclusions_signature,
    fieldName: "seller_disclosure_of_inclusions_exclusions_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Disclosure of Inclusions/Exclusions Signature",
  },
  {
    fieldId: FieldID.seller_disclosure_of_inclusions_exclusions_signature_date,
    fieldName: "seller_disclosure_of_inclusions_exclusions_signature_date",
    fieldType: "date",
    label: "Seller Disclosure of Inclusions/Exclusions Signature Date",
  },
  {
    fieldId: FieldID.seller_two_disclosure_of_inclusions_exclusions_signature,
    fieldName: "seller_two_disclosure_of_inclusions_exclusions_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Disclosure of Inclusions/Exclusions Signature",
  },
  {
    fieldId:
      FieldID.seller_two_disclosure_of_inclusions_exclusions_signature_date,
    fieldName: "seller_two_disclosure_of_inclusions_exclusions_signature_date",
    fieldType: "date",
    label: "Seller 2 Disclosure of Inclusions/Exclusions Signature Date",
  },
  {
    fieldId: FieldID.seller_pre_1978_initial,
    fieldName: "seller_pre_1978_initial",
    fieldType: "initials",
    label: "Seller Pre 1978 Initial",
  },
  {
    fieldId: FieldID.seller_two_pre_1978_initial,
    fieldName: "seller_two_pre_1978_initial",
    fieldType: "initials",
    label: "Seller 2 Pre 1978 Initial",
  },
  {
    fieldId: FieldID.seller_home_date_unknown_initial,
    fieldName: "seller_home_date_unknown_initial",
    fieldType: "initials",
    label: "Seller Home Date Unknown Initial",
  },
  {
    fieldId: FieldID.seller_two_home_date_unknown_initial,
    fieldName: "seller_two_home_date_unknown_initial",
    fieldType: "initials",
    label: "Seller 2 Home Date Unknown Initial",
  },
  {
    fieldId: FieldID.seller_presence_of_lead_known,
    fieldName: "seller_presence_of_lead_known",
    fieldType: "initials",
    label: "Seller Presence of Lead Known",
  },
  {
    fieldId: FieldID.seller_two_presence_of_lead_known,
    fieldName: "seller_two_presence_of_lead_known",
    fieldType: "initials",
    label: "Seller 2 Presence of Lead Known",
  },
  {
    fieldId: FieldID.seller_presence_of_lead_unknown,
    fieldName: "seller_presence_of_lead_unknown",
    fieldType: "initials",
    label: "Seller Presence of Lead Unknown",
  },
  {
    fieldId: FieldID.seller_two_presence_of_lead_unknown,
    fieldName: "seller_two_presence_of_lead_unknown",
    fieldType: "initials",
    label: "Seller 2 Presence of Lead Unknown",
  },
  {
    fieldId: FieldID.seller_lead_records_provided,
    fieldName: "seller_lead_records_provided",
    fieldType: "initials",
    label: "Seller Lead Records Provided",
  },
  {
    fieldId: FieldID.seller_two_lead_records_provided,
    fieldName: "seller_two_lead_records_provided",
    fieldType: "initials",
    label: "Seller 2 Lead Records Provided",
  },
  {
    fieldId: FieldID.seller_no_lead_records,
    fieldName: "seller_no_lead_records",
    fieldType: "initials",
    label: "Seller No Lead Records",
  },
  {
    fieldId: FieldID.seller_two_no_lead_records,
    fieldName: "seller_two_no_lead_records",
    fieldType: "initials",
    label: "Seller 2 No Lead Records",
  },
  {
    fieldId: FieldID.seller_lead_disclosure_signature,
    fieldName: "seller_lead_disclosure_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Lead Disclosure Signature",
  },
  {
    fieldId: FieldID.seller_lead_disclosure_signature_date,
    fieldName: "seller_lead_disclosure_signature_date",
    fieldType: "date",
    label: "Seller Lead Disclosure Signature Date",
  },
  {
    fieldId: FieldID.seller_two_lead_disclosure_signature,
    fieldName: "seller_two_lead_disclosure_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Lead Disclosure Signature",
  },
  {
    fieldId: FieldID.seller_two_lead_disclosure_signature_date,
    fieldName: "seller_two_lead_disclosure_signature_date",
    fieldType: "date",
    label: "Seller 2 Lead Disclosure Signature Date",
  },
  {
    fieldId: FieldID.seller_maryland_consumers_notice_signature,
    fieldName: "seller_maryland_consumers_notice_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Maryland Consumers Notice Signature",
  },
  {
    fieldId: FieldID.seller_maryland_consumers_notice_signature_date,
    fieldName: "seller_maryland_consumers_notice_signature_date",
    fieldType: "date",
    label: "Seller Maryland Consumers Notice Signature Date",
  },
  {
    fieldId: FieldID.seller_two_maryland_consumers_notice_signature,
    fieldName: "seller_two_maryland_consumers_notice_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Maryland Consumers Notice Signature",
  },
  {
    fieldId: FieldID.seller_two_maryland_consumers_notice_signature_date,
    fieldName: "seller_two_maryland_consumers_notice_signature_date",
    fieldType: "date",
    label: "Seller 2 Maryland Consumers Notice Signature Date",
  },
  {
    fieldId: FieldID.seller_contribution_signature,
    fieldName: "seller_contribution_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Contribution Signature",
  },
  {
    fieldId: FieldID.seller_contribution_signature_date,
    fieldName: "seller_contribution_signature_date",
    fieldType: "date",
    label: "Seller Contribution Signature Date",
  },
  {
    fieldId: FieldID.seller_two_contribution_signature,
    fieldName: "seller_two_contribution_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Contribution Signature",
  },
  {
    fieldId: FieldID.seller_two_contribution_signature_date,
    fieldName: "seller_two_contribution_signature_date",
    fieldType: "date",
    label: "Seller 2 Contribution Signature Date",
  },
  {
    fieldId: FieldID.property_neighborhood_name,
    fieldName: "property_neighborhood_name",
    fieldType: "text",
    label: "Property Neighborhood Name",
  },
  {
    fieldId: FieldID.monthly_hoa_fees,
    fieldName: "monthly_hoa_fees",
    fieldType: "currency",
    label: "Monthly HOA Fees",
  },
  {
    fieldId: FieldID.hoa_payment_basis,
    fieldName: "hoa_payment_basis",
    fieldType: "text",
    label: "HOA Payment Basis",
  },
  {
    fieldId: FieldID.total_yearly_hoa_fees,
    fieldName: "total_yearly_hoa_fees",
    fieldType: "currency",
    label: "Total Yearly HOA Fees",
  },
  {
    fieldId: FieldID.other_hoa_fees,
    fieldName: "other_hoa_fees",
    fieldType: "text",
    label: "Other HOA Fees",
  },
  {
    fieldId: FieldID.hoa_fee_comments,
    fieldName: "hoa_fee_comments",
    fieldType: "text",
    label: "HOA Fee Comments",
  },
  {
    fieldId: FieldID.hoa_name,
    fieldName: "hoa_name",
    fieldType: "text",
    label: "HOA Name",
  },
  {
    fieldId: FieldID.hoa_address,
    fieldName: "hoa_address",
    fieldType: "text",
    label: "HOA Address",
  },
  {
    fieldId: FieldID.hoa_telephone,
    fieldName: "hoa_telephone",
    fieldType: "text",
    label: "HOA Telephone",
  },
  {
    fieldId: FieldID.seller_hoa_judgement_initials,
    fieldName: "seller_hoa_judgement_initials",
    fieldType: "initials",
    label: "Seller HOA Judgement Initials",
  },
  {
    fieldId: FieldID.seller_two_hoa_judgement_initials,
    fieldName: "seller_two_hoa_judgement_initials",
    fieldType: "initials",
    label: "Seller 2 HOA Judgement Initials",
  },
  {
    fieldId: FieldID.seller_hoa_pending_claim_initials,
    fieldName: "seller_hoa_pending_claim_initials",
    fieldType: "initials",
    label: "Seller HOA Pending Claim Initials",
  },
  {
    fieldId: FieldID.seller_two_hoa_pending_claim_initials,
    fieldName: "seller_two_hoa_pending_claim_initials",
    fieldType: "initials",
    label: "Seller 2 HOA Pending Claim Initials",
  },
  {
    fieldId: FieldID.seller_hoa_no_knowledge_initials,
    fieldName: "seller_hoa_no_knowledge_initials",
    fieldType: "initials",
    label: "Seller HOA No Knowledge Initials",
  },
  {
    fieldId: FieldID.seller_two_hoa_no_knowledge_initials,
    fieldName: "seller_two_hoa_no_knowledge_initials",
    fieldType: "initials",
    label: "Seller 2 HOA No Knowledge Initials",
  },
  {
    fieldId: FieldID.hoa_documents,
    fieldName: "hoa_documents",
    fieldType: "document",
    label: "HOA Documents",
  },
  {
    fieldId: FieldID.seller_hoa_acknowledgement_signature,
    fieldName: "seller_hoa_acknowledgement_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller HOA Acknowledgement Signature",
  },
  {
    fieldId: FieldID.seller_hoa_acknowledgement_signature_date,
    fieldName: "seller_hoa_acknowledgement_signature_date",
    fieldType: "date",
    label: "Seller HOA Acknowledgement Signature Date",
  },
  {
    fieldId: FieldID.seller_two_hoa_acknowledgement_signature,
    fieldName: "seller_two_hoa_acknowledgement_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 HOA Acknowledgement Signature",
  },
  {
    fieldId: FieldID.seller_two_hoa_acknowledgement_signature_date,
    fieldName: "seller_two_hoa_acknowledgement_signature_date",
    fieldType: "date",
    label: "Seller 2 HOA Acknowledgement Signature Date",
  },
  {
    fieldId: FieldID.seller_comp_nominal_consideration_initial,
    fieldName: "seller_comp_nominal_consideration_initial",
    fieldType: "initials",
    label: "Seller Comp Nominal Consideration Initial",
  },
  {
    fieldId: FieldID.seller_two_comp_nominal_consideration_initial,
    fieldName: "seller_two_comp_nominal_consideration_initial",
    fieldType: "initials",
    label: "Seller 2 Comp Nominal Consideration Initial",
  },
  {
    fieldId: FieldID.seller_occupancy_comp_carry_cost_initial,
    fieldName: "seller_occupancy_comp_carry_cost_initial",
    fieldType: "initials",
    label: "Seller Occupancy Comp Carry Cost Initial",
  },
  {
    fieldId: FieldID.seller_two_occupancy_comp_carry_cost_initial,
    fieldName: "seller_two_occupancy_comp_carry_cost_initial",
    fieldType: "initials",
    label: "Seller 2 Occupancy Comp Carry Cost Initial",
  },
  {
    fieldId: FieldID.seller_occupancy_comp_flat_rate_initial,
    fieldName: "seller_occupancy_comp_flat_rate_initial",
    fieldType: "initials",
    label: "Seller Occupancy Comp Flat Rate Initial",
  },
  {
    fieldId: FieldID.seller_two_occupancy_comp_flat_rate_initial,
    fieldName: "seller_two_occupancy_comp_flat_rate_initial",
    fieldType: "initials",
    label: "Seller 2 Occupancy Comp Flat Rate Initial",
  },
  {
    fieldId: FieldID.seller_post_occupancy_signature,
    fieldName: "seller_post_occupancy_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Post Occupancy Signature",
  },
  {
    fieldId: FieldID.seller_post_occupancy_signature_date,
    fieldName: "seller_post_occupancy_signature_date",
    fieldType: "date",
    label: "Seller Post Occupancy Signature Date",
  },
  {
    fieldId: FieldID.seller_two_post_occupancy_signature,
    fieldName: "seller_two_post_occupancy_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Post Occupancy Signature",
  },
  {
    fieldId: FieldID.seller_two_post_occupancy_signature_date,
    fieldName: "seller_two_post_occupancy_signature_date",
    fieldType: "date",
    label: "Seller 2 Post Occupancy Signature Date",
  },
  {
    fieldId: FieldID.seller_anne_arundel_signature,
    fieldName: "seller_anne_arundel_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Anne Arundel Signature",
  },
  {
    fieldId: FieldID.seller_anne_arundel_signature_date,
    fieldName: "seller_anne_arundel_signature_date",
    fieldType: "date",
    label: "Seller Anne Arundel Signature Date",
  },
  {
    fieldId: FieldID.seller_two_anne_arundel_signature,
    fieldName: "seller_two_anne_arundel_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Anne Arundel Signature",
  },
  {
    fieldId: FieldID.seller_two_anne_arundel_signature_date,
    fieldName: "seller_two_anne_arundel_signature_date",
    fieldType: "date",
    label: "Seller 2 Anne Arundel Signature Date",
  },
  {
    fieldId: FieldID.seller_baltimore_city_signature,
    fieldName: "seller_baltimore_city_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Baltimore City Signature",
  },
  {
    fieldId: FieldID.seller_baltimore_city_signature_date,
    fieldName: "seller_baltimore_city_signature_date",
    fieldType: "date",
    label: "Seller Baltimore City Signature Date",
  },
  {
    fieldId: FieldID.seller_two_baltimore_city_signature,
    fieldName: "seller_two_baltimore_city_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Baltimore City Signature",
  },
  {
    fieldId: FieldID.seller_two_baltimore_city_signature_date,
    fieldName: "seller_two_baltimore_city_signature_date",
    fieldType: "date",
    label: "Seller 2 Baltimore City Signature Date",
  },
  {
    fieldId: FieldID.seller_baltimore_tax_signature_date,
    fieldName: "seller_baltimore_tax_signature_date",
    fieldType: "date",
    label: "Seller Baltimore Tax Signature Date",
  },
  {
    fieldId: FieldID.seller_two_baltimore_tax_signature,
    fieldName: "seller_two_baltimore_tax_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Baltimore Tax Signature",
  },
  {
    fieldId: FieldID.seller_two_baltimore_tax_signature_date,
    fieldName: "seller_two_baltimore_tax_signature_date",
    fieldType: "date",
    label: "Seller 2 Baltimore Tax Signature Date",
  },
  {
    fieldId: FieldID.seller_baltimore_acknowledge_signature,
    fieldName: "seller_baltimore_acknowledge_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Baltimore Acknowledge Signature",
  },
  {
    fieldId: FieldID.seller_baltimore_acknowledge_signature_date,
    fieldName: "seller_baltimore_acknowledge_signature_date",
    fieldType: "date",
    label: "Seller Baltimore Acknowledge Signature Date",
  },
  {
    fieldId: FieldID.seller_two_baltimore_acknowledge_signature,
    fieldName: "seller_two_baltimore_acknowledge_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Baltimore Acknowledge Signature",
  },
  {
    fieldId: FieldID.seller_two_baltimore_acknowledge_signature_date,
    fieldName: "seller_two_baltimore_acknowledge_signature_date",
    fieldType: "date",
    label: "Seller 2 Baltimore Acknowledge Signature Date",
  },
  {
    fieldId: FieldID.public_water_supply,
    fieldName: "public_water_supply",
    fieldType: "boolean",
    label: "Public Water Supply",
  },
  {
    fieldId: FieldID.public_sewer_system,
    fieldName: "public_sewer_system",
    fieldType: "boolean",
    label: "Public Sewer System",
  },
  {
    fieldId: FieldID.removed_from_flood_plan,
    fieldName: "removed_from_flood_plan",
    fieldType: "boolean",
    label: "Removed From Flood Plan",
  },
  {
    fieldId: FieldID.within_historic_district,
    fieldName: "within_historic_district",
    fieldType: "boolean",
    label: "Within Historic District",
  },
  {
    fieldId: FieldID.on_landmark_list,
    fieldName: "on_landmark_list",
    fieldType: "boolean",
    label: "On Landmark List",
  },
  {
    fieldId: FieldID.seller_dewatering_zone_signature,
    fieldName: "seller_dewatering_zone_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Dewatering Zone Signature",
  },
  {
    fieldId: FieldID.seller_dewatering_zone_signature_date,
    fieldName: "seller_dewatering_zone_signature_date",
    fieldType: "date",
    label: "Seller Dewatering Zone Signature Date",
  },
  {
    fieldId: FieldID.seller_two_dewatering_zone_signature,
    fieldName: "seller_two_dewatering_zone_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Dewatering Zone Signature",
  },
  {
    fieldId: FieldID.seller_two_dewatering_zone_signature_date,
    fieldName: "seller_two_dewatering_zone_signature_date",
    fieldType: "date",
    label: "Seller 2 Dewatering Zone Signature Date",
  },
  {
    fieldId: FieldID.seller_howard_county_signature,
    fieldName: "seller_howard_county_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Howard County Signature",
  },
  {
    fieldId: FieldID.seller_howard_county_signature_date,
    fieldName: "seller_howard_county_signature_date",
    fieldType: "date",
    label: "Seller Howard County Signature Date",
  },
  {
    fieldId: FieldID.seller_two_howard_county_signature,
    fieldName: "seller_two_howard_county_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2 Howard County Signature",
  },
  {
    fieldId: FieldID.seller_two_howard_county_signature_date,
    fieldName: "seller_two_howard_county_signature_date",
    fieldType: "date",
    label: "Seller 2 Howard County Signature Date",
  },
  {
    fieldId: FieldID.alarm_system,
    fieldName: "alarm_system",
    fieldType: "boolean",
    label: "Alarm System",
  },
  {
    fieldId: FieldID.existing_carpet,
    fieldName: "existing_carpet",
    fieldType: "boolean",
    label: "Existing Wall-to-Wall Carpet",
  },
  {
    fieldId: FieldID.ceiling_fans,
    fieldName: "ceiling_fans",
    fieldType: "boolean",
    label: "Ceiling Fan(s)",
  },
  {
    fieldId: FieldID.ceiling_fans_number,
    fieldName: "ceiling_fans_number",
    fieldType: "number",
    label: "Number of Ceiling Fans",
  },
  {
    fieldId: FieldID.fireplace_screens,
    fieldName: "fireplace_screens",
    fieldType: "boolean",
    label: "Fireplace Screens/Doors",
  },
  {
    fieldId: FieldID.central_vacuum,
    fieldName: "central_vacuum",
    fieldType: "boolean",
    label: "Central Vacuum",
  },
  {
    fieldId: FieldID.fireplace_equipment,
    fieldName: "fireplace_equipment",
    fieldType: "boolean",
    label: "Fireplace Equipment",
  },
  {
    fieldId: FieldID.clothes_dryer,
    fieldName: "clothes_dryer",
    fieldType: "boolean",
    label: "Clothes Dryer",
  },
  {
    fieldId: FieldID.freezer,
    fieldName: "freezer",
    fieldType: "boolean",
    label: "Freezer",
  },
  {
    fieldId: FieldID.clothes_washer,
    fieldName: "clothes_washer",
    fieldType: "boolean",
    label: "Clothes Washer",
  },
  {
    fieldId: FieldID.furnace_humidifier,
    fieldName: "furnace_humidifier",
    fieldType: "boolean",
    label: "Furnace Humidifier",
  },
  {
    fieldId: FieldID.cooktop,
    fieldName: "cooktop",
    fieldType: "boolean",
    label: "Cooktop",
  },
  {
    fieldId: FieldID.garage_openers,
    fieldName: "garage_openers",
    fieldType: "boolean",
    label: "Garage Opener(s)",
  },
  {
    fieldId: FieldID.garage_openers_number,
    fieldName: "garage_openers_number",
    fieldType: "number",
    label: "Number of Garage Openers",
  },
  {
    fieldId: FieldID.garage_remotes,
    fieldName: "garage_remotes",
    fieldType: "boolean",
    label: "Garage Remote(s)",
  },
  {
    fieldId: FieldID.garage_remotes_number,
    fieldName: "garage_remotes_number",
    fieldType: "number",
    label: "Number of Garage Remotes",
  },
  {
    fieldId: FieldID.drapery_rods,
    fieldName: "drapery_rods",
    fieldType: "boolean",
    label: "Drapery/Curtain Rods",
  },
  {
    fieldId: FieldID.draperies,
    fieldName: "draperies",
    fieldType: "boolean",
    label: "Draperies/Curtains",
  },
  {
    fieldId: FieldID.hot_tub,
    fieldName: "hot_tub",
    fieldType: "boolean",
    label: "Hot Tub, Equipment & Cover",
  },
  {
    fieldId: FieldID.electronic_air_filter,
    fieldName: "electronic_air_filter",
    fieldType: "boolean",
    label: "Electronic Air Filter",
  },
  {
    fieldId: FieldID.intercom,
    fieldName: "intercom",
    fieldType: "boolean",
    label: "Intercom",
  },
  {
    fieldId: FieldID.exhaust_fans,
    fieldName: "exhaust_fans",
    fieldType: "boolean",
    label: "Exhaust Fan(s)",
  },
  {
    fieldId: FieldID.exhaust_fans_number,
    fieldName: "exhaust_fans_number",
    fieldType: "number",
    label: "Number of Exhaust Fans",
  },
  {
    fieldId: FieldID.microwave,
    fieldName: "microwave",
    fieldType: "boolean",
    label: "Microwave",
  },
  {
    fieldId: FieldID.playground_equipment,
    fieldName: "playground_equipment",
    fieldType: "boolean",
    label: "Playground Equipment",
  },
  {
    fieldId: FieldID.tv_antenna,
    fieldName: "tv_antenna",
    fieldType: "boolean",
    label: "TV Antenna",
  },
  {
    fieldId: FieldID.pool_equipment,
    fieldName: "pool_equipment",
    fieldType: "boolean",
    label: "Pool, Equipment & Cover",
  },
  {
    fieldId: FieldID.trash_compactor,
    fieldName: "trash_compactor",
    fieldType: "boolean",
    label: "Trash Compactor",
  },
  {
    fieldId: FieldID.refrigerator,
    fieldName: "refrigerator",
    fieldType: "boolean",
    label: "Refrigerator(s)",
  },
  {
    fieldId: FieldID.refrigerator_number,
    fieldName: "refrigerator_number",
    fieldType: "number",
    label: "Number of Refrigerators",
  },
  {
    fieldId: FieldID.ice_maker,
    fieldName: "ice_maker",
    fieldType: "boolean",
    label: "Ice Maker(s)",
  },
  {
    fieldId: FieldID.ice_maker_number,
    fieldName: "ice_maker_number",
    fieldType: "number",
    label: "Number of Ice Makers",
  },
  {
    fieldId: FieldID.satellite_dish,
    fieldName: "satellite_dish",
    fieldType: "boolean",
    label: "Satellite Dish",
  },
  {
    fieldId: FieldID.screens,
    fieldName: "screens",
    fieldType: "boolean",
    label: "Screens",
  },
  {
    fieldId: FieldID.water_filter,
    fieldName: "water_filter",
    fieldType: "boolean",
    label: "Water Filter",
  },
  {
    fieldId: FieldID.shades_blinds,
    fieldName: "shades_blinds",
    fieldType: "boolean",
    label: "Shades/Blinds",
  },
  {
    fieldId: FieldID.water_softener,
    fieldName: "water_softener",
    fieldType: "boolean",
    label: "Water Softener",
  },
  {
    fieldId: FieldID.storage_sheds,
    fieldName: "storage_sheds",
    fieldType: "boolean",
    label: "Storage Shed(s)",
  },
  {
    fieldId: FieldID.storage_sheds_number,
    fieldName: "storage_sheds_number",
    fieldType: "number",
    label: "Number of Storage Sheds",
  },
  {
    fieldId: FieldID.window_ac_units,
    fieldName: "window_ac_units",
    fieldType: "boolean",
    label: "Window A/C Unit(s)",
  },
  {
    fieldId: FieldID.window_ac_units_number,
    fieldName: "window_ac_units_number",
    fieldType: "number",
    label: "Number of Window A/C Units",
  },
  {
    fieldId: FieldID.window_fans,
    fieldName: "window_fans",
    fieldType: "boolean",
    label: "Window Fan(s)",
  },
  {
    fieldId: FieldID.window_fans_number,
    fieldName: "window_fans_number",
    fieldType: "number",
    label: "Number of Window Fans",
  },
  {
    fieldId: FieldID.storm_doors,
    fieldName: "storm_doors",
    fieldType: "boolean",
    label: "Storm Doors",
  },
  {
    fieldId: FieldID.wood_stove,
    fieldName: "wood_stove",
    fieldType: "boolean",
    label: "Wood Stove",
  },
  {
    fieldId: FieldID.storm_windows,
    fieldName: "storm_windows",
    fieldType: "boolean",
    label: "Storm Windows",
  },
  {
    fieldId: FieldID.stove_range,
    fieldName: "stove_range",
    fieldType: "boolean",
    label: "Stove or Range",
  },
  {
    fieldId: FieldID.additional_inclusions,
    fieldName: "additional_inclusions",
    fieldType: "text",
    label: "Additional Inclusions",
  },
  {
    fieldId: FieldID.additional_exclusions,
    fieldName: "additional_exclusions",
    fieldType: "text",
    label: "Additional Exclusions",
  },
  {
    fieldId: FieldID.leased_fuel_tanks,
    fieldName: "leased_fuel_tanks",
    fieldType: "boolean",
    label: "Leased Fuel Tank(s)",
  },
  {
    fieldId: FieldID.leased_solar_panels,
    fieldName: "leased_solar_panels",
    fieldType: "boolean",
    label: "Leased Solar Panels",
  },
  {
    fieldId: FieldID.leased_alarm_system,
    fieldName: "leased_alarm_system",
    fieldType: "boolean",
    label: "Leased Alarm System",
  },
  {
    fieldId: FieldID.leased_water_treatment,
    fieldName: "leased_water_treatment",
    fieldType: "boolean",
    label: "Leased Water Treatment System",
  },
  {
    fieldId: FieldID.leased_other,
    fieldName: "leased_other",
    fieldType: "boolean",
    label: "Other Leased Item",
  },
  {
    fieldId: FieldID.leased_other_specify,
    fieldName: "leased_other_specify",
    fieldType: "text",
    label: "Specify Other Leased Item",
  },
  {
    fieldId: FieldID.additional_terms_leased_items,
    fieldName: "additional_terms_leased_items",
    fieldType: "text",
    label: "Additional Terms/Information Regarding Leased Items",
  },
  {
    fieldId: FieldID.utility_service_providers,
    fieldName: "utility_service_providers",
    fieldType: "text",
    label: "Utility Service Providers",
  },
  {
    fieldId: FieldID.agent_informed_initials,
    fieldName: "agent_informed_initials",
    fieldType: "initials",
    label: "Agent Informed Initials",
  },
  {
    fieldId: FieldID.buyer_assessment_waived_initials,
    fieldName: "buyer_assessment_waived_initials",
    fieldType: "initials",
    label: "Buyer Assessment Waived Initials",
  },
  {
    fieldId: FieldID.seller_landlord_lead_initials,
    fieldName: "seller_landlord_lead_initials",
    fieldType: "initials",
    label: "Seller Landlord Lead Initials",
  },
  {
    fieldId: FieldID.seller_landlord_lead_uncertain_initials,
    fieldName: "seller_landlord_lead_uncertain_initials",
    fieldType: "initials",
    label: "Seller Landlord Lead Uncertain Initials",
  },
  {
    fieldId: FieldID.initial_deposit,
    fieldName: "initial_deposit",
    fieldType: "currency",
    label: "Initial Deposit",
  },
  {
    fieldId: FieldID.initial_deposit_days,
    fieldName: "initial_deposit_days",
    fieldType: "number",
    label: "Initial Deposit Days",
  },
  {
    fieldId: FieldID.additional_deposit,
    fieldName: "additional_deposit",
    fieldType: "currency",
    label: "Additional Deposit",
  },
  {
    fieldId: FieldID.additional_deposit_date,
    fieldName: "additional_deposit_date",
    fieldType: "date",
    label: "Additional Deposit Date",
  },
  {
    fieldId: FieldID.financing_application_days,
    fieldName: "financing_application_days",
    fieldType: "number",
    label: "Financing Application Days",
  },
  {
    fieldId: FieldID.financing_commitment_days,
    fieldName: "financing_commitment_days",
    fieldType: "number",
    label: "Financing Commitment Days",
  },
  {
    fieldId: FieldID.seller_baltimore_tax_signature,
    fieldName: "seller_baltimore_tax_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Baltimore Tax Signature",
  },
  {
    fieldId: FieldID.loan_term,
    fieldName: "loan_term",
    fieldType: "number",
    label: "Loan Term in Years",
  },
  {
    fieldId: FieldID.loan_type,
    fieldName: "loan_type",
    fieldType: "text",
    label: "Loan Type",
  },
  {
    fieldId: FieldID.lender_name,
    fieldName: "lender_name",
    fieldType: "text",
    label: "Lender Name",
  },
  {
    fieldId: FieldID.lender_point_of_contact,
    fieldName: "lender_point_of_contact",
    fieldType: "text",
    label: "Lender Point of Contact",
  },
  {
    fieldId: FieldID.nmls_number,
    fieldName: "nmls_number",
    fieldType: "number",
    label: "NMLS #",
  },
  {
    fieldId: FieldID.loan_rate_type,
    fieldName: "loan_rate_type",
    fieldType: "text",
    label: "Loan Rate Type",
  },
  {
    fieldId: FieldID.min_down_payment,
    fieldName: "min_down_payment",
    fieldType: "currency",
    label: "Minimum Down Payment",
  },
  {
    fieldId: FieldID.lender_mailing_address,
    fieldName: "lender_mailing_address",
    fieldType: "text",
    label: "Lender Mailing Address",
  },
  {
    fieldId: FieldID.lender_phone,
    fieldName: "lender_phone",
    fieldType: "text",
    label: "Lender Phone:",
  },
  {
    fieldId: FieldID.lender_email,
    fieldName: "lender_email",
    fieldType: "text",
    label: "Lender Email:",
  },
  {
    fieldId: FieldID.buyer_settlement_signature,
    fieldName: "buyer_settlement_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Signature",
  },
  {
    fieldId: FieldID.buyer_two_settlement_signature,
    fieldName: "buyer_two_settlement_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Signature",
  },
  {
    fieldId: FieldID.seller_settlement_signature,
    fieldName: "seller_settlement_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Signature",
  },
  {
    fieldId: FieldID.seller_two_settlement_signature,
    fieldName: "seller_two_settlement_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2  Signature",
  },
  {
    fieldId: FieldID.buyer_settlement_signature_date,
    fieldName: "buyer_settlement_signature_date",
    fieldType: "date",
    label: "Date",
  },
  {
    fieldId: FieldID.buyer_two_settlement_signature_date,
    fieldName: "buyer_two_settlement_signature_date",
    fieldType: "date",
    label: "Date",
  },
  {
    fieldId: FieldID.seller_settlement_signature_date,
    fieldName: "seller_settlement_signature_date",
    fieldType: "date",
    label: "Date",
  },
  {
    fieldId: FieldID.seller_two_settlement_signature_date,
    fieldName: "seller_two_settlement_signature_date",
    fieldType: "date",
    label: "Date",
  },
  //
  {
    fieldId: FieldID.buyer_conventional_financing_signature,
    fieldName: "buyer_conventional_financing_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer Signature",
  },
  {
    fieldId: FieldID.buyer_two_conventional_financing_signature,
    fieldName: "buyer_two_conventional_financing_signature",
    fieldType: "signature",
    userRole: "buyer",
    label: "Buyer 2 Signature",
  },
  {
    fieldId: FieldID.seller_conventional_financing_signature,
    fieldName: "seller_conventional_financing_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller Signature",
  },
  {
    fieldId: FieldID.seller_two_conventional_financing_signature,
    fieldName: "seller_two_conventional_financing_signature",
    fieldType: "signature",
    userRole: "seller",
    label: "Seller 2  Signature",
  },
  {
    fieldId: FieldID.buyer_conventional_financing_signature_date,
    fieldName: "buyer_conventional_financing_signature_date",
    fieldType: "date",
    label: "Date",
  },
  {
    fieldId: FieldID.buyer_two_conventional_financing_signature_date,
    fieldName: "buyer_two_conventional_financing_signature_date",
    fieldType: "date",
    label: "Date",
  },
  {
    fieldId: FieldID.seller_conventional_financing_signature_date,
    fieldName: "seller_conventional_financing_signature_date",
    fieldType: "date",
    label: "Date",
  },
  {
    fieldId: FieldID.seller_two_conventional_financing_signature_date,
    fieldName: "seller_two_conventional_financing_signature_date",
    fieldType: "date",
    label: "Date",
  },
];
