import React from "react";
import Navbar from "../../components/navigationbar/Navbar";
import styled from "styled-components";
import ConnectionsColumn from "../../components/Dashboard/ConnectionsColumn";
import StatusColumn from "../../components/Dashboard/StatusColumn";
import DashboardHeader from "../../components/Dashboard/DashboardContent/DashboardHeader";
import HomeColumn from "../../components/Dashboard/HomeColumn";
import DetailsColumn from "../../components/Dashboard/DetailsColumn";

const navBarHeight = 64; // Actual height of the Navbar in pixels
const DashboardContainer = styled.div`
  display: flex;
  height: calc(100vh - ${navBarHeight}px);
  width: 100%; // Adjust for padding
  padding: 0 20px; // Corrected property name for horizontal padding
  box-sizing: border-box;
  overflow: hidden; // Prevent overflow outside this container
  background-color: #eeebff;
`;

const LeftColumn = styled.div`
  width: 380px; // Fixed width for the left column
  background-color: transparent;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  overflow: auto; // Allow scroll within the column
  box-sizing: border-box;
  margin-top: 30px; // Add margin at the top
`;

const CenterColumn = styled.div`
  flex: 1.8;
  background-color: #eeebff;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  overflow: auto; // Allow scroll within the column
  box-sizing: border-box;
  margin-top: 30px; // Add margin at the top
`;

const RightColumn = styled.div`
  max-width: 340px; // Maximum width for the right column
  background-color: transparent;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  overflow: auto; // Allow scroll within the column
  box-sizing: border-box;
  margin-top: 30px; // Add margin at the top
`;

const DashboardPage = () => {
  return (
    <div style={{ backgroundColor: "#eeebff" }}>
      <Navbar />
      <DashboardContainer>
        <LeftColumn>
          <HomeColumn />
          <ConnectionsColumn />
        </LeftColumn>
        <CenterColumn data-testid="centerColumn">
          <DashboardHeader />
        </CenterColumn>
        <RightColumn>
          <StatusColumn />
          <DetailsColumn />
        </RightColumn>
      </DashboardContainer>
    </div>
  );
};

export default DashboardPage;
