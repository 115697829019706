// src/taskModals/modals/QueenAnnesCountyDisclosuresModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const QueenAnnesCountyDisclosuresModal: Modal = {
  modalId: ModalID.QueenAnnesCountyDisclosures,
  title: "Queen Anne's County Disclosures",
  formFields: [
    { fieldId: FieldID.buyer_first_name },
    // Add other relevant fields
  ],
};
