import React, { useState, useRef, useEffect } from "react";
import * as pdfjsLib from "pdfjs-dist/webpack";

const FileUploader = ({ onFileSelect }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileSelect(file);
    }
  };

  return (
    <input type="file" onChange={handleFileChange} accept="application/pdf" />
  );
};

const DataTypeSelection = ({ onSave, onCancel }) => {
  const [dataType, setDataType] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(dataType);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "20%",
        left: "20%",
        backgroundColor: "white",
        padding: "20px",
        border: "1px solid black",
        zIndex: 100,
      }}
    >
      <form onSubmit={handleSubmit}>
        <label>
          Select data type:
          <select
            value={dataType}
            onChange={(e) => setDataType(e.target.value)}
            required
          >
            <option value="">Select...</option>
            <option value="first name">First Name</option>
            <option value="last name">Last Name</option>
            <option value="city">City</option>
            <option value="state">State</option>
          </select>
        </label>
        <button type="submit">Save</button>
        <button onClick={onCancel} type="button">
          Cancel
        </button>
      </form>
    </div>
  );
};

const PdfAnnotationPage = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const pdfCanvasRef = useRef(null);
  const overlayCanvasRef = useRef(null);
  const [rectangles, setRectangles] = useState([]);
  const [currentRect, setCurrentRect] = useState(null);
  const [showDataTypeSelection, setShowDataTypeSelection] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false); // Add a state to track if we're currently drawing

  useEffect(() => {
    const renderPDF = async () => {
      if (!pdfFile) return;

      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const typedArray = new Uint8Array(e.target.result);
        const loadingTask = pdfjsLib.getDocument({ data: typedArray });
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1.5 });
        const pdfCanvas = pdfCanvasRef.current;
        const overlayCanvas = overlayCanvasRef.current;
        pdfCanvas.height = viewport.height;
        pdfCanvas.width = viewport.width;
        overlayCanvas.height = viewport.height;
        overlayCanvas.width = viewport.width;
        const context = pdfCanvas.getContext("2d");

        await page.render({ canvasContext: context, viewport: viewport })
          .promise;
      };
      fileReader.readAsArrayBuffer(pdfFile);
    };

    renderPDF();
  }, [pdfFile]); // Only re-render the PDF when the file changes

  useEffect(() => {
    const drawRectangles = () => {
      const overlayCanvas = overlayCanvasRef.current;
      if (!overlayCanvas) return;
      const context = overlayCanvas.getContext("2d");
      // Clear overlay canvas
      context.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      // Redraw all rectangles
      rectangles.forEach((rect) => {
        context.strokeRect(rect.x, rect.y, rect.width, rect.height);
      });
      // Draw the current rectangle being drawn, if any
      if (currentRect) {
        context.strokeRect(
          currentRect.x,
          currentRect.y,
          currentRect.width,
          currentRect.height
        );
      }
    };

    drawRectangles();
  }, [rectangles, currentRect]); // Redraw rectangles as they change

  const handleMouseDown = (e) => {
    const rect = overlayCanvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setCurrentRect({ x, y, width: 0, height: 0 }); // Initialize the currentRect
    setIsDrawing(true); // Start drawing
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return; // Only draw if we are in drawing mode
    const rect = overlayCanvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    // Update currentRect based on the new mouse position
    setCurrentRect((current) => ({
      ...current,
      width: x - current.x,
      height: y - current.y,
    }));
  };

  const handleMouseUp = () => {
    if (!currentRect || !isDrawing) return;
    if (currentRect.width !== 0 && currentRect.height !== 0) {
      setShowDataTypeSelection(true); // Show the data type selection if a valid rectangle was drawn
    } else {
      setCurrentRect(null); // Reset the currentRect if the rectangle is too small
    }
    setIsDrawing(false); // Stop drawing
  };

  const handleMouseLeave = () => {
    setIsDrawing(false); // Optional: Reset drawing state when the mouse leaves the canvas
  };

  const handleSaveDataType = (dataType) => {
    if (currentRect) {
      setRectangles([...rectangles, { ...currentRect, dataType }]);
    }
    setShowDataTypeSelection(false);
    setCurrentRect(null);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div>
        <h2>PDF Annotation Tool</h2>
        <FileUploader onFileSelect={setPdfFile} />
        <div style={{ position: "relative" }}>
          <canvas ref={pdfCanvasRef} style={{ border: "1px solid black" }} />
          <canvas
            ref={overlayCanvasRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              border: "1px solid red",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
          />
        </div>
        {showDataTypeSelection && (
          <DataTypeSelection
            onSave={handleSaveDataType}
            onCancel={() => setShowDataTypeSelection(false)}
          />
        )}
      </div>
      <div style={{ marginLeft: "20px" }}>
        <h3>Selected Fields</h3>
        <ul>
          {rectangles.map((rect, index) => (
            <li key={index}>{`Field ${index + 1}: ${rect.dataType} (${Math.abs(
              rect.width
            )}x${Math.abs(rect.height)} at ${rect.x},${rect.y})`}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PdfAnnotationPage;
