const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getOffersByHome = async (homeId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=getOffersByHome&homeId=${homeId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching offers by home:", error);
    throw error;
  }
};

export const getOffersByBuyer = async (buyerId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=getOffersByBuyer&buyerId=${buyerId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching offers by buyer:", error);
    throw error;
  }
};

export const getOffersBySeller = async (sellerId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=getOffersBySeller&sellerId=${sellerId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching offers by seller:", error);
    throw error;
  }
};

export const updateOfferStatus = async (offerId: number, status: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=updateOfferStatus`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ offerId, status }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating offer status:", error);
    throw error;
  }
};

// Function to update an entire offer
export const updateOffer = async (offerId: number, updatedData: any) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=updateOffer`, {
      method: "POST", // This can be PUT or POST depending on your API design
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ offerId, ...updatedData }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating offer:", error);
    throw error;
  }
};

export const getOffersByConnectionId = async (connectionId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=getOffersByConnectionId&connectionId=${connectionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data.offers;
  } catch (error) {
    console.error("Error fetching offers by connection ID:", error);
    throw error;
  }
};

export const createOffer = async (offerData: any) => {
  try {
    // API call to create a new offer
    const response = await fetch(`${API_BASE_URL}?url=createOffer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(offerData), // Directly pass the mapped data
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data.offerId; // Return the offer_id
  } catch (error) {
    console.error("Error creating offer:", error);
    throw error;
  }
};
