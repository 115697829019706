import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import Grid, { GridSize } from "@material-ui/core/Grid";

import { FieldID } from "../taskFields/fieldIds";
import { modalRegistry } from "../taskModals/modalRegistry";
import { dataFieldList } from "../taskFields/fieldLists";
import { updateDeal } from "../../api/dealApi";
import { Offer, useDashboard } from "../../context/DashboardContext";
import { useUser } from "../../context/UserContext";
import {
  generateOfferTasks,
  generateInitialOfferReviewTask,
  generateCounterOffer,
  generateReviewOfferTasks,
  generatePurchaseAgreementTasks,
} from "../taskGenerator";
import {
  createOffer,
  updateOfferStatus,
  getOffersByConnectionId,
} from "../../api/offerApi";
import { ContentItem, FormField, DataField } from "../tasks/tasks";
import {
  saveUserSignature,
  saveUserInitials,
  getUserSignatureAndInitials,
  removeUserSignature,
  removeUserInitials,
} from "../../api/userApi";
import { acceptOfferAndUpdateDeal } from "../../api/dealApi";
import { isDataField } from "../../types/typeGaurds";
import SignatureCanvas from "react-signature-canvas";
import Switch from "@material-ui/core/Switch";
import { withStyles, makeStyles } from "@material-ui/core/styles";

// Custom Switch
const CustomSwitch = withStyles({
  switchBase: {
    color: "#D1D1D1",
    "&$checked": {
      color: "#FFFFFF",
    },
    "&$checked + $track": {
      backgroundColor: "#9D86F4",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#D1D1D1",
  },
})(Switch);

// Custom Buttons
const WhiteButton = withStyles({
  root: {
    backgroundColor: "#FFFFFF",
    color: "#9D86F4",
    border: "1px solid #9D86F4",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
})(Button);

const PurpleButton = withStyles({
  root: {
    backgroundColor: "#9D86F4",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#8F7ADE",
    },
  },
})(Button);

// Styles for Typography and other components
const useStyles = makeStyles({
  label: {
    color: "#344054",
  },
  textField: {
    marginTop: "8px",
    marginBottom: "8px",
    "& label.Mui-focused": {
      color: "#344054",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#344054",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D0D5DD",
      },
      "&:hover fieldset": {
        borderColor: "#344054",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#344054",
      },
    },
  },
  dialogContent: {
    color: "#344054",
    overflowY: "auto", // Ensure scroll if content overflows
  },
});

interface FieldRendererProps {
  field: DataField | ContentItem;
  value: any;
  displayOnly?: boolean;
  handleChange?: (fieldId: string, value: any) => void;
  userId: number;
  signatureData?: string | null;
  initialData?: string | null;
  dealPropertyValue?: any; // Value of the deal property corresponding to the field
  updateDealProperty: (
    dealId: number,
    propertyName: string,
    value: any
  ) => Promise<void>;
  dealId: number;
  disabled?: boolean; // New prop to control field disability
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const FieldRenderer = ({
  field,
  value,
  displayOnly = false,
  handleChange,
  userId,
  signatureData,
  initialData,
  dealPropertyValue,
  updateDealProperty,
  dealId,
  disabled = false, // Default to not disabled
}: FieldRendererProps) => {
  const classes = useStyles();
  const canvasRef = useRef<SignatureCanvas | null>(null);
  const [isCanvasOpen, setIsCanvasOpen] = useState(false);

  // Open the signature canvas dialog
  const openCanvas = () => {
    if (!disabled) {
      setIsCanvasOpen(true);
    }
  };

  // Close the signature canvas dialog
  const closeCanvas = () => {
    setIsCanvasOpen(false);
  };

  // Clear the signature canvas
  const handleSignatureClear = () => {
    if (canvasRef.current) {
      canvasRef.current.clear();
    }
  };

  // Handle saving signature/initials to the user table and updating the deal property
  const handleSignatureSave = async () => {
    if (canvasRef.current) {
      const base64 = canvasRef.current.toDataURL();
      try {
        if (isDataField(field)) {
          // Save signature or initials to the user table
          if (field.fieldType === "signature") {
            await saveUserSignature(userId, base64);
          } else if (field.fieldType === "initials") {
            await saveUserInitials(userId, base64);
          }
          // Update the corresponding deal property to true using fieldName
          //const propertyName = field.fieldName;
          if (handleChange) {
            handleChange(field.fieldId.toString(), false);
          }
          // await updateDealProperty(dealId, propertyName, true);
          setIsCanvasOpen(false);
        }
      } catch (error) {
        console.error("Error saving signature:", error);
        alert("Failed to save signature. Please try again.");
      }
    }
  };

  // Handle adding signature/initial by updating the deal property to true
  const handleAddSignature = async () => {
    if (isDataField(field) && !disabled) {
      const propertyName = field.fieldName;
      try {
        // Update the corresponding deal property to true
        if (handleChange) {
          handleChange(field.fieldId.toString(), true);
        }
        //  await updateDealProperty(dealId, propertyName, true);
      } catch (error) {
        console.error(`Error updating deal property ${propertyName}:`, error);
        alert(`Failed to update ${propertyName}. Please try again.`);
      }
    }
  };

  // Handle removing signature/initials by updating the deal property to false
  const handleRemove = async () => {
    if (isDataField(field) && !disabled) {
      const propertyName = field.fieldName;
      try {
        // Update the corresponding deal property to false
        if (handleChange) {
          handleChange(field.fieldId.toString(), false);
        }
        // await updateDealProperty(dealId, propertyName, false);
      } catch (error) {
        console.error(`Error updating deal property ${propertyName}:`, error);
        alert(`Failed to update ${propertyName}. Please try again.`);
      }
    }
  };

  // Handle input changes for non-signature fields
  const handleInputChange = (event: any) => {
    if (disabled) return; // Prevent changes if disabled

    const newValue =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    // If the field type is 'currency', parse the value to a number
    const parsedValue =
      isDataField(field) && field.fieldType === "currency"
        ? parseFloat(newValue.replace(/[^0-9.-]+/g, ""))
        : newValue;

    if (isDataField(field)) {
      handleChange && handleChange(field.fieldId.toString(), newValue);
    }
  };

  if (!isDataField(field)) {
    // field is a ContentItem
    return (
      <div
        dangerouslySetInnerHTML={{ __html: field.content }}
        style={{ marginBottom: "16px" }}
      />
    );
  }

  // Logic for displaying, checking, or updating signatures and initials
  if (field.fieldType === "signature" || field.fieldType === "initials") {
    const hasDealProperty =
      dealPropertyValue === 1 ||
      dealPropertyValue === true ||
      dealPropertyValue === "1";
    const hasUserData =
      field.fieldType === "signature" ? !!signatureData : !!initialData;
    const imageData =
      field.fieldType === "signature" ? signatureData : initialData;

    if (hasDealProperty && hasUserData) {
      // Case 1: Deal property is true and user has signature data
      // Render the signature/initial image and show 'Remove Signature' button
      return (
        <div style={{ marginBottom: "16px" }}>
          <Typography variant="subtitle1">{field.label}</Typography>
          <img
            src={imageData!}
            alt={field.label}
            style={{ maxWidth: "100%" }}
          />
          <div style={{ marginTop: "8px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleRemove}
              disabled={disabled}
            >
              Remove {field.fieldType === "signature" ? "Signature" : "Initial"}
            </Button>
          </div>
        </div>
      );
    } else if (!hasDealProperty && hasUserData) {
      // Case 3: Deal property is false but user has signature data
      // Display 'Add Signature' button which will update the deal property to true when clicked
      return (
        <div style={{ marginBottom: "16px" }}>
          <Typography variant="subtitle1">{field.label}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddSignature}
            disabled={disabled}
          >
            Add {field.fieldType === "signature" ? "Signature" : "Initial"}
          </Button>
        </div>
      );
    } else if (!hasDealProperty && !hasUserData) {
      // Case 2: Deal property is false and user has no signature data
      // Display 'Add Signature' button which will open the canvas to create signature
      return (
        <div style={{ marginBottom: "16px" }}>
          <Typography variant="subtitle1">{field.label}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={openCanvas}
            disabled={disabled}
          >
            Add {field.fieldType === "signature" ? "Signature" : "Initial"}
          </Button>
          <Dialog open={isCanvasOpen} onClose={closeCanvas}>
            <DialogTitle>
              {field.fieldType === "signature"
                ? "Add Signature"
                : "Add Initial"}
            </DialogTitle>
            <DialogContent>
              <SignatureCanvas
                ref={canvasRef}
                penColor="black"
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas",
                }}
              />
              <div style={{ marginTop: "8px" }}>
                <Button
                  onClick={handleSignatureClear}
                  color="secondary"
                  disabled={disabled}
                >
                  Clear
                </Button>
                <Button
                  onClick={handleSignatureSave}
                  color="primary"
                  disabled={disabled}
                >
                  Save
                </Button>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeCanvas} color="default" disabled={disabled}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    } else {
      // Should not reach here
      return null;
    }
  }

  // Render other field types
  if (displayOnly || disabled) {
    // If displayOnly or disabled, make the field read-only or disabled
    switch (field.fieldType) {
      case "boolean":
        return (
          <FormControlLabel
            control={<CustomSwitch checked={!!value} disabled />}
            label={field.label}
            labelPlacement="end"
            style={{ marginBottom: "16px" }}
          />
        );
      case "currency":
        return (
          <div style={{ marginBottom: "16px" }}>
            <Typography variant="subtitle1" className={classes.label}>
              {field.label}
            </Typography>
            <Typography variant="body1">
              {value != null ? currencyFormatter.format(value) : "N/A"}
            </Typography>
          </div>
        );
      default:
        return (
          <div style={{ marginBottom: "16px" }}>
            <Typography variant="subtitle1">{field.label}</Typography>
            <Typography variant="body1">{value || "N/A"}</Typography>
          </div>
        );
    }
  }

  // Determine if the field should be disabled based on props
  return (
    <div style={{ marginBottom: "16px" }}>
      {field.fieldType === "boolean" ? null : (
        <Typography variant="subtitle1" className={classes.label}>
          {field.label}
        </Typography>
      )}
      {field.fieldType === "text" ||
      field.fieldType === "number" ||
      field.fieldType === "currency" ? (
        <TextField
          id={field.fieldId.toString()}
          label={field.label}
          type={field.fieldType === "currency" ? "text" : field.fieldType} // Use 'text' type for currency to allow formatting
          variant="outlined"
          fullWidth
          margin="normal"
          value={value || ""}
          onChange={handleInputChange}
          disabled={disabled}
          className={classes.textField}
          InputLabelProps={{ style: { color: "#344054" } }}
          InputProps={
            field.fieldType === "currency"
              ? {
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputMode: "decimal",
                }
              : {
                  style: { color: "#344054" },
                }
          }
        />
      ) : field.fieldType === "boolean" ? (
        <FormControlLabel
          control={
            <CustomSwitch
              id={field.fieldId.toString()}
              checked={!!value}
              onChange={handleInputChange}
              disabled={disabled}
            />
          }
          label={field.label}
          labelPlacement="end"
          className={classes.label}
        />
      ) : field.fieldType === "date" ? (
        <TextField
          id={field.fieldId.toString()}
          label={field.label}
          type="date"
          InputLabelProps={{ shrink: true, style: { color: "#344054" } }}
          InputProps={{ style: { color: "#344054" } }}
          variant="outlined"
          fullWidth
          margin="normal"
          value={value || ""}
          onChange={handleInputChange}
          disabled={disabled}
          className={classes.textField}
        />
      ) : null}
    </div>
  );
};

interface ModalBuilderProps {
  modalId: number;
  onClose: () => void;
  deal: any;
  offer: any;
  task?: any;
  subtask?: any;
  userType: string; // Expected to be "seller" or "buyer"
}

const ModalBuilder: React.FC<ModalBuilderProps> = ({
  modalId,
  onClose,
  deal,
  offer,
  task,
  subtask,
  userType, // "seller" or "buyer"
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState<any>({});
  const [signatureData, setSignatureData] = useState<string | null>(null);
  const [initialData, setInitialData] = useState<string | null>(null);
  const {
    currentDeal,
    setLoadingTasks,
    setCurrentOffer,
    loadingTasks,
    triggerTasksReload,
    currentOffer,
  } = useDashboard();

  const { user } = useUser();
  const userId = user?.user_id || 0;
  const modal = modalRegistry[modalId];

  // Local state copies for deal and offer
  const [localDeal, setLocalDeal] = useState<any>(currentDeal);
  const [localOffer, setLocalOffer] = useState<any>(currentOffer);
  // Helper function to update specific deal properties
  const updateDealProperty = async (
    dealId: number,
    propertyName: string,
    value: boolean
  ) => {
    try {
      const mappedFormData = { [propertyName]: value };
      setLocalDeal((prevDeal: any) => ({
        ...prevDeal,
        [propertyName]: value,
      }));
      await updateDeal(dealId, mappedFormData);
      // Update the localDeal state
    } catch (error) {
      console.error(`Error updating deal property ${propertyName}:`, error);
      alert(`Failed to update ${propertyName}. Please try again.`);
    }
  };

  // Fetch signature and initials data from the user table
  useEffect(() => {
    const fetchSignatureAndInitials = async () => {
      try {
        const signatureAndInitials = await getUserSignatureAndInitials(userId);
        if (signatureAndInitials) {
          setSignatureData(signatureAndInitials.signature_base64 || null);
          setInitialData(signatureAndInitials.initials_base64 || null);
        }
      } catch (error) {
        console.error("Error fetching signature and initials:", error);
      }
    };

    fetchSignatureAndInitials();
  }, [userId]);

  // Update localDeal and localOffer when props change
  useEffect(() => {
    console.log("Deal updated:", deal);

    setLocalDeal(deal);
  }, [deal]);

  useEffect(() => {
    console.log("Offer updated:", offer);
    setLocalOffer(offer);
  }, [offer]);

  // Initialize form data based on modal form fields and offer/deal data
  useEffect(() => {
    const initialFormData: any = {};

    // Determine whether to get values from offer or deal based on subtask.taskType
    const isOfferTask =
      subtask &&
      (subtask.taskType === "offer" ||
        subtask.taskType === "counter" ||
        subtask.taskType === "review");

    modal.formFields.forEach((formField: any) => {
      if ("fieldId" in formField) {
        const fieldInfo = dataFieldList.find(
          (field) => field.fieldId === formField.fieldId
        );
        if (fieldInfo) {
          const propertyName = fieldInfo.fieldName;
          let value;
          if (isOfferTask && localOffer) {
            // If it's an offer-related task, get values from the offer object
            value = localOffer[propertyName];
          } else {
            // Otherwise, get values from the deal object
            value = localDeal[propertyName];
          }
          initialFormData[formField.fieldId] = value;
        } else {
          console.warn(`FieldInfo not found for fieldId: ${formField.fieldId}`);
        }
      }
    });

    console.log("initialFormData", initialFormData);
    setFormData(initialFormData);
  }, [localDeal, localOffer, modal.formFields, subtask]);

  if (!modal) {
    return <p>No modal found for the specified ID.</p>;
  }

  // Handle changes in form fields
  const handleFieldChange = (fieldId: string, value: any) => {
    console.log(`Field ${fieldId} changed:`, value);
    setFormData((prevFormData: any) => ({ ...prevFormData, [fieldId]: value }));
  };

  // Helper function to map field IDs to property names
  const mapFieldIdsToPropertyNames = (formData: any) => {
    const mappedData: any = {};
    Object.keys(formData).forEach((fieldId) => {
      const fieldInfo = dataFieldList.find(
        (field) => field.fieldId === parseInt(fieldId)
      );
      if (fieldInfo) {
        const propertyName = fieldInfo.fieldName;
        mappedData[propertyName] = formData[fieldId];
      }
    });
    return mappedData;
  };

  // Handle form submission for updating deal or offer
  const handleSubmit = async () => {
    const mappedFormData = mapFieldIdsToPropertyNames(formData);
    if (
      subtask &&
      (subtask.taskType === "offer" ||
        subtask.taskType === "counter" ||
        subtask.taskType === "review")
    ) {
      // Update offer
      try {
        // Implement your updateOffer function here
        // Example:
        // await updateOffer(localOffer.offer_id, mappedFormData);
        // For demonstration, we'll assume the offer is updated locally
        setLocalOffer((prevOffer: any) => ({
          ...prevOffer,
          ...mappedFormData,
        }));
        alert("Offer updated successfully!");
        onClose();
      } catch (error) {
        console.error("Error updating offer:", error);
        alert("Failed to update the offer. Please try again.");
      }
    } else {
      // Update deal
      try {
        mappedFormData.deal_id = localDeal.deal_id;
        await updateDeal(mappedFormData.deal_id, mappedFormData);
        setLocalDeal((prevDeal: any) => ({
          ...prevDeal,
          ...mappedFormData,
        }));
        triggerTasksReload();
        alert("Deal updated successfully!");
        onClose();
      } catch (error) {
        console.error("Error updating deal:", error);
        alert("Failed to update the deal. Please try again.");
      }
    }
  };

  // Function to generate review offer tasks
  const createReviewOfferTasks = async () => {
    const mappedFormData = mapFieldIdsToPropertyNames(formData);

    const dealId = currentDeal?.deal_id;
    const connectionId = currentDeal?.connection_id;
    const homeId = currentDeal?.home_id;
    const sellerId = currentDeal?.seller_id;
    const buyerId = currentDeal?.buyer_id;

    setLoadingTasks(true);

    if (dealId && connectionId && homeId && sellerId && buyerId) {
      try {
        const offerId = await createOffer({
          ...mappedFormData,
          deal_id: dealId,
          connection_id: connectionId,
          home_id: homeId,
          buyer_id: buyerId,
          seller_id: sellerId,
        });

        if (offerId) {
          await generateReviewOfferTasks(
            offerId,
            dealId,
            task.taskId,
            userType
          );
          alert("Tasks generated successfully.");
          triggerTasksReload();
          onClose();
        } else {
          alert("Failed to create offer.");
        }
      } catch (error) {
        console.error("Error generating review offer tasks:", error);
        alert("Failed to create offer or generate tasks.");
      } finally {
        setLoadingTasks(false);
      }
    } else {
      alert("No deal or connection found.");
      setLoadingTasks(false);
    }
  };

  // Function to generate counter offer tasks
  const generateCounterOfferTasks = async () => {
    const mappedFormData = mapFieldIdsToPropertyNames(formData);

    const dealId = currentDeal?.deal_id;
    const connectionId = currentDeal?.connection_id;
    const homeId = currentDeal?.home_id;
    const sellerId = currentDeal?.seller_id;
    const buyerId = currentDeal?.buyer_id;

    setLoadingTasks(true);

    if (dealId && connectionId && homeId && sellerId && buyerId) {
      try {
        const offerId = await createOffer({
          ...mappedFormData,
          deal_id: dealId,
          connection_id: connectionId,
          home_id: homeId,
          buyer_id: buyerId,
          seller_id: sellerId,
        });

        if (offerId) {
          await generateCounterOffer(offerId, dealId, task.taskId, userType);
          alert("Tasks generated successfully.");
          triggerTasksReload();
        } else {
          alert("Failed to create offer.");
        }
      } catch (error) {
        console.error("Error generating counter offer tasks:", error);
        alert("Failed to create offer or generate tasks.");
      } finally {
        setLoadingTasks(false);
      }
    } else {
      alert("No deal or connection found.");
      setLoadingTasks(false);
    }
  };

  const handleAccept = async () => {
    if (!offer) {
      alert("No offer available to accept.");
      return;
    }

    const confirmAccept = window.confirm(
      "Are you sure you want to accept this offer?"
    );
    if (!confirmAccept) return;

    setLoadingTasks(true); // Trigger loading state

    try {
      // Call the new API function from dealApi to accept offer and update deal
      if (currentDeal?.deal_id) {
        await acceptOfferAndUpdateDeal(currentDeal.deal_id, offer.offer_id);

        // Update the current offer in context
        setCurrentOffer(null); // Assuming the offer is now accepted and no longer current

        alert("Offer accepted and deal updated successfully!");

        // Automatically generate the Purchase Agreement Task
        await generatePurchaseAgreementTasks(
          currentDeal.deal_id,
          offer.offer_id
        );

        // Trigger task reload
        triggerTasksReload();

        onClose();
      }
    } catch (error: any) {
      console.error("Error accepting offer:", error);
      alert(error.message || "Failed to accept the offer. Please try again.");
    } finally {
      setLoadingTasks(false); // End loading state
    }
  };
  // Handler to decline the offer
  const handleDecline = async () => {
    if (!offer) {
      alert("No offer available to decline.");
      return;
    }

    const confirmDecline = window.confirm(
      "Are you sure you want to decline this offer?"
    );
    if (!confirmDecline) return;

    setLoadingTasks(true); // Trigger loading state

    try {
      // Update the offer status to 'rejected'
      await updateOfferStatus(offer.offer_id, "rejected");

      // Refresh the current offer in context
      const refreshedOffers = await getOffersByConnectionId(deal.connection_id);
      const latestAcceptedOffer = refreshedOffers.find(
        (o: Offer) =>
          o.offer_id === offer.offer_id && o.offer_status === "accepted"
      );
      setCurrentOffer(latestAcceptedOffer || null);

      alert("Offer declined successfully!");
      onClose();
    } catch (error) {
      console.error("Error declining offer:", error);
      alert("Failed to decline the offer. Please try again.");
    } finally {
      setLoadingTasks(false); // End loading state
    }
  };

  // Function to render buttons based on task type
  const renderButtons = () => {
    const taskType = subtask?.taskType || task?.taskType;

    switch (taskType) {
      case "review":
        return (
          <>
            <PurpleButton
              onClick={handleAccept}
              variant="contained"
              style={{ marginTop: "16px", marginRight: "8px" }}
              disabled={loadingTasks}
            >
              {loadingTasks ? <CircularProgress size={24} /> : "Accept"}
            </PurpleButton>
            <WhiteButton
              onClick={generateCounterOfferTasks}
              variant="contained"
              style={{ marginTop: "16px", marginRight: "8px" }}
            >
              {loadingTasks ? <CircularProgress size={24} /> : "Counter"}
            </WhiteButton>
            <WhiteButton
              onClick={handleDecline}
              variant="contained"
              style={{ marginTop: "16px" }}
              disabled={loadingTasks}
            >
              {loadingTasks ? <CircularProgress size={24} /> : "Decline"}
            </WhiteButton>
          </>
        );
      case "counter":
        return (
          <WhiteButton
            onClick={createReviewOfferTasks}
            color="primary"
            variant="contained"
            style={{ marginTop: "16px" }}
            disabled={loadingTasks}
          >
            {loadingTasks ? (
              <CircularProgress size={24} />
            ) : (
              "Submit Counter Offer"
            )}
          </WhiteButton>
        );
      case "offer":
        return (
          <PurpleButton
            onClick={createReviewOfferTasks}
            color="primary"
            variant="contained"
            style={{ marginTop: "16px" }}
            disabled={loadingTasks}
          >
            {loadingTasks ? <CircularProgress size={24} /> : "Submit Offer"}
          </PurpleButton>
        );
      default:
        return (
          <PurpleButton
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            style={{ marginTop: "16px" }}
            disabled={loadingTasks}
          >
            {loadingTasks ? <CircularProgress size={24} /> : "Submit"}
          </PurpleButton>
        );
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={false} // Disable default maxWidth to set a fixed width
      PaperProps={{
        style: { width: "808px", maxWidth: "808px" }, // Fixed width and maxWidth
      }}
    >
      <DialogTitle id="form-dialog-title">{modal.title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          {modal.formFields.map((formField, index) => {
            // Initialize variables
            let fieldElement = null;
            let gridSize: GridSize = 6; // Default to half width

            if ("content" in formField) {
              // formField is a ContentItem
              fieldElement = (
                <FieldRenderer
                  key={`content-${index}`}
                  field={formField}
                  value={null}
                  displayOnly={false}
                  handleChange={handleFieldChange}
                  userId={userId}
                  signatureData={signatureData}
                  initialData={initialData}
                  dealPropertyValue={null}
                  updateDealProperty={updateDealProperty}
                  dealId={localDeal.deal_id}
                  disabled={false}
                />
              );
              gridSize = 12; // Content items span full width
            } else {
              // formField is a FieldReference
              if (formField.options?.hidden) {
                return null;
              }

              const fieldInfo = dataFieldList.find(
                (field) => field.fieldId === formField.fieldId
              );

              if (fieldInfo) {
                // Determine the value for dealPropertyValue
                const propertyName = fieldInfo.fieldName;
                let dealPropertyValue = null;

                const isOfferTask =
                  subtask &&
                  (subtask.taskType === "offer" ||
                    subtask.taskType === "counter" ||
                    subtask.taskType === "review");

                if (isOfferTask && localOffer) {
                  dealPropertyValue = localOffer[propertyName];
                } else {
                  dealPropertyValue = localDeal[propertyName];
                }

                // Determine if the field should be disabled based on options and user type
                let isDisabled = false;
                if (formField.options?.sellerOnly && userType !== "seller") {
                  isDisabled = true;
                }
                if (formField.options?.buyerOnly && userType !== "buyer") {
                  isDisabled = true;
                }

                // Check if the field should span full width
                if (formField.options?.fullWidth) {
                  gridSize = 12;
                }

                fieldElement = (
                  <FieldRenderer
                    key={`field-${formField.fieldId}-${index}`}
                    field={fieldInfo}
                    value={formData[formField.fieldId]}
                    handleChange={handleFieldChange}
                    displayOnly={
                      modal.displayOnly || formField.options?.displayOnly
                    }
                    userId={userId}
                    signatureData={signatureData}
                    initialData={initialData}
                    dealPropertyValue={dealPropertyValue}
                    updateDealProperty={updateDealProperty}
                    dealId={localDeal.deal_id}
                    disabled={isDisabled}
                  />
                );
              } else {
                console.warn(
                  `FieldInfo not found for fieldId: ${formField.fieldId}`
                );
                return null;
              }
            }

            return (
              <Grid
                item
                xs={12}
                sm={gridSize}
                key={`grid-item-${index}`}
                style={{ width: "100%" }}
              >
                {fieldElement}
              </Grid>
            );
          })}
        </Grid>
        {renderButtons()}
      </DialogContent>
    </Dialog>
  );
};

export default ModalBuilder;
