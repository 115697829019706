// src/taskModals/modals/MontgomeryCountyDisclosuresModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const MontgomeryCountyDisclosuresModal: Modal = {
  modalId: ModalID.MontgomeryCountyDisclosures,
  title: "Montgomery County Disclosures",
  formFields: [
    { fieldId: FieldID.buyer_first_name },
    // Add other relevant fields
  ],
};
