// src/taskModals/modals/GeneralContractProvisionsModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const GeneralContractProvisionsModal: Modal = {
  modalId: ModalID.GeneralContractProvisions,
  title: "General Contract Provisions",
  formFields: [
    {
      content: `
        <h2>1. Master Plan Considerations</h2>
        <p>
          1.1 Buyers should be aware that the property, or its surrounding area, may
          be influenced by the Baltimore City Master Plan. It is recommended that
          buyers review the Master Plan to stay informed about current and future
          land use, public facilities, and infrastructure plans. For comprehensive
          details, please contact the Baltimore City Department of Planning at
          410-396-7526 or visit
          <a href="https://planning.baltimorecity.gov/master-plans"
            >Baltimore City Planning</a
          >.
        </p>
        <p>
          1.2 By signing below, the buyer acknowledges awareness that the property
          may be subject to the Master Plan’s provisions and agrees to consult the
          relevant city or state authorities to understand any applicable land use
          or development plans.
        </p>
      `,
    },
  ],
};
