import React from "react";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import { FormData } from "../../utilities/formDataTypes";

interface Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const DetailsFields: React.FC<Props> = ({ formData, setFormData }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const name = event.target.name as keyof FormData;
    const value = event.target.value as any;
    if (name) {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  return (
    <>
      <FormControl fullWidth margin="normal">
        <InputLabel id="home-type-label">Home Type</InputLabel>
        <Select
          labelId="home-type-label"
          name="home_type"
          value={formData.home_type}
          onChange={handleSelectChange}
        >
          <MenuItem value="single_family">Single Family Home</MenuItem>
          <MenuItem value="apartment">Apartment</MenuItem>
          <MenuItem value="condo">Condo</MenuItem>
          <MenuItem value="multi_family">Multi Family</MenuItem>
          <MenuItem value="land">Land</MenuItem>
          <MenuItem value="town_home">Town Home</MenuItem>
          <MenuItem value="manufactured">Manufactured</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.hoa}
            onChange={handleCheckboxChange}
            name="hoa"
          />
        }
        label="HOA"
      />
      {formData.hoa && (
        <TextField
          label="HOA Fees"
          name="hoaFees"
          variant="outlined"
          value={formData.hoaFees}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
      )}
      <FormControl fullWidth margin="normal">
        <InputLabel id="home-exterior-label">Home Exterior</InputLabel>
        <Select
          labelId="home-exterior-label"
          name="homeExterior"
          value={formData.homeExterior}
          onChange={handleSelectChange}
        >
          <MenuItem value="brick">Brick</MenuItem>
          <MenuItem value="wood">Wood</MenuItem>
          <MenuItem value="vinyl">Vinyl</MenuItem>
          <MenuItem value="stone">Stone</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="floors-label">Floors</InputLabel>
        <Select
          labelId="floors-label"
          name="floors"
          value={formData.floors}
          onChange={handleSelectChange}
        >
          <MenuItem value="1">1 Floor</MenuItem>
          <MenuItem value="2">2 Floors</MenuItem>
          <MenuItem value="3">3 Floors</MenuItem>
          <MenuItem value="4+">4+ Floors</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Year Built"
        name="yearBuilt"
        variant="outlined"
        value={formData.yearBuilt}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Roof Age"
        name="roofAge"
        variant="outlined"
        value={formData.roofAge}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Lot Size in Acres"
        name="lot_size"
        value={formData.lot_size}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Square Footage"
        name="square_footage"
        value={formData.square_footage}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
    </>
  );
};

export default DetailsFields;
