import React from "react";
import { Button } from "@mui/material";

interface StepButtonsProps {
  step: number;
  setStep: (step: number) => void;
  numSteps: number;
  handleSubmit: () => void;
}

const StepButtons: React.FC<StepButtonsProps> = ({
  step,
  setStep,
  numSteps,
  handleSubmit,
}) => {
  const isLastStep = step === numSteps - 1;

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleNext = () => {
    if (step < numSteps - 1) {
      setStep(step + 1);
    } else {
      handleSubmit(); // Handle form submission on the last step
    }
  };

  return (
    <div>
      <Button disabled={step === 0} onClick={handleBack}>
        Back
      </Button>
      <Button variant="contained" color="primary" onClick={handleNext}>
        {isLastStep ? "Finish" : "Next"}
      </Button>
    </div>
  );
};

export default StepButtons;
