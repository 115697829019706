// src/taskModals/modals/BuyersSaleFinancingSettlementModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const BuyersSaleFinancingSettlementModal: Modal = {
  modalId: ModalID.BuyersSaleFinancingSettlement,
  title: "Buyer’s Sale, Financing, Settlement of Other Real Estate",
  formFields: [
    // Title
    {
      content: `<h1>BUYER’S SALE, FINANCING, SETTLEMENT OF OTHER REAL ESTATE</h1>`,
    },
    // Introduction Paragraph
    {
      content: `
        <p>
          This Contract is dependent on the successful sale, financing, settlement,
          or lease of the Buyer's current property, as specified below. The property
          in question is located at:
          <strong>{{property_address_line_1}}</strong> ("Buyer's Property"). The
          Buyer must provide the Seller with written proof of the specified event(s)
          within the designated time frame.
        </p>
      `,
    },
    // Applicable Sections Note
    {
      content: `
        <p>
          Only the sections initialed by both Buyer and Seller (A, B, C) will be
          applicable to this Addendum.
        </p>
      `,
    },
    // Section A
    {
      content: `<p><strong>A.</strong> This Contract is contingent upon the Buyer securing a
          written sales contract for Buyer's Property by </p>`,
    },
    {
      fieldId: FieldID.buyer_property_contract_date,
    },
    // Section B
    {
      content: `<p><strong>B.</strong> This Contract is contingent upon the buyer of Buyer's
          Property obtaining a written financing commitment by </p>`,
    },
    {
      fieldId: FieldID.financing_contingency,
    },
    // Section C
    {
      content: `<p><strong>C.</strong> This Contract is contingent upon either:</p>`,
    },
    {
      content: `
        <p>
          The settlement of Buyer's Property by
        </p>
      `,
    },
    {
      fieldId: FieldID.settlement_date,
    },
    {
      content: `
        <p>
          OR <br />
          The signing of a written lease agreement for Buyer's Property by
        </p>
      `,
    },
    {
      fieldId: FieldID.buyer_property_lease_date,
    },
    // Null and Void Clause
    {
      content: `
        <p>
          If the initialed conditions are not met (and/or if the Seller does not
          receive the necessary documentation) by the specified date, either party
          may declare the Contract null and void through written notice, rendering
          it ineffective. In such a case, all Deposits will be handled according to
          the Deposit(s) paragraph of the Contract.
        </p>
        <p>
          If at the time of the Contract's execution, Buyer's Property is not under
          a sales contract or lease agreement, the property must be listed for sale
          or lease with a licensed real estate broker, a member of a multiple
          listing service, and evidence of this listing must be provided to the
          Seller within
        </p>
      `,
    },
    {
      fieldId: FieldID.buyer_days_to_list_property,
    },
    {
      content: `
        <p>
          days from the Contract Acceptance Date. Should the Seller find the listing evidence
          unsatisfactory, the Seller may declare the Contract null and void through
          written notice within
        </p>
      `,
    },
    {
      fieldId: FieldID.evidence_notice_days,
    },
    {
      content: `
        <p>
          days of receiving the evidence. In this case, all Deposits will be managed
          according to the Deposit(s) paragraph of the Contract.
        </p>
        <p>
          The Buyer cannot remove this contingency unless they provide a written
          financing commitment or other documentation proving their financial
          capability to settle on the Property independently of the sale,
          settlement, and/or lease of Buyer's Property. The Seller will have sole
          discretion to determine the sufficiency of the provided documentation. If
          the Seller deems the commitment or documentation unsatisfactory, the
          Seller may declare the Contract null and void through written notice
          within
        </p>
      `,
    },
    {
      fieldId: FieldID.seller_days_to_null_and_void,
    },
    {
      content: `
        <p>
          days of receiving the commitment or documentation. In this scenario, all Deposits will be
          handled according to the Deposit(s) paragraph of the Contract.
        </p>
        <p>
          All other terms and conditions of the Contract of Sale remain unchanged
          and in effect.
        </p>
      `,
    },
    // Buyer Signatures
    {
      fieldId: FieldID.buyer_settlement_signature,
      options: { buyerOnly: true },
    },
    {
      fieldId: FieldID.buyer_settlement_signature_date,
      options: { buyerOnly: true },
    },
    {
      fieldId: FieldID.buyer_two_settlement_signature,
      options: { buyerOnly: true },
    },
    {
      fieldId: FieldID.buyer_two_settlement_signature_date,
      options: { buyerOnly: true },
    },
    // Seller Signatures
    {
      content: `<br /> `,
    },
    {
      fieldId: FieldID.seller_settlement_signature,
      options: { sellerOnly: true },
    },
    {
      fieldId: FieldID.seller_settlement_signature_date,
      options: { sellerOnly: true },
    },
    {
      fieldId: FieldID.seller_two_settlement_signature,
      options: { sellerOnly: true },
    },
    {
      fieldId: FieldID.seller_two_settlement_signature_date,
      options: { sellerOnly: true },
    },
  ],
};
