// src/pages/home/Home.tsx
import React, { useState, KeyboardEvent } from "react";
import Navbar from "../../components/navigationbar/Navbar";
import styled from "styled-components";
import HeroImage from "../../images/extraLarge.jpg";
import { useSearch } from "../../context/SearchContext";
import { useNavigate } from "react-router-dom";

const HomePageContainer = styled.div`
  width: 100%;
`;

const HeroSection = styled.section`
  background-image: url(${HeroImage});
  background-size: cover;
  background-position: center;
  height: 75vh; // Adjust height as needed
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  border-radius: 15px; // This will give the section rounded borders
  margin: 0 15px; // This will add 15px margin horizontally

  h1 {
    font-size: 2.5rem; // Adjust font size as needed
    margin-bottom: 20px;
  }
`;

const SearchBarContainer = styled.div`
  width: 40%; // Adjust width as needed
  max-width: 600px; // Ensure the search bar is not too wide on larger screens
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  input {
    border: none;
    padding: 10px;
    width: 70%;
    margin-right: 10px; // Spacing between input and search button
  }

  button {
    background-color: red; // Use your theme's color
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
  }
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 10px;

  div {
    padding: 10px 20px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    &:hover {
      background-color: rgba(255, 255, 255);
      color: #007882;
    }

    &.active {
      border-bottom: 2px solid white;
    }
  }
`;

const HighlightText = styled.div`
  background-color: white; // White background for the highlighted text
  color: #303030; // Dark grey text color for contrast
  padding: 20px 0; // Vertical padding
  font-size: 24px; // Larger font size for emphasis
  font-weight: bold; // Bold font weight
  text-align: center;
  border-bottom: 1px solid #e0e0e0; // Optional, if you want to separate it from the Hero section with a line
`;

const SubHeaderText = styled.div`
  background-color: #ffffff; // Assuming the grey background is similar to the image
  color: #606060; // Grey text color
  padding: 10px 0; // Top and bottom padding
  font-size: 16px; // Example font size, adjust as necessary
  line-height: 1.6; // Line height for better readability
  text-align: center;
  margin-top: -5px; // To move the text up closer to the hero section if necessary
`;

const HeroTabs = () => (
  <Tabs>
    <div className="active">Buy</div>
    <div>Sell</div>
    <div>Sold</div>
  </Tabs>
);

const MainContent = styled.main`
  padding: 20px;
`;

const HomePage = () => {
  const [searchInput, setSearchInput] = useState("");
  const { setSearchParams } = useSearch();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleSearch = () => {
    const isZip = /^\d{5}(-\d{4})?$/.test(searchInput); // Regex for US ZIP code
    const isCity = /^[a-zA-Z\s]+$/.test(searchInput); // Regex for city (letters and spaces only)

    setSearchParams({
      city: isCity ? searchInput : "",
      zipCode: isZip ? searchInput : "",
      address: !isCity && !isZip ? searchInput : "",
    });
    navigate("/buy");
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <HomePageContainer>
      <Navbar />
      {/* This is the new text component */}
      <HeroSection>
        <h1>Discover a place you'll love to live</h1>
        <HeroTabs />
        <SearchBarContainer>
          <input
            type="text"
            placeholder="Enter an address, neighborhood, city, or ZIP code"
            value={searchInput}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <button onClick={handleSearch}>Search</button>
        </SearchBarContainer>
      </HeroSection>
      <HighlightText>Find Your Dream Home with Us</HighlightText>{" "}
      <SubHeaderText>
        Explore homes on Off Market — Browse to find what is right for you.
      </SubHeaderText>
      <MainContent>
        {/* Additional content sections will go here */}
      </MainContent>
    </HomePageContainer>
  );
};

export default HomePage;
