// src/taskModals/modals/BaltimoreCityDisclosuresModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const BaltimoreCityDisclosuresModal: Modal = {
  modalId: ModalID.BaltimoreCityDisclosures,
  title: "Baltimore City Disclosures",
  formFields: [
    // Title
    {
      content: `<h1>BALTIMORE CITY DISCLOSURES</h1>`,
    },
    // Section 1
    {
      content: `
        <h2>1. Master Plan Considerations</h2>
        <p>
          1.1 Buyers should be aware that the property, or its surrounding area, may
          be influenced by the Baltimore City Master Plan. It is recommended that
          buyers review the Master Plan to stay informed about current and future
          land use, public facilities, and infrastructure plans. For comprehensive
          details, please contact the Baltimore City Department of Planning at
          410-396-7526 or visit
          <a href="https://planning.baltimorecity.gov/master-plans"
            >Baltimore City Planning</a
          >.
        </p>
        <p>
          1.2 By signing below, the buyer acknowledges awareness that the property
          may be subject to the Master Plan’s provisions and agrees to consult the
          relevant city or state authorities to understand any applicable land use
          or development plans.
        </p>
      `,
    },
    {
      content: `
        <h2>2. Compliance with Local Regulations</h2>
        <p>
          2.1 If the property has been, or is currently used as a single-family
          rental dwelling within the last six months, the contract’s validity is
          contingent upon adherence to Title 13, Subtitle 6 of the Baltimore City
          Code (2004 Edition, as amended).
        </p>
        <p>
          2.2 In accordance with Subtitle 5 of the Baltimore City Zoning Ordinance
          (2005 Edition), a Transfer Certificate from the Baltimore Zoning
          Administration must be attached to the contract for properties other than
          single or two-family dwellings.
        </p>
      `,
    },
    // Section 3
    {
      content: `
        <h2>3. Newly Constructed Residences</h2>
        <p>
          3.1 Buyers of newly constructed properties in Baltimore City may qualify
          for the Baltimore City Newly Constructed Dwelling Real Property Tax
          Credit. To claim this credit, applications must be filed with the
          Baltimore City Department of Finance within 90 days post-settlement or
          upon receipt of the initial assessment notice. For details, contact
          443-984-4053 or visit
          <a
            href="https://propertytaxcredits.baltimorecity.gov/propertytaxcredits/Documents/NewConstructionTC/Newly_Constructed_Dwelling_Summary.pdf"
            >Baltimore City Property Tax Credits</a
          >.
        </p>
      `,
    },
    // Section 4
    {
      content: `
        <h2>4. Proximity to Industrial and Railroad Operations</h2>
        <p>
          4.1 The buyer should be informed that the property may be near
          heavy-industrial zones (M-3) or railroad operations. Such operations may
          result in continuous noise, odors, lights, vibrations, and other
          disturbances. For detailed information on these operations, visit
          <a href="http://CityView.BaltimoreCity.gov">Baltimore City View</a>.
        </p>
      `,
    },
    // Section 5
    {
      content: `
        <h2>5. Vacant Building Status</h2>
        <p>
          5.1 Vacant Building Notice: To legally occupy a property in Baltimore
          City, it must not have an active Vacant Building Notice. Sellers should
          verify the status of the property by checking the Baltimore City Code Map
          at
          <a href="https://cels.baltimorehousing.org/codemapv2ext/"
            >Baltimore City Code Map</a
          >
          or contacting the Department of Housing and Community Development at
          410-396-3470.
        </p>
        <p>
          5.2 The seller confirms whether the property has a current Vacant Building
          Notice and if it has been resolved. Additionally, confirmation of a valid
          Occupancy Permit from the Department of Housing and Community Development
          is required.
        </p>
      `,
    },
    // Signatures for Section 5
    {
      content: `<p>Buyer Signature:</p>`,
    },
    {
      fieldId: FieldID.buyer_baltimore_signature,
      options: { buyerOnly: true },
    },
    {
      content: `Date:`,
    },
    {
      fieldId: FieldID.buyer_baltimore_signature_date,
      options: { buyerOnly: true },
    },
    {
      content: `<br />Buyer Signature:`,
    },
    {
      fieldId: FieldID.buyer_two_baltimore_signature,
      options: { buyerOnly: true },
    },
    {
      content: `Date:`,
    },
    {
      fieldId: FieldID.buyer_two_baltimore_signature_date,
      options: { buyerOnly: true },
    },
    {
      content: `<br />Seller Signature:`,
    },
    {
      fieldId: FieldID.seller_baltimore_city_signature,
      options: { sellerOnly: true },
    },
    {
      content: `Date:`,
    },
    {
      fieldId: FieldID.seller_baltimore_city_signature_date,
      options: { sellerOnly: true },
    },
    {
      content: `<br />Seller Signature:`,
    },
    {
      fieldId: FieldID.seller_two_baltimore_city_signature,
      options: { sellerOnly: true },
    },
    {
      content: `Date:`,
    },
    {
      fieldId: FieldID.seller_two_baltimore_city_signature_date,
      options: { sellerOnly: true },
    },
    // Section 6
    {
      content: `
        <h2>6. Yield Excise Tax</h2>
        <p>
          6.1 As per Baltimore City Ordinance 19-233, transactions exceeding
          $1,000,000 are subject to a 40% Yield Tax as a transfer tax and a 15%
          Yield Tax as a recordation tax.
        </p>
        <p>
          6.2 The seller and buyer agree on the allocation of these taxes as
          follows:
        </p>
      `,
    },
    // Tax Responsibility Fields
    {
      content: `<p>If the transaction is greater than $1,000,000:<br />Seller is responsible for:</p>`,
    },
    {
      fieldId: FieldID.baltimore_tax_seller_responsibility,
    },
    {
      content: `<br />Buyer is responsible for:</p>`,
    },
    {
      fieldId: FieldID.baltimore_tax_buyer_responsibility,
    },
    {
      content: `<br />Split between seller and buyer:</p>`,
    },
    {
      fieldId: FieldID.baltimore_tax_split,
    },
    // Signatures for Section 6
    {
      content: `<p>Buyer Signature:</p>`,
    },
    {
      fieldId: FieldID.buyer_baltimore_tax_signature,
      options: { buyerOnly: true },
    },
    {
      content: `Date:`,
    },
    {
      fieldId: FieldID.buyer_baltimore_tax_signature_date,
      options: { buyerOnly: true },
    },
    {
      content: `<br />Buyer Signature:`,
    },
    {
      fieldId: FieldID.buyer_two_baltimore_tax_signature,
      options: { buyerOnly: true },
    },
    {
      content: `Date:`,
    },
    {
      fieldId: FieldID.buyer_two_baltimore_tax_signature_date,
      options: { buyerOnly: true },
    },
    {
      content: `<br />Seller Signature:`,
    },
    {
      fieldId: FieldID.seller_baltimore_tax_signature,
      options: { sellerOnly: true },
    },
    {
      content: `Date:`,
    },
    {
      fieldId: FieldID.seller_baltimore_tax_signature_date,
      options: { sellerOnly: true },
    },
    {
      content: `<br />Seller Signature:`,
    },
    {
      fieldId: FieldID.seller_two_baltimore_tax_signature,
      options: { sellerOnly: true },
    },
    {
      content: `Date:`,
    },
    {
      fieldId: FieldID.seller_two_baltimore_tax_signature_date,
      options: { sellerOnly: true },
    },
    // Acknowledgment
    {
      content: `
        <h2>Acknowledgment</h2>
        <p>
          By signing below, both parties acknowledge that they have read and
          understood the disclosures outlined in this Addendum and agree to the
          terms specified.
        </p>
        <p>All terms and conditions of the Contract of Sale remain in effect.</p>
      `,
    },
    // Final Signatures
    {
      content: `<p>Buyer Signature:</p>`,
    },
    {
      fieldId: FieldID.buyer_baltimore_acknowledge_signature,
      options: { buyerOnly: true },
    },
    {
      content: `Date:`,
    },
    {
      fieldId: FieldID.buyer_baltimore_acknowledge_signature_date,
      options: { buyerOnly: true },
    },
    {
      content: `<br />Buyer Signature:`,
    },
    {
      fieldId: FieldID.buyer_two_baltimore_acknowledge_signature,
      options: { buyerOnly: true },
    },
    {
      content: `Date:`,
    },
    {
      fieldId: FieldID.buyer_two_baltimore_acknowledge_signature_date,
      options: { buyerOnly: true },
    },
    {
      content: `<br />Seller Signature:`,
    },
    {
      fieldId: FieldID.seller_baltimore_acknowledge_signature,
      options: { sellerOnly: true },
    },
    {
      content: `Date:`,
    },
    {
      fieldId: FieldID.seller_baltimore_acknowledge_signature_date,
      options: { sellerOnly: true },
    },
    {
      content: `<br />Seller Signature:`,
    },
    {
      fieldId: FieldID.seller_two_baltimore_acknowledge_signature,
      options: { sellerOnly: true },
    },
    {
      content: `Date:`,
    },
    {
      fieldId: FieldID.seller_two_baltimore_acknowledge_signature_date,
      options: { sellerOnly: true },
    },
    {
      content: `</p>`,
    },
  ],
};
