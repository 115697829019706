export const ModalID = {
  InitialOffer: 1,
  SellerReviewOffer: 2,
  SellerCounterOffer: 3,
  BuyerCounterOffer: 4,
  BuyerReviewOffer: 5,
  // New modal IDs for purchase agreement sections
  GeneralContractProvisions: 6,
  BuyerPreApprovalDocumentation: 7,
  ConventionalFinancing: 8,
  FHAFinancing: 9,
  VAFinancing: 10,
  PropertyInspections: 11,
  MDResidentialPropertyDisclosures: 12,
  MarylandResidentialPropertyDisclaimer: 13,
  DisclosureOfInclusionsExclusions: 14,
  BuyersSaleFinancingSettlement: 15,
  NoticeToBuyerOfHOAAct: 16,
  PostSettlementOccupancyAgreement: 17,
  AnneArundelCountyDisclosures: 18,
  BaltimoreCityDisclosures: 19,
  BaltimoreCountyDisclosures: 20,
  HowardCountyDisclosures: 21,
  MontgomeryCountyDisclosures: 22,
  QueenAnnesCountyDisclosures: 23,
};
