// src/taskModals/modals/HowardCountyDisclosuresModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const HowardCountyDisclosuresModal: Modal = {
  modalId: ModalID.HowardCountyDisclosures,
  title: "Howard County Disclosures",
  formFields: [
    { fieldId: FieldID.buyer_first_name },
    // Add other relevant fields
  ],
};
