// src/taskModals/modals/AnneArundelCountyDisclosuresModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const AnneArundelCountyDisclosuresModal: Modal = {
  modalId: ModalID.AnneArundelCountyDisclosures,
  title: "Anne Arundel County Disclosures",
  formFields: [
    {
      content: `
    <h1>ANNE ARUNDEL COUNTY DISCLOSURES</h1>

    <h2>1. NOTICE REGARDING ANNE ARUNDEL COUNTY LAND-USE PLANS</h2>
    <p>
        <strong>IMPORTANT NOTICE TO BUYER:</strong> The Buyer is advised that to understand current and future plans related to land use, public facilities, public works, schools, and other regional developments that might affect the property or its surrounding area, the Buyer should reach out to relevant county departments or visit the official county website. This notice is required under Section 10-703 of the Real Property Article of the Annotated Code of Maryland, specifically for contracts concerning the sale of single-family residential properties in Anne Arundel County that include four or fewer units. If any local laws mandate similar or additional disclosures, those laws will take precedence. For further information, visit the Anne Arundel County website at <a href="http://www.aacounty.org">www.aacounty.org</a>.
    </p>

    <h2>2. NOTICE REGARDING HISTORIC DISTRICT PROPERTIES IN ANNAPOLIS</h2>
    <p>
        <strong>IMPORTANT NOTICE TO BUYER:</strong> If the property is located within the Annapolis Historic District, as specified by the Annapolis City Code, the Buyer should be aware that there are specific requirements and restrictions that apply to properties within this district. It is recommended that the Buyer consults the Historic Preservation Commission’s guidelines on their official website for more details about any potential restrictions on property modifications (<a href="http://www.annapolis.gov">www.annapolis.gov</a>).
    </p>

    <h2>3. NOTICE FOR PROPERTIES WITH WATERFRONT FEATURES</h2>
    <p>
        <strong>IMPORTANT NOTICE TO BUYER:</strong> If the property is described as or appears to be waterfront, the Buyer should employ a qualified surveyor or title expert to verify any water access rights or riparian rights associated with the property. These rights should be thoroughly investigated as part of the due diligence process.
    </p>

    <h2>4. NOTICE CONCERNING CHESAPEAKE BAY CRITICAL AREA</h2>
    <p>
        <strong>IMPORTANT NOTICE TO BUYER:</strong> If there are any ongoing or past enforcement actions initiated by Anne Arundel County or the State regarding violations of local laws, particularly those pertaining to the Chesapeake Bay Critical Area, this contract must clearly outline:
        <ol type="a">
            <li>The specific nature of the violation.</li>
            <li>The current status of any proceedings related to the enforcement of the violation.</li>
            <li>Any actions required by the Buyer to remedy or rectify the violation.</li>
        </ol>
        The Buyer is strongly encouraged to seek professional legal advice to fully comprehend the potential liabilities and responsibilities associated with such violations.
    </p>

    <h2>5. NOTICE ABOUT AIRPORT NOISE ZONES</h2>
    <p>
        <strong>IMPORTANT NOTICE TO BUYER:</strong> The property may be situated within a noise-affected area due to proximity to an airport, such as Baltimore-Washington International (BWI) Airport. This location may result in exposure to noise levels that can exceed 65 decibels during the day and night. Properties in these zones may also be subject to restrictions on new developments or changes in property use without obtaining specific variances. Buyers should contact the Maryland Aviation Administration or relevant local authorities for detailed information about noise zones and their potential impact on property use and value.
    </p>

    <h2>6. NOTICE REGARDING HOMEOWNERS INSURANCE</h2>
    <p>
        <strong>IMPORTANT NOTICE TO BUYER:</strong> The Buyer should be aware that insurance providers might deny coverage or impose higher premiums if there have been prior claims on the property. The Buyer should contact their insurance provider to get an accurate estimate of the insurance cost and terms for the property.
    </p>

    <h2>7. NOTICE REGARDING FLOOD INSURANCE</h2>
    <p>
        <strong>IMPORTANT NOTICE TO BUYER:</strong> For properties located in areas susceptible to flooding, the Buyer may be required by their lender to obtain flood insurance. The National Flood Insurance Program (NFIP) offers insurance based on assessed flood risks, and premiums may increase significantly due to recent changes in federal regulations. Buyers should not base their expectations on past flood insurance costs and are advised to seek quotes from multiple insurers to understand the current costs and available coverage options.
    </p>

    <h2>8. NOTICE OF SPECIAL TAXES AND ASSESSMENTS</h2>
    <p>
        <strong>IMPORTANT NOTICE TO BUYER:</strong> Some properties may be subject to special assessments or taxes imposed for local infrastructure improvements, such as public water or wastewater systems. The Buyer is responsible for verifying these charges, their amounts, and the terms with the local tax office. Any outstanding assessments may need to be paid by the Buyer after the purchase.
    </p>

    <h2>9. NOTICE ABOUT WELL WATER QUALITY</h2>
    <p>
        <strong>IMPORTANT NOTICE TO BUYER:</strong> If the property relies on a private well for water, especially in areas known to have radium levels exceeding those set by the U.S. Environmental Protection Agency, it is recommended that the Buyer conduct water quality testing. Testing should be done by certified professionals to ensure compliance with health and safety standards. Additional information can be obtained from local health authorities or approved environmental testing firms.
    </p>

    <p>All terms and conditions of the Contract of Sale remain in effect.</p>
      `,
    },
    // Add other relevant fields
  ],
};
