// src/context/UserContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { User, UserContextType } from "./types";
import { getFavorites } from "../api/userApi";

const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [favorites, setFavorites] = useState<any[]>([]);

  // Load user data from localStorage when the app initializes
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      fetchFavorites(parsedUser.user_id); // Fetch favorites on load if user is present
    }
  }, []);

  const fetchFavorites = async (userId: number) => {
    try {
      const data = await getFavorites(userId);
      setFavorites(data.data); // Assuming the data is returned in this structure
    } catch (error) {
      console.error("Error fetching favorites:", error);
    }
  };

  const login = (userData: User) => {
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
    fetchFavorites(userData.user_id); // Fetch favorites after login
  };

  const logout = () => {
    setUser(null);
    setFavorites([]); // Clear favorites on logout
    localStorage.removeItem("user");
  };

  return (
    <UserContext.Provider
      value={{ user, login, logout, favorites, fetchFavorites }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
