// src/taskModals/modals/MDResidentialPropertyDisclosuresModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const MDResidentialPropertyDisclosuresModal: Modal = {
  modalId: ModalID.MDResidentialPropertyDisclosures,
  title: "MD Residential Property Disclosures",
  formFields: [
    { fieldId: FieldID.property_disclosures },
    // Add other disclosure fields
  ],
};
