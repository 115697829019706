// src/taskModals/modals/MarylandResidentialPropertyDisclaimerModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const MarylandResidentialPropertyDisclaimerModal: Modal = {
  modalId: ModalID.MarylandResidentialPropertyDisclaimer,
  title: "Maryland Residential Property Disclaimer Statement",
  formFields: [
    { fieldId: FieldID.disclosures },
    // Add other disclaimer fields
  ],
};
