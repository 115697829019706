// src/taskModals/modals/BuyerPreApprovalDocumentationModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds"; // Import from modalIds.ts

export const BuyerPreApprovalDocumentationModal: Modal = {
  modalId: ModalID.BuyerPreApprovalDocumentation,
  title: "Buyer Pre-Approval Documentation",
  formFields: [
    // Title
    {
      content: `<h1>BUYER PRE-APPROVAL DOCUMENTATION</h1>`,
    },
    // Introduction Paragraph
    {
      content: `
        <p>
          Based on the information submitted by the Buyer(s) to the selected
          financial institution, mortgage lender, or mortgage broker, the Buyer(s)
          has been pre-approved for a mortgage contingent upon income verification,
          asset documentation, and a credit history review. The terms of the
          mortgage are as follows:
        </p>
      `,
    },
    // Pre-Approval Details
    {
      fieldId: FieldID.pre_approval_date,
    },
    {
      fieldId: FieldID.loan_program,
    },
    {
      fieldId: FieldID.purchase_price,
    },
    {
      fieldId: FieldID.interest_rate,
    },
    {
      fieldId: FieldID.min_down_payment,
    },
    {
      fieldId: FieldID.loan_rate_type,
    },
    {
      fieldId: FieldID.total_loan_amount,
    },
    {
      fieldId: FieldID.loan_term,
    },
    {
      fieldId: FieldID.loan_type,
    },
    // Lender Information
    {
      content: `<h2>LENDER INFORMATION:</h2>`,
    },
    {
      fieldId: FieldID.lender_name,
    },
    {
      fieldId: FieldID.lender_point_of_contact,
    },
    {
      fieldId: FieldID.nmls_number,
    },
    {
      fieldId: FieldID.lender_mailing_address,
    },
    {
      fieldId: FieldID.lender_phone,
    },
    {
      fieldId: FieldID.lender_email,
    },
    // Additional Paragraph
    {
      content: `
        <p>
          Pre-approval is based on the information currently provided. Any changes
          or unverifiable data during the verification process may impact approval.
          This pre-approval does not constitute a formal commitment to lend, as your
          loan application has yet to undergo final approval. An underwriter must
          review your income, assets, appraisal, title, and obtain updated automated
          approval before official loan approval. The Interest Rate (Note Rate) and
          Annual Percentage Rate (APR) are estimates as of the pre-approval date and
          may change until a sales contract is finalized and the interest rate is
          locked.
        </p>
      `,
    },
  ],
};
