// src/components/dashboard/StatusColumn.tsx
import React from "react";
import Typography from "@mui/material/Typography";
import Check from "@mui/icons-material/Check";
import styled, { css } from "styled-components";

// Define the types for the props
type CircleProps = {
  status: "completed" | "working" | "upcoming";
};

const StatusBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;
const Line = styled.div`
  flex-grow: 1;
  height: 2px;
  background-color: #e0e0e0;
`;

const CustomCheck = styled(Check)`
  color: #3f51b5; /* your checkmark color */
`;

const Circle = styled.div<CircleProps>`
  position: relative; /* Needed to position the pseudo-element correctly */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: #ffffff; /* Set the interior color to white */
  border: 2px solid #e0e0e0; /* Set the border to a thin grey line */
  border-color: ${(props) =>
    props.status === "completed"
      ? "#57FF8C"
      : props.status === "working"
      ? "#FF9E9C"
      : props.status === "upcoming"
      ? "#FFC45D"
      : "transparent"};

  /* Add a pseudo-element for the line below the circle */
  &:after {
    content: "";
    position: absolute;
    bottom: -50px; /* Adjust this value based on your layout */
    left: 50%;
    transform: translateX(-50%); /* Center the line */
    width: 2px; /* Line width */
    height: calc(100% + 15px); /* Line height, adjust as needed */
    background-color: #e0e0e0; /* Line color */
  }
`;
const statuses = [
  {
    label: "Accept Offer",
    description: "Your Task Upload Document is completed",
    date: "Sat 25th Oct.",
    status: "completed",
  },
  {
    label: "Schedule Inspection",
    description: "Schedule your home inspection",
    date: "Mon 27th Oct",
    status: "working",
  },
  {
    label: "Loan Approval",
    description: "Get your loan approved",
    date: "Wed 29th Oct.",
    status: "upcoming",
  },
  {
    label: "Document Verification",
    description: "Verify your documents",
    date: "Sat 30th Oct.",
    status: "completed",
  },
  {
    label: "Closing",
    description: "Close on your home",
    date: "Sun 31st Oct.",
    status: "upcoming",
  },
];

const ScrollableContainer = styled.div`
  height: calc(100vh - 200px);
  overflow-y: auto;
  display: flex;
  border-radius: 12px; // Set border-radius to 10px
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  // Hide scrollbar for Chrome, Safari and Opera
  ::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  flex-grow: 1; // Allow this part to take up the available space
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // Hide scrollbar for Chrome, Safari and Opera
  ::-webkit-scrollbar {
    display: none;
  }
  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
`;
const StatusHeader = styled.div`
  padding: 10px;
  font-weight: bold;
  color: black;
  background-color: white; // Set the background color so it's not transparent when scrolling
  border-radius: 12px 12px 0 0; // Round only the top corners
`;

const StatusInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusColumn: React.FC = () => {
  return (
    <ScrollableContainer>
      <StatusHeader>
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", color: "black", textAlign: "left" }}
        >
          Status
        </Typography>
        <Line />
      </StatusHeader>
      <ScrollableContent>
        {statuses.map((item, index) => (
          <StatusBlock key={index}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <Circle
                  status={item.status as "completed" | "working" | "upcoming"}
                />
              </div>
              <div style={{ justifyContent: "column" }}>
                <StatusInfo>
                  <Typography
                    variant="subtitle1"
                    color="#303030"
                    style={{ fontWeight: "bold", textAlign: "left" }} // Ensure text is aligned to the left
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#605F5F"
                    style={{ textAlign: "left" }}
                  >
                    {item.description}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#605F5F"
                    style={{ textAlign: "left" }}
                  >
                    {item.date}
                  </Typography>
                </StatusInfo>
              </div>
            </div>
          </StatusBlock>
        ))}
      </ScrollableContent>
    </ScrollableContainer>
  );
};

export default StatusColumn;
