// src/components/Dashboard/DashboardContent/MainPage.tsx
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import MockHomeList from "../../../mocks/mockData/mockHomeList.json"; // Import the mock data`
import { useDashboard } from "../../../context/DashboardContext"; // Adjust the import path as necessary

const ScrollableContainer = styled.div`
  height: calc(100vh - 200px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const HomeCard = styled(Card)`
  margin-bottom: 10px;
  height: 700px;
`;

const MainPage: React.FC = () => {
  const { propertySelected } = useDashboard(); // Use the context to get the selected property ID

  const [mockHomes, setMockHomes] = React.useState(MockHomeList); // Use the mock data
  const [home, setHome] = React.useState(
    mockHomes.find((home) => home.id === propertySelected)
  );

  React.useEffect(() => {
    // Update home state when propertySelected changes
    setHome(mockHomes.find((home) => home.id === propertySelected));
  }, [propertySelected, mockHomes]);

  return (
    <ScrollableContainer>
      {home ? (
        <HomeCard>
          <CardContent>
            <Typography variant="h5" component="div">
              {home.title}
            </Typography>
            <Typography variant="body1">{home.description}</Typography>
            <Typography variant="body1">Price: {home.price}</Typography>
          </CardContent>
        </HomeCard>
      ) : (
        <Typography variant="body1">
          {propertySelected
            ? `No home found with id: ${propertySelected}`
            : "Select a home to view details"}
        </Typography>
      )}
    </ScrollableContainer>
  );
};

export default MainPage;
