import React from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent, // Import this type
} from "@mui/material";
import { FormData } from "../../utilities/formDataTypes";

interface Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const LayoutFields: React.FC<Props> = ({ formData, setFormData }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  // Update this function to handle number values
  const handleSelectChange = (event: SelectChangeEvent) => {
    const name = event.target.name as keyof FormData; // Make sure the name is a key in the FormData type
    let value: string | number = event.target.value; // The value is a string by default
    if (name === "beds" || name === "baths" || name === "garage_spots") {
      value = parseInt(value); // Convert to number if the field is 'beds' or 'bathrooms'
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.basement}
            onChange={handleCheckboxChange}
            name="basement"
          />
        }
        label="Basement"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.garage}
            onChange={handleCheckboxChange}
            name="garage"
          />
        }
        label="Garage"
      />
      {formData.garage && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="garage-spots-label">Garage Spots</InputLabel>
          <Select
            labelId="garage-spots-label"
            name="garage_spots"
            value={formData.garage_spots}
            onChange={handleSelectChange}
          >
            <MenuItem value="1">1 Spot</MenuItem>
            <MenuItem value="2">2 Spots</MenuItem>
            <MenuItem value="3">3 Spots</MenuItem>
            <MenuItem value="4+">4+ Spots</MenuItem>
          </Select>
        </FormControl>
      )}
      <FormControl fullWidth margin="normal">
        <InputLabel id="beds-label">Bedrooms</InputLabel>
        <Select
          labelId="beds-label"
          name="beds"
          value={formData.beds}
          onChange={handleSelectChange}
        >
          <MenuItem value="1">1 Bedroom</MenuItem>
          <MenuItem value="2">2 Bedrooms</MenuItem>
          <MenuItem value="3">3 Bedrooms</MenuItem>
          <MenuItem value="4">4 Bedrooms</MenuItem>
          <MenuItem value="5">5+ Bedrooms</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="bathrooms-label">Bathrooms</InputLabel>
        <Select
          labelId="bathrooms-label"
          name="baths"
          value={formData.baths}
          onChange={handleSelectChange}
        >
          <MenuItem value="1">1 Bathroom</MenuItem>
          <MenuItem value="2">2 Bathrooms</MenuItem>
          <MenuItem value="3">3 Bathrooms</MenuItem>
          <MenuItem value="4">4 Bathrooms</MenuItem>
          <MenuItem value="5">5+ Bathrooms</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default LayoutFields;
