// src/components/Dashboard/DashboardContent/TaskPage.tsx
import React, { useState, useEffect, useCallback } from "react";
import ModalBuilder from "../../../taskSystem/modalBuilder/ModalBuilder";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  Button,
  CircularProgress, // Import CircularProgress for loading indicators
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EventNoteIcon from "@mui/icons-material/EventNote";
import styled from "styled-components";
import { SelectProps } from "@mui/material/Select";
import { useDashboard } from "../../../context/DashboardContext";
import { useUser } from "../../../context/UserContext";
import {
  fetchDealByConnection,
  fetchTasksByDealId,
} from "../../../api/taskApi";
import {
  generateOfferTasks,
  generatePurchaseAgreementTasks,
} from "../../../taskSystem/taskGenerator";
import { getDealById } from "../../../api/dealApi"; // Import the new API function
import Handlebars from "handlebars"; // Import Handlebars
import html2pdf from "html2pdf.js"; // Import html2pdf.js
import { getUserSignatureAndInitials } from "../../../api/userApi";
import { dataFieldList } from "../../../taskSystem/taskFields/fieldLists";
import { DataField } from "../../../taskSystem/tasks/tasks";

type StyledSelectProps = {
  status: "completed" | "working" | "upcoming";
} & SelectProps;

export interface SignatureData {
  signature_base64: string | null;
  initials_base64: string | null;
  has_signature: number;
  has_initials: number;
}

export interface SignatureResponse {
  message: string;
  data: SignatureData;
}

export interface DealData {
  deal_id: number;
  buyer_id: number;
  seller_id: number;
  [key: string]: any; // To accommodate other dynamic fields
}

export interface DealResponse {
  deal: DealData;
}

export interface TransformedDealData {
  [key: string]: string;
}

const StyledTableHead = styled(TableHead)`
  background-color: ${(props) => props.color || "#c5cae9"};
  border-radius: 15px 15px 0 0;
  .MuiTableCell-root:first-of-type {
    border-top-left-radius: 15px;
  }
  .MuiTableCell-root:last-of-type {
    border-top-right-radius: 15px;
  }
`;

const StyledTableCell = styled(TableCell)`
  border-bottom: none;
  vertical-align: middle;
`;

const StyledTableCellRow = styled(TableCell)`
  border-bottom: none;
  padding: 8px;
`;

const TaskDate = styled(Typography)`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const SubtaskRow = styled(TableRow)`
  background-color: #ffffff;
  display: flex;
  border: 1px solid #e0e0e0;
`;

const SubtaskLabel = styled(Typography)``;

const DownloadIcon = styled(CloudDownloadIcon)`
  margin-left: auto;
`;

const StyledSelect = styled(Select)<StyledSelectProps>`
  &.MuiSelect-outlined.MuiSelect-select {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 24px;
    background-color: ${({ status }) => getColor(status)};
    font-size: 0.875rem;
    line-height: normal;
  }
  & .MuiSvgIcon-root {
    right: 5px;
  }
  &.MuiOutlinedInput-root {
    height: 21px;
  }
`;

const TaskIcon = styled(CloudDownloadIcon)`
  margin-right: 8px;
`;

const TableWrapper = styled.div`
  padding-bottom: 30px;
`;

const getColor = (status: string) => {
  switch (status) {
    case "completed":
      return "#57FF8C";
    case "working":
      return "#FF9E9C";
    case "upcoming":
      return "#FFC45D";
    default:
      return "#c5cae9";
  }
};

// Styled Button for "Make an Offer"
const MakeOfferButton = styled(Button)`
  margin: 10px 0;
`;

const TaskPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tasks, setTasks] = useState<any[]>([]);
  const [selectedSubtask, setSelectedSubtask] = useState<any | null>(null);
  const {
    currentConnection,
    currentDeal,
    currentOffer,
    setLoadingTasks,
    setAllConnections,
    setCurrentOffer, // Ensure this is destructured
    tasksReloadTrigger, // Destructure tasksReloadTrigger
    triggerTasksReload, // Destructure triggerTasksReload if needed
    loadingTasks, // Keep for loading state
  } = useDashboard();
  const [loading, setLoading] = useState(false); // May not be needed
  const { user } = useUser();
  const userId = user?.user_id;
  const [userType, setUserType] = useState<string>("buyer");

  // Dependency to trigger re-fetching tasks
  const [selectedTask, setSelectedTask] = useState<any | null>(null);

  useEffect(() => {
    const userType =
      currentConnection?.buyer_id === userId ? "buyer" : "seller";
    setUserType(userType);
  }, [currentConnection, currentDeal, currentOffer, userId]);

  const handleGeneratePurchaseAgreementTasks = async () => {
    if (currentDeal) {
      setLoadingTasks(true);
      try {
        await generatePurchaseAgreementTasks(
          currentDeal.deal_id,
          currentDeal.offer_id
        );
        alert("Purchase Agreement tasks generated successfully.");
        // Trigger task reload
        triggerTasksReload();
      } catch (error) {
        console.error("Error generating purchase agreement tasks:", error);
        alert("Failed to generate purchase agreement tasks.");
      } finally {
        setLoadingTasks(false);
      }
    } else {
      alert("No current deal found.");
    }
  };

  const handlePrintContract = async () => {
    console.log("Printing contract...");

    // Set loading state to true to indicate that the document is being generated
    setLoading(true);

    try {
      if (!currentDeal) {
        alert("No current deal found.");
        return;
      }

      // Fetch deal data
      const dealResponse: DealResponse = await getDealById(currentDeal.deal_id);
      const dealData: DealData = dealResponse.deal;
      console.log("Deal data fetched:", dealData);

      // Extract buyer and seller IDs
      const buyerId = dealData.buyer_id;
      const sellerId = dealData.seller_id;

      // Fetch signatures and initials for buyer and seller
      const [buyerSignatureResponse, sellerSignatureResponse] =
        await Promise.all([
          getUserSignatureAndInitials(buyerId),
          getUserSignatureAndInitials(sellerId),
        ]);

      // Assuming getUserSignatureAndInitials returns SignatureData directly
      const buyerSignatureData: SignatureData = buyerSignatureResponse;
      const sellerSignatureData: SignatureData = sellerSignatureResponse;

      // Create a map for easy access to signatures and initials
      const signatureMap: { [key: string]: string } = {
        buyer_signature:
          buyerSignatureData.has_signature &&
          buyerSignatureData.signature_base64
            ? `<img src="${buyerSignatureData.signature_base64}" alt="Buyer Signature" />`
            : "&#x2610;", // Empty checkbox
        buyer_initials:
          buyerSignatureData.has_initials && buyerSignatureData.initials_base64
            ? `<img src="${buyerSignatureData.initials_base64}" alt="Buyer Initials" />`
            : "",
        seller_signature:
          sellerSignatureData.has_signature &&
          sellerSignatureData.signature_base64
            ? `<img src="${sellerSignatureData.signature_base64}" alt="Seller Signature" />`
            : "&#x2610;", // Empty checkbox
        seller_initials:
          sellerSignatureData.has_initials &&
          sellerSignatureData.initials_base64
            ? `<img src="${sellerSignatureData.initials_base64}" alt="Seller Initials" />`
            : "",
        // Add more mappings if there are multiple signatures or initials
      };

      // Function to transform deal data based on field types
      const transformDealData = (
        dealData: DealData,
        dataFieldList: DataField[],
        signatureMap: { [key: string]: string }
      ): TransformedDealData => {
        const transformedData: TransformedDealData = { ...dealData };

        dataFieldList.forEach((field) => {
          const { fieldName, fieldType, userRole } = field;
          const value = dealData[fieldName];

          if (fieldType === "boolean") {
            // Replace boolean with checkbox HTML
            transformedData[fieldName] = value
              ? "&#x2611;" // ☑ Checkmark
              : "&#x2610;"; // ☐ Empty checkbox
          } else if (fieldType === "signature" && dealData[fieldName]) {
            if (userRole === "buyer") {
              transformedData[fieldName] = signatureMap.buyer_signature || "";
            } else if (userRole === "seller") {
              transformedData[fieldName] = signatureMap.seller_signature || "";
            }
            // Replace with image HTML or empty string
            //transformedData[fieldName] = signatureMap[fieldName] || "";
          } else if (fieldType === "initials" && dealData[fieldName]) {
            if (userRole === "buyer") {
              transformedData[fieldName] = signatureMap.buyer_initials || "";
            } else if (userRole === "seller") {
              transformedData[fieldName] = signatureMap.seller_initials || "";
            }
            // Replace with image HTML or empty string
            //transformedData[fieldName] = signatureMap[fieldName] || "";
          }
          // Add more transformations if needed
        });

        return transformedData;
      };

      // Verify that dataFieldList is defined
      if (!dataFieldList || dataFieldList.length === 0) {
        console.error("dataFieldList is undefined or empty.");
        alert("Failed to generate contract. Please contact support.");
        return;
      }

      // Transform the deal data
      const transformedDealData: TransformedDealData = transformDealData(
        dealData,
        dataFieldList,
        signatureMap
      );

      // Fetch templates
      const templateUrls = [
        "/templates/AnneArundelCountyDisclosures.html",
        "/templates/BaltimoreCityDisclosures.html",
        "/templates/BaltimoreCountyDisclosures.html",
        "/templates/BuyerPreApprovalDocumentation.html",
        "/templates/BuyersSaleFinancingSettlementOfOtherRealEstate.html",
        "/templates/ConventionalFinancing.html",
        "/templates/DisclosureOfInclusionsExclusions.html",
      ];

      const templatePromises = templateUrls.map((url) =>
        fetch(url).then((res) => {
          if (!res.ok) {
            throw new Error(
              `Failed to fetch template at ${url}: ${res.statusText}`
            );
          }
          return res.text();
        })
      );
      const templates = await Promise.all(templatePromises);

      // Parse templates with Handlebars and transformed deal data
      const compiledTemplates = templates.map((template, index) => {
        const compiledTemplate = Handlebars.compile(template);
        const compiledHtml = compiledTemplate(transformedDealData);
        console.log(
          `Compiled template ${index + 1}:`,
          compiledHtml.slice(0, 100) // log first 100 chars
        );
        return compiledHtml;
      });

      // Generate PDFs from parsed HTML
      const options = {
        margin: 0.5,
        filename: "contract.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      const injectedStyles = `
      <style>
        body {
          font-family: Arial, Helvetica, sans-serif;
        }
        h1, h2 {
          word-spacing: normal; /* Adjust as needed */
          margin-bottom: 0.5em; /* Optional */
        }
      </style>
    `;

      // Create a container to hold all the pages
      const container = document.createElement("div");

      // Inject the styles once at the beginning
      container.innerHTML += injectedStyles;

      compiledTemplates.forEach((html, index) => {
        const page = document.createElement("div");
        page.innerHTML = html;
        container.appendChild(page);
        console.log(`Added template ${index + 1} to container.`);
      });

      await html2pdf()
        .set(options)
        .from(container)
        .outputPdf("bloburl")
        .then((pdfUrl: string) => {
          console.log("PDF generated at:", pdfUrl);
          window.open(pdfUrl, "_blank");
        });
    } catch (error) {
      console.error("Error printing contract:", error);
      alert(
        "An error occurred while printing the contract. Please check the console for details."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (subtask: any) => {
    if (subtask[userType].required) {
      const parentTask = tasks.find((task) => task.subtasks.includes(subtask));
      if (parentTask) {
        setSelectedSubtask(subtask);
        setSelectedTask(parentTask);
        setIsModalOpen(true);
      } else {
        console.warn("Parent task not found for the selected subtask.");
      }
    }
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSubtask(null); // Reset selected subtask on close
  };

  const handleMakeOfferClick = async () => {
    if (currentConnection) {
      setLoadingTasks(true);
      try {
        const dealResponse = await fetchDealByConnection({
          connectionId: currentConnection.connection_id,
        });

        if (dealResponse) {
          await generateOfferTasks(
            dealResponse.deal.offer_id,
            dealResponse.deal.deal_id
          );
          alert("Tasks generated successfully.");
          // Trigger task reload
          triggerTasksReload();
        } else {
          alert("No deal found for this connection.");
        }
      } catch (error) {
        console.error("Error making offer:", error);
        alert("Failed to make offer.");
      } finally {
        setLoadingTasks(false);
      }
    } else {
      alert("No connection found.");
    }
  };

  useEffect(() => {
    const userType =
      currentConnection?.buyer_id === userId ? "buyer" : "seller";
    setUserType(userType);
  }, [currentConnection, currentDeal, currentOffer, userId]);

  const loadTasks = useCallback(async () => {
    if (currentConnection) {
      // setLoadingTasks(true);
      try {
        const dealResponse = await fetchDealByConnection({
          connectionId: currentConnection.connection_id,
        });

        if (dealResponse) {
          const tasksResponse = await fetchTasksByDealId(
            dealResponse.deal.deal_id
          );
          setTasks(tasksResponse.tasks);
        }
      } catch (error) {
        console.error("Error loading tasks:", error);
      } finally {
        // setLoadingTasks(false);
      }
    }
  }, [currentConnection, setLoadingTasks]);

  useEffect(() => {
    loadTasks(); // Initial load

    const intervalId = setInterval(() => {
      loadTasks();
    }, 5000); // Call loadTasks every 5 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [loadTasks, tasksReloadTrigger, currentConnection]);

  return (
    <div style={{ paddingRight: 20, paddingLeft: 20 }}>
      {loading && <p>Building the document, please wait...</p>}
      {/* Conditional Rendering of "Make an Offer" Button */}
      {tasks.length < 1 && currentOffer && userType === "buyer" && (
        <MakeOfferButton
          variant="contained"
          color="primary"
          onClick={handleMakeOfferClick}
          disabled={loadingTasks}
          startIcon={loadingTasks ? <CircularProgress size={20} /> : null}
        >
          {loadingTasks ? "Generating Tasks..." : "Make an Offer"}
        </MakeOfferButton>
      )}

      {/* Other Action Buttons */}
      <div style={{ marginBottom: "20px" }}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CloudDownloadIcon />}
          onClick={handlePrintContract}
          style={{ marginRight: "10px" }}
        >
          Print Contract
        </Button>
        {/* <Button
          variant="contained"
          color="success"
          onClick={handleGeneratePurchaseAgreementTasks}
          disabled={loading}
        >
          Generate Purchase Agreement Tasks
        </Button> */}
      </div>

      {/* Task Table */}
      <TableContainer component={Paper} elevation={0} square>
        {tasks.map((task) => (
          <TableWrapper key={`task-${task.task_id}`}>
            <Table>
              <StyledTableHead color={getColor(task.status)}>
                <TableRow>
                  <StyledTableCell colSpan={3}>
                    <Typography variant="h6" color="white">
                      {task.label}
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {task.subtasks.map((subtask: any) => (
                  <SubtaskRow
                    key={`subtask-${subtask.subtask_id}`}
                    onClick={() => handleRowClick(subtask)}
                    style={{
                      backgroundColor: subtask[userType].required
                        ? "#ffffff"
                        : "#f0f0f0",
                      cursor: subtask[userType].required
                        ? "pointer"
                        : "default",
                      color: subtask[userType].required ? "inherit" : "#a0a0a0",
                    }}
                  >
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox checked={subtask.status === "completed"} />
                        <SubtaskLabel variant="body1">
                          {subtask.label}
                        </SubtaskLabel>
                        <IconButton
                          size="small"
                          disabled={!subtask[userType].required}
                        >
                          <TaskIcon />
                        </IconButton>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledSelect
                        status={
                          subtask.status as "completed" | "working" | "upcoming"
                        }
                        value={subtask.status}
                        variant="outlined"
                        disabled={!subtask[userType].required}
                      >
                        <MenuItem value="completed">Completed</MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                      </StyledSelect>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TaskDate variant="body2">
                        <EventNoteIcon fontSize="small" />
                        {subtask.date
                          ? new Date(subtask.date).toLocaleDateString()
                          : "No date"}
                      </TaskDate>
                    </StyledTableCell>
                  </SubtaskRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        ))}
      </TableContainer>

      {/* Modal for Subtasks */}
      {isModalOpen && selectedSubtask && (
        <ModalBuilder
          modalId={selectedSubtask.modalId} // Use selectedSubtask
          onClose={handleCloseModal}
          deal={currentDeal}
          offer={currentOffer}
          task={selectedTask}
          subtask={selectedSubtask} // Pass the selected subtask
          userType={userType}
        />
      )}
    </div>
  );
};

export default TaskPage;
