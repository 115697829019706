// src/api/homeApi.ts
import { FormData as FormInfo } from "../components/sellersForm/utilities/formDataTypes"; // Adjust path as necessary

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const saveHomeInfo = async (homeData: FormInfo) => {
  try {
    // Fetch geolocation (existing code)
    const fullAddress = `${homeData.address}, ${homeData.city}, ${homeData.state} ${homeData.zipCode}`;
    const geoLocation = await getLatLngFromAddress(fullAddress);

    if (geoLocation) {
      homeData.latitude = geoLocation.lat;
      homeData.longitude = geoLocation.lng;
    }

    // Initialize FormData object
    const formData = new FormData();

    // Append each photo to the FormData object
    homeData.photos.forEach((photo: File) => {
      formData.append("photos[]", photo);
    });

    // Append other form fields to FormData
    Object.entries(homeData).forEach(([key, value]) => {
      if (key !== "photos") {
        formData.append(key, value as string | Blob);
      }
    });

    // Send form data to the backend API
    const response = await fetch(`${API_BASE_URL}/index.php?url=createHome`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error saving home information:", error);
  }
};

const getLatLngFromAddress = async (address: string) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=AIzaSyCBxFttxvInvMZVnsmWhJCJQ2Y911zN8io`
    );

    const data = await response.json();

    if (data.status === "OK" && data.results.length > 0) {
      const location = data.results[0].geometry.location;
      return {
        lat: location.lat,
        lng: location.lng,
      };
    } else {
      console.error("Geocoding API error:", data.status);
      return null;
    }
  } catch (error) {
    console.error("Error fetching geolocation:", error);
    return null;
  }
};

export const getHome = async (homeId: string) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/index.php?url=getHome&homeId=${homeId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching home details:", error);
  }
};

export const getUserHomes = async (userId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/index.php?url=getUserHomes&userId=${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user homes:", error);
  }
};

export const checkUserRole = async (userId: number, homeId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/index.php?url=checkUserRole`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, homeId }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error checking user role:", error);
  }
};

export const isOwner = async (userId: number, homeId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}/index.php?url=isOwner`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, homeId }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error checking if user is owner:", error);
  }
};

export const updateFavorite = async (userId: number, homeId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}/index.php?url=favoriteHome`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, homeId }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating favorite status:", error);
  }
};
