const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const createConnection = async (buyerId: number, homeId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=createConnection`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ buyerId, homeId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating connection:", error);
    throw error;
  }
};

export const getConnectionByBuyerAndHome = async (
  buyerId: number,
  homeId: number
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=getConnectionByBuyerAndHome`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ buyerId, homeId }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching connection by buyer and home:", error);
    throw error;
  }
};

export const getConnectionsBySeller = async (sellerId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=getConnectionsBySeller&sellerId=${sellerId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching connections by seller:", error);
  }
};

export const getConnectionsByBuyer = async (buyerId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=getConnectionsByBuyer&buyerId=${buyerId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching connections by buyer:", error);
  }
};

export const updateConnectionStatus = async (
  connectionId: number,
  status: string
) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=updateConnectionStatus`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ connectionId, status }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating connection status:", error);
  }
};

export const getConnectionsByHome = async (homeId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=getConnectionsByHome&homeId=${homeId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching connections by home:", error);
  }
};
