// src/components/filterBar/FilterBar.tsx

import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
  FormControl,
  Popover,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import { FilterList, ArrowDropDown, Clear } from "@mui/icons-material";
import styled from "styled-components";
import { useSearch } from "../../context/SearchContext";

const FilterBarContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 10px 0;
  padding: 10px;
`;

const FilterButton = styled(Button)`
  text-transform: none;
`;

const PopoverContent = styled(Box)`
  padding: 16px;
  max-width: 300px;
`;

const initialFilters = {
  minPrice: "",
  maxPrice: "",
  beds: "",
  baths: "",
  square_footage: "",
  home_type: "",
};

const initialModalFilters = {
  lot_size: "",
  garage_spots: "",
  basement: null as boolean | null,
  waterfront: null as boolean | null,
  hoa: null as boolean | null,
  pictures_available: null as boolean | null,
  year_built: "",
  floors: "",
  pool: null as boolean | null,
  high_ceilings: null as boolean | null,
};

const FilterBar = () => {
  const { searchParams, setSearchParams } = useSearch();
  const [filters, setFilters] = useState(initialFilters);
  const [modalFilters, setModalFilters] = useState(initialModalFilters);

  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: HTMLElement | null;
  }>({
    price: null,
    beds: null,
    baths: null,
    square_footage: null,
    home_type: null,
    additional: null,
  });

  const handleReset = () => {
    setFilters(initialFilters);
    setModalFilters(initialModalFilters);
    setSearchParams({ city: "", zipCode: "", address: "" });
  };

  const handleFilterButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    filterName: string
  ) => {
    setAnchorEl({
      ...anchorEl,
      [filterName]: event.currentTarget,
    });
  };

  const handlePopoverClose = (filterName: string) => {
    setAnchorEl({
      ...anchorEl,
      [filterName]: null,
    });
  };

  const handleFilterChange = (filterName: string, value: any) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
  };

  const handleModalFilterChange = (filterName: string, value: any) => {
    setModalFilters({
      ...modalFilters,
      [filterName]: value,
    });
  };

  const handleApply = (filterName: string) => {
    // @ts-expect-error
    const updatedSearchParams: { [key: string]: string | boolean } = {
      ...searchParams,
    };

    // Include main filters
    (Object.keys(filters) as (keyof typeof filters)[]).forEach((key) => {
      if (filters[key] !== "" && filters[key] !== null) {
        updatedSearchParams[key] = filters[key];
      } else {
        delete updatedSearchParams[key];
      }
    });

    // Include modal filters
    (Object.keys(modalFilters) as (keyof typeof modalFilters)[]).forEach(
      (key) => {
        if (modalFilters[key] !== "" && modalFilters[key] !== null) {
          updatedSearchParams[key] = modalFilters[key] as string | boolean;
        } else {
          delete updatedSearchParams[key];
        }
      }
    );

    setSearchParams(updatedSearchParams);
    handlePopoverClose(filterName);
  };

  // Generate price options
  const priceOptions = [];
  priceOptions.push({ label: "No Min", value: "" });
  for (let i = 100000; i <= 1000000; i += 100000) {
    priceOptions.push({ label: `$${i.toLocaleString()}`, value: i.toString() });
  }
  for (let i = 1500000; i <= 4000000; i += 500000) {
    priceOptions.push({ label: `$${i.toLocaleString()}`, value: i.toString() });
  }
  priceOptions.push({ label: "No Max", value: "" });

  // Function to handle tri-state checkbox change
  type ModalFilterKeys = keyof typeof modalFilters;

  const handleTriStateCheckboxChange = (filterName: ModalFilterKeys) => {
    const currentValue = modalFilters[filterName];
    let newValue: boolean | null;
    if (currentValue === null) {
      newValue = true;
    } else if (currentValue === true) {
      newValue = false;
    } else {
      newValue = null;
    }
    handleModalFilterChange(filterName, newValue);
  };

  return (
    <FilterBarContainer>
      {/* Price Button */}
      <FilterButton
        variant="outlined"
        onClick={(e) => handleFilterButtonClick(e, "price")}
        endIcon={<ArrowDropDown />}
      >
        Price
      </FilterButton>
      {/* Price Popover */}
      <Popover
        open={Boolean(anchorEl.price)}
        anchorEl={anchorEl.price}
        onClose={() => handlePopoverClose("price")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverContent>
          <Typography variant="h6">Price Range</Typography>
          <FormControl fullWidth margin="normal">
            <Typography variant="caption">Min Price</Typography>
            <Select
              value={filters.minPrice}
              onChange={(e) =>
                handleFilterChange("minPrice", e.target.value as string)
              }
              displayEmpty
            >
              {priceOptions.map((option, index) => (
                <MenuItem key={`min-${index}`} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Typography variant="caption">Max Price</Typography>
            <Select
              value={filters.maxPrice}
              onChange={(e) =>
                handleFilterChange("maxPrice", e.target.value as string)
              }
              displayEmpty
            >
              {priceOptions.map((option, index) => (
                <MenuItem key={`max-${index}`} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleApply("price")}
            fullWidth
          >
            Apply
          </Button>
        </PopoverContent>
      </Popover>

      {/* Beds Button */}
      <FilterButton
        variant="outlined"
        onClick={(e) => handleFilterButtonClick(e, "beds")}
        endIcon={<ArrowDropDown />}
      >
        Beds
      </FilterButton>
      {/* Beds Popover */}
      <Popover
        open={Boolean(anchorEl.beds)}
        anchorEl={anchorEl.beds}
        onClose={() => handlePopoverClose("beds")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverContent>
          <Typography variant="h6">Beds</Typography>
          <FormControl fullWidth margin="normal">
            <Select
              value={filters.beds}
              onChange={(e) =>
                handleFilterChange("beds", e.target.value as string)
              }
              displayEmpty
            >
              <MenuItem value="">Any Beds</MenuItem>
              <MenuItem value="1">1+ Beds</MenuItem>
              <MenuItem value="2">2+ Beds</MenuItem>
              <MenuItem value="3">3+ Beds</MenuItem>
              <MenuItem value="4">4+ Beds</MenuItem>
              <MenuItem value="5">5+ Beds</MenuItem>
              <MenuItem value="6">6+ Beds</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleApply("beds")}
            fullWidth
          >
            Apply
          </Button>
        </PopoverContent>
      </Popover>

      {/* Baths Button */}
      <FilterButton
        variant="outlined"
        onClick={(e) => handleFilterButtonClick(e, "baths")}
        endIcon={<ArrowDropDown />}
      >
        Baths
      </FilterButton>
      {/* Baths Popover */}
      <Popover
        open={Boolean(anchorEl.baths)}
        anchorEl={anchorEl.baths}
        onClose={() => handlePopoverClose("baths")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverContent>
          <Typography variant="h6">Baths</Typography>
          <FormControl fullWidth margin="normal">
            <Select
              value={filters.baths}
              onChange={(e) =>
                handleFilterChange("baths", e.target.value as string)
              }
              displayEmpty
            >
              <MenuItem value="">Any Baths</MenuItem>
              <MenuItem value="1">1+ Baths</MenuItem>
              <MenuItem value="2">2+ Baths</MenuItem>
              <MenuItem value="3">3+ Baths</MenuItem>
              <MenuItem value="4">4+ Baths</MenuItem>
              <MenuItem value="5">5+ Baths</MenuItem>
              <MenuItem value="6">6+ Baths</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleApply("baths")}
            fullWidth
          >
            Apply
          </Button>
        </PopoverContent>
      </Popover>

      {/* Square Footage Button */}
      <FilterButton
        variant="outlined"
        onClick={(e) => handleFilterButtonClick(e, "square_footage")}
        endIcon={<ArrowDropDown />}
      >
        Sq Ft
      </FilterButton>
      {/* Square Footage Popover */}
      <Popover
        open={Boolean(anchorEl.square_footage)}
        anchorEl={anchorEl.square_footage}
        onClose={() => handlePopoverClose("square_footage")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverContent>
          <Typography variant="h6">Square Footage</Typography>
          <FormControl fullWidth margin="normal">
            <Select
              value={filters.square_footage}
              onChange={(e) =>
                handleFilterChange("square_footage", e.target.value as string)
              }
              displayEmpty
            >
              <MenuItem value="">Any Size</MenuItem>
              <MenuItem value="1000">1,000+ sq ft</MenuItem>
              <MenuItem value="2000">2,000+ sq ft</MenuItem>
              <MenuItem value="3000">3,000+ sq ft</MenuItem>
              <MenuItem value="4000">4,000+ sq ft</MenuItem>
              <MenuItem value="5000">5,000+ sq ft</MenuItem>
              <MenuItem value="10000">10,000+ sq ft</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleApply("square_footage")}
            fullWidth
          >
            Apply
          </Button>
        </PopoverContent>
      </Popover>

      {/* Home Type Button */}
      <FilterButton
        variant="outlined"
        onClick={(e) => handleFilterButtonClick(e, "home_type")}
        endIcon={<ArrowDropDown />}
      >
        Home Type
      </FilterButton>
      {/* Home Type Popover */}
      <Popover
        open={Boolean(anchorEl.home_type)}
        anchorEl={anchorEl.home_type}
        onClose={() => handlePopoverClose("home_type")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverContent>
          <Typography variant="h6">Home Type</Typography>
          <FormControl fullWidth margin="normal">
            <Select
              value={filters.home_type}
              onChange={(e) =>
                handleFilterChange("home_type", e.target.value as string)
              }
              displayEmpty
            >
              <MenuItem value="">All Home Types</MenuItem>
              <MenuItem value="house">House</MenuItem>
              <MenuItem value="apartment">Apartment</MenuItem>
              <MenuItem value="condo">Condo</MenuItem>
              <MenuItem value="townhouse">Townhouse</MenuItem>
              <MenuItem value="land">Land</MenuItem>
              <MenuItem value="multi-family">Multi-Family</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleApply("home_type")}
            fullWidth
          >
            Apply
          </Button>
        </PopoverContent>
      </Popover>

      {/* Additional Filters Button */}
      <IconButton onClick={(e) => handleFilterButtonClick(e, "additional")}>
        <FilterList />
      </IconButton>
      {/* <Tooltip title="Reset Filters">
        <IconButton onClick={handleReset}>
          <Clear />
        </IconButton>
      </Tooltip> */}
      {/* Additional Filters Popover */}
      <Popover
        open={Boolean(anchorEl.additional)}
        anchorEl={anchorEl.additional}
        onClose={() => handlePopoverClose("additional")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <PopoverContent>
          <Typography variant="h6">Additional Filters</Typography>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Lot Size (sq ft)"
              value={modalFilters.lot_size}
              onChange={(e) =>
                handleModalFilterChange("lot_size", e.target.value)
              }
              type="number"
              variant="outlined"
              size="small"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Garage Spots"
              value={modalFilters.garage_spots}
              onChange={(e) =>
                handleModalFilterChange("garage_spots", e.target.value)
              }
              type="number"
              variant="outlined"
              size="small"
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={modalFilters.basement === null}
                checked={modalFilters.basement === true}
                onChange={() => handleTriStateCheckboxChange("basement")}
              />
            }
            label="Basement"
          />
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={modalFilters.waterfront === null}
                checked={modalFilters.waterfront === true}
                onChange={() => handleTriStateCheckboxChange("waterfront")}
              />
            }
            label="Waterfront"
          />
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={modalFilters.hoa === null}
                checked={modalFilters.hoa === true}
                onChange={() => handleTriStateCheckboxChange("hoa")}
              />
            }
            label="HOA"
          />
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={modalFilters.pictures_available === null}
                checked={modalFilters.pictures_available === true}
                onChange={() =>
                  handleTriStateCheckboxChange("pictures_available")
                }
              />
            }
            label="Pictures Available"
          />
          <FormControl fullWidth margin="normal">
            <TextField
              label="Year Built (After)"
              value={modalFilters.year_built}
              onChange={(e) =>
                handleModalFilterChange("year_built", e.target.value)
              }
              type="number"
              variant="outlined"
              size="small"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Floors"
              value={modalFilters.floors}
              onChange={(e) =>
                handleModalFilterChange("floors", e.target.value)
              }
              type="number"
              variant="outlined"
              size="small"
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={modalFilters.pool === null}
                checked={modalFilters.pool === true}
                onChange={() => handleTriStateCheckboxChange("pool")}
              />
            }
            label="Pool"
          />
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={modalFilters.high_ceilings === null}
                checked={modalFilters.high_ceilings === true}
                onChange={() => handleTriStateCheckboxChange("high_ceilings")}
              />
            }
            label="High Ceilings"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleApply("additional")}
            fullWidth
          >
            Apply
          </Button>
        </PopoverContent>
      </Popover>
    </FilterBarContainer>
  );
};

export default FilterBar;
