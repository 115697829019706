import React from "react";
import { StepIconProps } from "@mui/material/StepIcon";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"; // For the default step icon
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // For the completed step icon

const CustomStepIcon: React.FC<StepIconProps> = ({ active, completed }) => {
  if (completed) {
    return <CheckCircleIcon style={{ color: "white" }} />;
  }
  if (active) {
    return <CheckCircleIcon style={{ color: "white" }} />;
  }
  return <CircleOutlinedIcon style={{ color: "white" }} />;
};

export default CustomStepIcon;
