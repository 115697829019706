// src/components/mapContainer/MapContainer.tsx
// AIzaSyCBxFttxvInvMZVnsmWhJCJQ2Y911zN8io
import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useHover } from "../../pages/buy/HoverContext";

type Home = {
  latitude: number | string;
  longitude: number | string;
  address: string;
  default_image: string;
  price: string;
  home_id: string;
};

type MapContainerProps = {
  center?: {
    lat: number;
    lng: number;
  };
  zoom?: number;
  homes?: Home[];
};

type MarkerProps = {
  lat: number;
  lng: number;
  home: Home;
  isHovered: boolean;
};

const Marker: React.FC<MarkerProps> = ({ home, isHovered }) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const { setHoveredHomeId } = useHover();

  const handleClick = () => {
    navigate(`/view-home/${home.home_id}`);
  };

  const handleMouseEnter = () => {
    setHover(true);
    setHoveredHomeId(home.home_id);
  };

  const handleMouseLeave = () => {
    setHover(false);
    setHoveredHomeId(null);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={{
        position: "relative",
        height: "20px",
        width: "20px",
        backgroundColor: isHovered ? "#FF0000" : "#3159FE",
        borderRadius: "50%",
        border: "2px solid #fff",
        cursor: "pointer",
      }}
    >
      {(hover || isHovered) && (
        <InfoCard>
          <img src={home.default_image} alt={home.address} />
          <p>${home.price}</p>
        </InfoCard>
      )}
    </div>
  );
};

const InfoCard = styled.div`
  position: absolute;
  bottom: 30px;
  left: -50px;
  width: 150px;
  padding: 10px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 100;
  img {
    width: 100%;
    height: auto;
  }
  p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #333;
  }
`;

const MapContainer: React.FC<MapContainerProps> = ({
  center = { lat: 39.22, lng: -76.58 },
  zoom = 12,
  homes = [],
}) => {
  const { hoveredHomeId } = useHover(); // Use hover context

  return (
    <MapWrapper>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCBxFttxvInvMZVnsmWhJCJQ2Y911zN8io" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        {homes.map((home, index) => {
          console.log(`Home ${index} before parsing:`, {
            latitude: home.latitude,
            longitude: home.longitude,
          });

          const lat = parseFloat(home.latitude as string);
          const lng = parseFloat(home.longitude as string);

          // Check if lat and lng are valid numbers
          if (!isNaN(lat) && !isNaN(lng)) {
            return (
              <Marker
                key={index}
                lat={lat}
                lng={lng}
                home={home}
                isHovered={home.home_id === hoveredHomeId}
              />
            );
          } else {
            console.error(`Invalid lat/lng for home at ${home.address}`);
            return null;
          }
        })}
      </GoogleMapReact>
    </MapWrapper>
  );
};

const MapWrapper = styled.div`
  height: 100vh;
  width: 100%;
`;

export default MapContainer;
