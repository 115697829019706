// src/pages/sell/Sell.tsx
import React from "react";
import Navbar from "../../components/navigationbar/Navbar";
import styled from "styled-components";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";

interface SellingOptionProps {
  title: string;
  reasons: string[];
  price: string;
  buttonText?: string;
  onButtonClick?: () => void;
}
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const SellPageContainer = styled.div`
  flex: 1; // This will make the container fill the available space
  width: 100%;
  background-color: #f5f3fe;
  display: flex;
  flex-direction: column;
  overflow-y: auto; // Add scrolling inside SellPageContainer if content overflows vertically
`;

const HeroSection = styled.section`
  color: black;

  text-align: center;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap; // Allow items to wrap to the next line
  justify-content: space-around;
  gap: 20px; // Add some space between the cards when they wrap
  margin-top: 20px;
`;

const CardContainer = styled.div<{ $mostPopular?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: 100%;
  max-width: 394px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: ${(props) =>
    props.$mostPopular
      ? "0px 5px 30px -2px #6941C633"
      : "0px 2px 28px 0px #9E77ED33"};
`;
const CardContentContainer = styled.div``;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between; // Add this to align the title and the tag
  width: 100%;
  margin-bottom: 20px; // Adjust the spacing as needed
`;
const CardHeaderTitle = styled.div``;

const CardHeaderTitleText = styled.h5`
  margin: 0;
  color: #9d86f4;
  font-weight: bold;
  font-size: 20px;
`;

const CardServiceTitleText = styled.h5`
  margin: 0;
  color: #484848;
  font-size: 18px;
  font-weight: 600;
`;

const CardServiceCostText = styled.h5`
  margin: 0;
  color: #000000; // Match the theme color from HeroSection
  font-size: 24px;
  font-weight: bold;
`;

const CardServiceDescriptionText = styled.p`
  font-size: 14px;
  /* Utilize your confidence, knowledge, and our easy-to-use platform to sell your home on your own */

  width: 362px;
  height: 50px;

  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  color: #605f5f;
`;

const CardServiceContainer = styled.div`
  margin-top: 20px;
`;

const CardServiceButton = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 20px;
  text-transform: none;
  margin-top: 20px;
  border-style: solid; // You need to define the border style
  border-width: 3px;
  border-radius: 12px;
  border-color: #9d86f4; // Ensure this color is visible against the button background
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 20px;
  cursor: pointer; // To indicate the button is clickable
  outline: none; // Remove the outline on focus for aesthetics (optional)
  transition: background-color 0.3s, color 0.3s; // Smooth transition for hover effects (optional)
  font-size: 20px;
  font-weight: bold;
  color: #9d86f4;
  &:hover {
    background-color: #9d86f4; // Change the color on hover (optional)
    color: white; // Text color on hover (optional)
  }
`;

const CardBenefitsContainer = styled.div``;

const CardBenefitsLine = styled.div``;

const CardBenefitsList = styled.div``;

const CardListItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const CardListItemLeft = styled.div`
  width: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
`;

const CardListItemLeftIcon = styled(CheckCircleOutlineIcon)`
  color: #007882; // Match the theme color from HeroSection
  margin-right: 10px; // Space between icon and text
`;

const CardListItemRight = styled.div`
  gap: 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

const CardListItemTitle = styled.h5`
  margin: 0;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
`;
const CardListItemDescription = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0; // Remove default margin
  padding: 0; // Remove default padding
`;

const MostPopularTag = styled.div`
  background-color: #9d86f4; // The color of the tag from the image
  color: white;
  font-size: 14px;
  padding: 4px 8px; // Adjust the padding as needed
  border-radius: 4px; // Adjust the border-radius as needed
  font-weight: bold;
  text-transform: uppercase;
`;

const CardComponent = ({
  title,
  serviceTitle,
  serviceCost,
  serviceDescription,
  buttonText,
  benefitTitles,
  benefitDescriptions,
  mostPopular,
  onButtonClick,
}: {
  title: string;
  serviceTitle: string;
  serviceCost: string;
  serviceDescription: string;
  buttonText: string;
  benefitTitles: string[];
  benefitDescriptions: string[];
  mostPopular?: boolean;
  onButtonClick: () => void;
}) => {
  return (
    <CardContainer $mostPopular={mostPopular}>
      <CardContentContainer>
        <CardHeader>
          <CardHeaderTitle>
            <CardHeaderTitleText>{title}</CardHeaderTitleText>
          </CardHeaderTitle>
          {mostPopular && <MostPopularTag>Most Popular</MostPopularTag>}
        </CardHeader>
        <CardServiceContainer>
          <CardServiceTitleText>{serviceTitle}</CardServiceTitleText>
          <CardServiceCostText>{serviceCost}</CardServiceCostText>
          <CardServiceDescriptionText>
            {serviceDescription}
          </CardServiceDescriptionText>
          <CardServiceButton onClick={onButtonClick}>
            {buttonText}
          </CardServiceButton>
        </CardServiceContainer>
        <CardBenefitsContainer>
          <CardBenefitsLine />
          <CardBenefitsList>
            {benefitTitles.map((title, index) => (
              <div
                style={{ display: "flex", flexDirection: "row" }}
                key={index}
              >
                <CardListItem>
                  <CardListItemLeft>
                    <CardListItemLeftIcon />
                  </CardListItemLeft>
                  <CardListItemRight>
                    <CardListItemTitle>{title}</CardListItemTitle>
                    <CardListItemDescription>
                      {benefitDescriptions[index]}
                    </CardListItemDescription>
                  </CardListItemRight>
                </CardListItem>
              </div>
            ))}
          </CardBenefitsList>
        </CardBenefitsContainer>
      </CardContentContainer>
    </CardContainer>
  );
};

const SellPage = () => {
  const navigate = useNavigate();

  const navigateToCreateListing = () => {
    navigate("/create-listing"); // Update the path as needed
  };
  return (
    <AppContainer>
      <Navbar />
      <SellPageContainer>
        <HeroSection>
          <h1 style={{ fontSize: 32, fontWeight: 500 }}>
            Maximize Profits, Minimize Stress
          </h1>
          <p style={{ fontSize: 28 }}>
            OffMkt is where private deals find their perfect match​
          </p>
        </HeroSection>
        <CardsContainer>
          <CardComponent
            title="OFFMKT"
            serviceTitle="Do it yourself"
            serviceCost="Free"
            serviceDescription="Utilize your confidence, knowledge, and our easy-to-use platform to sell your home on your own"
            buttonText="Get Started"
            benefitTitles={[
              "Customizable Listing on OffMktConnect.com",
              "All documents needed to sell with confidence",
              "Personal Selling Dashboard & Inbox",
              "Ultra-Concentrated in Maryland, Direct Connections Fast!",
            ]}
            benefitDescriptions={[
              "Create your digital listing and get it buyer ready.",
              "Use our templates without needing to search endlessly for compliant documentation",
              "Your personalized dashboard to keep track of your listing, make edits, receive notifications & communicate directly with your interested buyers.",
              "We are the only platform that is ultra-concentrated in Maryland, so you can connect with buyers fast!",
            ]}
            onButtonClick={navigateToCreateListing}
          />

          <CardComponent
            title="OFFMKT"
            serviceTitle="White Glove Service"
            serviceCost="0.5% of home sale price"
            serviceDescription="Boost your confidence and minimize your level of effort (while still saving a ton of $$$) with the expert guidance of our licensed realtors."
            buttonText="Get Started"
            benefitTitles={[
              "Customizable Listing on OffMktConnect.com",
              "Licensed Agent at an industry-leading price",
              "All documents needed to sell with confidence",
              "Personal Selling Dashboard & Inbox",
              "Ultra-Concentrated in Maryland, Direct Connections Fast!",
              "Marketing Package, Inspection Scheduling, and Settlement Preparation",
              "Comprehensive Competitive Market Analysis (CMA)",
              "Maximize your Earnings!",
            ]}
            benefitDescriptions={[
              "Create your digital listing and get it buyer ready.",
              "Our Licensed Professionals guide you confidently through each step of your unique selling journey at a price-point that stands alone in the market",
              "Use Maryland REALTORS® approved documents without needing to search endlessly for compliant documentation",
              "Your personalized dashboard to keep track of your listing, make edits, receive notifications & communicate directly with your interested buyers.",
              "List your home on OffMktConnect.com to reach only serious, pre-qualified buyers searching specifically in Maryland.",
              "From pricing your property and professional photos, to navigating the way you like, to navigating your offers and sealing the deal, we're with you the entire way",
              "Our CMA goes beyond just numbers. We dive deep into market trends, comparables, and unique property features to ensure your home is priced not just to sell, but to profit.",
              "Our groundbreaking 0.5% commission rate ensures you keep more of your money where it belongs—in your pocket. Simply put, we prioritize your profit, not our payout.",
            ]}
            onButtonClick={navigateToCreateListing}
            mostPopular
          />
          <CardComponent
            title="OFFMKT"
            serviceTitle="Closing Support"
            serviceCost="$499 one time payment"
            serviceDescription="Boost your confidence and minimize your level of effort (while still saving a ton of $$$) with the expert guidance of our licensed realtors."
            buttonText="Get Started"
            benefitTitles={[
              "Licensed Real Estate Advisor",
              "Get A Step-By-Step Closing Blueprint",
              "Legal Documents Provided",
              "Title, Inspections And Appraisals",
              "Closing Preparations",
            ]}
            benefitDescriptions={[
              "Close with confidence when working with a licensed Real Estate Advisor from our elite partner, [Real Estate LLC]",
              "Work with our team to help make the closing process easy and stress-free from start to finish.",
              "Our licensed advisor from [Real Estate LLC] will assist with legal documents specific to your closing.",
              "We'll coordinate your inspection, title, appraisal and closing so you don’t have to.",
              "End-to-end, we're with you the entire way",
            ]}
            onButtonClick={() => {}}
          />
        </CardsContainer>
      </SellPageContainer>
    </AppContainer>
  );
};

export default SellPage;
