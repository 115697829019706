// src/taskSystem/tasks/buyerCounterTask.tsx

import { Subtask, Task, UserRoleRequirements, FormField, Modal } from "./tasks";

export const buyerCounterTask: Subtask[] = [
  {
    taskId: null,
    offerId: null,
    dealId: null,
    taskTitle: "Buyer Submit Offer",
    label: "Buyer Submit Offer",
    date: null,
    status: "pending",
    state: "MD",
    modalId: 4,
    taskType: "counter",
    description: "Seller Submit Offer",
    seller: {
      required: false,
      optional: false,
      inputType: "modal",
      documentId: null,
      modalId: null,
      completed: true,
    },
    buyer: {
      required: true,
      optional: false,
      inputType: "modal",
      documentId: null,
      modalId: 1,
      completed: true,
    },
    agent: {
      required: false,
      optional: true,
      inputType: "modal",
      documentId: null,
      modalId: null,
      completed: true,
    },
  },
];
