const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const createDeal = async (
  homeId: number,
  buyerId: number,
  sellerId: number,
  connectionId: number
) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=createDeal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        homeId,
        buyerId,
        sellerId,
        connectionId,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating deal:", error);
    throw error;
  }
};

export const getDealByConnectionId = async (connectionId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=getDealByConnectionId`, {
      method: "POST", // Change to POST since we're sending data in the body
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ connectionId }), // Send connectionId as an object
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching deal by connection ID:", error);
    throw error;
  }
};

export const getDealById = async (dealId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=getDealById`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dealId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching deal by ID:", error);
    throw error;
  }
};

export const acceptOfferAndUpdateDeal = async (
  dealId: number,
  offerId: number
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=acceptOfferAndUpdateDeal`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dealId,
          offerId,
        }),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Failed to accept the offer.");
    }

    return data;
  } catch (error) {
    console.error("Error accepting offer and updating deal:", error);
    throw error;
  }
};

export const updateDeal = async (dealId: number, updatedData: any) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=updateDeal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dealId, ...updatedData }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating deal:", error);
    throw error;
  }
};
