import React, { useState, useEffect } from "react";
import {
  CardContent,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Modal,
  Box,
  Button,
} from "@mui/material";
import {
  FormData,
  categories,
  initialFormData,
} from "./utilities/formDataTypes";
import { StyledCard, CategoryList, ButtonContainer } from "./styles";
import FormStepContent from "./components/FormStepContent";
import StepButtons from "./components/StepButtons";
import CustomStepIcon from "./components/CustomStepIcon";
import { saveHomeInfo } from "../../api"; // Adjust the path as necessary
import { useUser } from "../../context/UserContext";
import styled from "styled-components";

const FormFields = styled.div`
  flex: 2;
  padding: 20px;
  max-height: 100%; /* Ensures it takes up full height of the parent */
  overflow-y: auto; /* Enables vertical scrolling */
`;

const SuccessModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 24px;
  padding: 20px;
  text-align: center;
`;

const SellersForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [open, setOpen] = useState(false);
  const { user } = useUser();

  // Update formData when the user changes
  useEffect(() => {
    if (user) {
      setFormData((prev) => ({ ...prev, user_id: user.user_id }));
    }
  }, [user]);

  const handleSubmit = async () => {
    console.log("Submitting form data:", formData);
    try {
      const response = await saveHomeInfo(formData);
      if (response && response.message === "Home created successfully") {
        console.log("Form submitted successfully");
        setOpen(true); // Open the modal on successful submission
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    // Optionally reset form or navigate away
  };

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  return (
    <>
      <StyledCard>
        <CategoryList>
          <Stepper activeStep={activeStep} orientation="vertical">
            {categories.map((label, index) => (
              <Step key={label} onClick={() => handleStepClick(index)}>
                <StepLabel
                  sx={{
                    color: "white",
                    ".MuiStepLabel-label": { color: "white" },
                    cursor: "pointer", // Adds a pointer cursor to indicate the step is clickable
                  }}
                  StepIconComponent={CustomStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </CategoryList>
        <FormFields>
          <div
            style={{
              height: 5,
              width: 100,
              backgroundColor: "#9d86f4",
              marginTop: 20,
              marginLeft: 15,
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}
          ></div>
          <CardContent>
            <Typography variant="h6">{categories[activeStep]}</Typography>
            <FormStepContent
              step={activeStep}
              formData={formData}
              setFormData={setFormData}
            />
          </CardContent>
          <ButtonContainer>
            <StepButtons
              step={activeStep}
              setStep={setActiveStep}
              numSteps={categories.length}
              handleSubmit={handleSubmit}
            />
          </ButtonContainer>
        </FormFields>
      </StyledCard>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SuccessModalBox>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Home Uploaded Successfully!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your home has been successfully uploaded and is now live on the
            platform.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{ mt: 3 }}
          >
            Close
          </Button>
        </SuccessModalBox>
      </Modal>
    </>
  );
};

export default SellersForm;
