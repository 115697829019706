// src/taskSystem/tasks/offerTasks.tsx

import { Subtask, Task, UserRoleRequirements, FormField, Modal } from "./tasks";

export const offerTask: Task = {
  taskId: 1,
  offerId: 1,
  dealId: 1,
  taskType: "offer",
  taskTitle: "Offer",
  label: "Submit Offer",
  date: null,
  status: "pending",
  state: "MD",
  modalId: null,
  description: "Offer",
  seller: {
    required: true,
    optional: false,
    inputType: "header",
    documentId: null,
    modalId: null,
    completed: true,
  },
  buyer: {
    required: true,
    optional: false,
    inputType: "header",
    documentId: null,
    modalId: null,
    completed: true,
  },
  agent: {
    required: true,
    optional: false,
    inputType: "header",
    documentId: null,
    modalId: null,
    completed: true,
  },
  subtasks: [
    {
      taskId: 2,
      offerId: 1,
      dealId: 1,
      taskTitle: "Submit Offer",
      taskType: "offer",
      label: "Please Submit Offer",
      date: null,
      status: "pending",
      state: "MD",
      modalId: 1,
      description: "Submit Offer",
      seller: {
        required: false,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: null,
        completed: true,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: 1,
        completed: true,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: null,
        completed: true,
      },
    },
  ],
};
