import React, { useState, useEffect } from "react";
import Navbar from "../../components/navigationbar/Navbar";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MapContainer from "../../components/mapContainer/MapContainer";
import FilterBar from "../../components/filterBar/filterBar";
import { useSearch } from "../../context/SearchContext";
import { useUser } from "../../context/UserContext";
import { searchHomes } from "../../api"; // Adjust the path as necessary
import HomeListContainer from "../../components/homeListContainer/HomeListContainer";
import { HoverProvider } from "./HoverContext";

const navBarHeight = 64; // Actual height of the Navbar in pixels
const filterBarHeight = 40; // Set the actual height of your FilterBar

const MainSection = styled.div`
  display: flex;
  height: calc(100vh - ${navBarHeight}px);
`;

const MapSection = styled.div<{ isFullWidth: boolean }>`
  flex-grow: ${({ isFullWidth }) => (isFullWidth ? 3 : 1)};
  transition: flex-grow 0.3s ease;
  overflow: hidden;
  width: ${({ isFullWidth }) =>
    isFullWidth ? "75%" : "50%"}; // Adjust width based on isFullWidth
  height: 100%;
`;

const HomeListSection = styled.div<{ isFullWidth: boolean }>`
  flex-grow: 0;
  overflow-y: auto;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ isFullWidth }) =>
    isFullWidth ? "25%" : "50%"}; // Adjust width based on isFullWidth
  height: 100%;
`;

const ToggleButton = styled(IconButton)`
  position: absolute;
  top: ${navBarHeight + 10}px; // Adjust top offset
  left: 10px;
  z-index: 10;
  background-color: white !important;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #f0f0f0;
  }
  color: inherit;
  opacity: 1;
`;

const Buy = () => {
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [homes, setHomes] = useState([]); // State to store fetched homes
  const { searchParams, setSearchParams } = useSearch(); // Assuming setSearchParams is available for updating search parameters
  const { user } = useUser();
  const userId = user?.user_id;

  const toggleMapSize = () => setIsFullWidth(!isFullWidth);

  useEffect(() => {
    const fetchHomes = async () => {
      try {
        // Include the userId in the search parameters if it exists
        const paramsWithUserId = { ...searchParams, userId };
        const response = await searchHomes(paramsWithUserId);
        setHomes(response.data); // Update state with fetched homes
        console.log(response.data); // Temporary log to see the fetched data
      } catch (error) {
        console.error("Error fetching homes:", error);
      }
    };

    fetchHomes();
  }, [searchParams, userId]); // Include userId as a dependency to refetch homes if the userId changes

  return (
    <>
      <Navbar />
      <MainSection>
        {/* @ts-expect-error */}

        <HoverProvider>
          <MapSection isFullWidth={isFullWidth}>
            <ToggleButton onClick={toggleMapSize} color="primary">
              {isFullWidth ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </ToggleButton>
            <MapContainer homes={homes} />{" "}
            {/* Pass homes data to MapContainer */}
          </MapSection>
          <HomeListSection isFullWidth={isFullWidth}>
            <FilterBar />
            <HomeListContainer homes={homes} />
          </HomeListSection>
        </HoverProvider>
      </MainSection>
    </>
  );
};

export default Buy;
