// DashboardContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import {
  getConnectionsBySeller,
  getConnectionsByBuyer,
} from "../api/connectionApi";
import { getDealByConnectionId } from "../api/dealApi";
import { getOffersByConnectionId } from "../api/offerApi";
import { getNotificationsByUser } from "../api/notificationsApi";
import { useUser } from "./UserContext";
import { deepEqual } from "../utils/deepEqual";

export interface Notification {
  notification_id: number;
  user_id: number;
  deal_id?: number | null;
  connection_id?: number | null;
  notification_type: string;
  text: string;
  time_sent: string;
  read_status: boolean;
  archive_status: boolean;
}
export interface Connection {
  connection_id: number;
  buyer_id: number;
  seller_id: number;
  home_id: number;
  connection_status: string;
  connection_date: string;
  username: string;
  role?: string;
  connectionUserId: number;
  default_image?: string;
  address?: string;
  price?: string;
  image_src?: string;
  beds?: number;
  baths?: number;
  square_footage?: number;
  home_label?: string;
}

export interface Deal {
  deal_id: number;
  offer_id: number;
  connection_id: number;
  home_id: number;
  seller_id: number;
  buyer_id: number;
  // Add other fields that are relevant to the deal
}

export interface Offer {
  offer_id: number;
  home_id: number;
  buyer_id: number;
  seller_id: number;
  connection_id: number;
  offer_status: "pending" | "accepted" | "rejected" | "counter" | "withdrawn";
  previous_offer: number | null;
  current_offer: number;
  counter_offer_requested: boolean;
  deal_id: number | null;
  earnest_money_deposit: number;
  financing_contingency: boolean;
  appraisal_contingency: boolean;
  inspection_contingency: boolean;
  radon_inspection: boolean;
  structural_mechanical_inspection: boolean;
  mold_inspection: boolean;
  environmental_inspection: boolean;
  chimney_inspection: boolean;
  lead_based_paint_disclosure: boolean;
  wood_destroying_insect_inspection: boolean;
  due_diligence_amount?: number | null;
  additional_offer_conditions: string | null;
  settlement_date: string;
  created_at: string;
  updated_at: string;
  is_current_offer: boolean;
  is_initial_offer: boolean;
}

// Define the context's type
interface DashboardContextType {
  propertySelected: number | null;
  setPropertySelected: (property: number | null) => void;
  unreadMessageCount: number;
  setUnreadMessageCount: (count: number) => void;
  connectionId: number | null;
  setConnectionId: (id: number | null) => void;
  allConnections: Connection[];
  setAllConnections: React.Dispatch<React.SetStateAction<Connection[]>>; // Added setter
  viewMode: string;
  setViewMode: (mode: string) => void;
  recipientId: number | null;
  setRecipientId: (id: number | null) => void;
  currentConnection: Connection | null | undefined;
  setCurrentConnection: (connection: Connection | null | undefined) => void;
  currentDeal: Deal | null;
  currentOffer: Offer | null;
  setCurrentOffer: (offer: Offer | null) => void;
  currentOffers: Offer[] | null; // Add currentOffers to the context
  setCurrentOffers: (offers: Offer[] | null) => void; // Add setter for currentOffers
  loadingTasks: boolean;
  setLoadingTasks: (loading: boolean) => void;
  tasksReloadTrigger: number;
  triggerTasksReload: () => void;
  notifications: Notification[];
  unreadNotificationCount: number;
}

// Create the context with a default value
const DashboardContext = createContext<DashboardContextType | undefined>(
  undefined
);

// Create a custom hook to use the dashboard context
export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error("useDashboard must be used within a DashboardProvider");
  }
  return context;
};

// Define the provider component's props type
interface DashboardProviderProps {
  children: ReactNode;
}

// Create the provider component
export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children,
}) => {
  const [propertySelected, setPropertySelected] = useState<number | null>(null);
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);
  const [connectionId, setConnectionId] = useState<number | null>(null);
  const [allConnections, setAllConnections] = useState<Connection[]>([]);
  const [recipientId, setRecipientId] = useState<number | null>(null);
  const [viewMode, setViewMode] = useState<string>("buyer");
  const [currentConnection, setCurrentConnection] = useState<
    Connection | null | undefined
  >(null);
  const [currentDeal, setCurrentDeal] = useState<Deal | null>(null);
  const [currentOffer, setCurrentOffer] = useState<Offer | null>(null);
  const [currentOffers, setCurrentOffers] = useState<Offer[] | null>(null); // State to hold all offers for the current connection
  const [loadingTasks, setLoadingTasks] = useState<boolean>(false);
  const [tasksReloadTrigger, setTasksReloadTrigger] = useState<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadNotificationCount, setUnreadNotificationCount] =
    useState<number>(0);
  const { user } = useUser();

  const triggerTasksReload = () => {
    console.log("Reloading tasks...");
    setTasksReloadTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    console.log("Property selected:", propertySelected);
  }, [propertySelected]);

  useEffect(() => {
    console.log("Connection ID:", connectionId);
  }, [connectionId]);

  useEffect(() => {
    let isMounted = true;

    const fetchNotifications = async () => {
      if (!user) return;
      try {
        const fetchedNotifications = await getNotificationsByUser(user.user_id);

        if (isMounted && fetchedNotifications) {
          // Check if notifications have changed using deepEqual
          if (!deepEqual(fetchedNotifications, notifications)) {
            setNotifications(fetchedNotifications);

            // Update unread notification count
            const unreadCount = fetchedNotifications.filter(
              (notif: Notification) =>
                !notif.read_status && !notif.archive_status
            ).length;
            setUnreadNotificationCount(unreadCount);
          }
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    // Initial fetch
    fetchNotifications();

    // Set up interval to fetch every 10 seconds
    const intervalId = setInterval(fetchNotifications, 10000);

    // Cleanup function
    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [user, notifications]);

  // Update useEffect hooks to depend on tasksReloadTrigger instead of loadingTasks
  useEffect(() => {
    let isMounted = true; // Flag to prevent state updates on unmounted component

    const fetchCurrentOffers = async () => {
      if (currentConnection) {
        try {
          const offers = await getOffersByConnectionId(
            currentConnection.connection_id
          );
          console.log("Fetched offers:", offers);

          if (isMounted && offers) {
            // Compare fetched offers with currentOffers
            if (!deepEqual(offers, currentOffers)) {
              setCurrentOffers(offers);
              console.log("Current offers updated.");

              if (offers && offers.length > 0) {
                const latestOffer = offers.reduce((max: Offer, offer: Offer) =>
                  max.offer_id > offer.offer_id ? max : offer
                );

                // Compare latestOffer with currentOffer
                if (!deepEqual(latestOffer, currentOffer)) {
                  setCurrentOffer(latestOffer);
                  console.log("Current offer updated to latest offer.");
                  triggerTasksReload(); // Trigger tasks reload if needed
                }
              }
            } else {
              console.log("No changes in current offers.");
            }
          }
        } catch (error) {
          console.error("Error fetching current offers:", error);
          if (isMounted) {
            setCurrentOffers(null);
            setCurrentOffer(null);
          }
        }
      }
    };

    // Initial fetch
    fetchCurrentOffers();

    // Set up interval to fetch every 10 seconds
    const intervalId = setInterval(fetchCurrentOffers, 10000); // 10000 ms = 10 seconds

    // Cleanup function
    return () => {
      isMounted = false; // Prevent state updates
      clearInterval(intervalId); // Clear the interval
    };
  }, [currentConnection, currentOffers, currentOffer, triggerTasksReload]);

  useEffect(() => {
    const fetchDeal = async () => {
      console.log("Fetching deal...");
      if (currentConnection) {
        try {
          const dealResponse = await getDealByConnectionId(
            currentConnection.connection_id
          );
          if (dealResponse.deal) {
            setCurrentDeal(dealResponse.deal); // Adjust to extract the nested deal object
          } else {
            setCurrentDeal(dealResponse); // In case it's returned directly
          }
          console.log("Current deal:", dealResponse);
        } catch (error) {
          console.log("Error fetching deal:", error);
          console.error("Error fetching deal:", error);
          setCurrentDeal(null);
        }
      }
    };

    fetchDeal();
  }, [currentConnection, tasksReloadTrigger]); // Changed dependency

  useEffect(() => {
    console.log("current connection updated");
  }, [currentConnection]);

  useEffect(() => {
    const fetchConnections = async () => {
      if (!user) return;
      try {
        const fetchSellerConnections = await getConnectionsBySeller(
          user.user_id
        );
        const fetchBuyerConnections = await getConnectionsByBuyer(user.user_id);

        const sellerConnections = fetchSellerConnections?.connections || [];
        const buyerConnections = fetchBuyerConnections?.connections || [];

        const fetchedAllConnections: Connection[] = [
          ...sellerConnections.map((conn: Connection) => ({
            ...conn,
            role: "Seller",
            connectionUserId: conn.buyer_id,
          })),
          ...buyerConnections.map((conn: Connection) => ({
            ...conn,
            role: "Buyer",
            connectionUserId: conn.seller_id,
          })),
        ];

        // Use deepEqual to check if fetched connections are different
        if (!deepEqual(fetchedAllConnections, allConnections)) {
          setAllConnections(fetchedAllConnections);
        }
      } catch (error) {
        console.error("Error fetching connections:", error);
      }
    };

    // Initial fetch and periodic updates
    fetchConnections(); // Initial fetch
    const intervalId = setInterval(fetchConnections, 10000); // Re-fetch every 10 seconds

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [user, allConnections]); // Add allConnections as a dependency

  // The value that will be given to the context's provider
  const value = {
    propertySelected,
    setPropertySelected,
    unreadMessageCount,
    setUnreadMessageCount,
    connectionId,
    setConnectionId,
    allConnections,
    setAllConnections, // Exposed setter
    viewMode,
    setViewMode,
    recipientId,
    setRecipientId,
    currentConnection,
    setCurrentConnection,
    currentDeal,
    currentOffer,
    setCurrentOffer,
    currentOffers, // Provide currentOffers to the context
    setCurrentOffers, // Provide setCurrentOffers to the context
    loadingTasks,
    setLoadingTasks,
    tasksReloadTrigger,
    triggerTasksReload,
    notifications,
    unreadNotificationCount,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
