// notificationsApi.ts
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const createNotification = async (notificationData: {
  userId: number;
  dealId?: number;
  connectionId?: number;
  notificationType: string;
  text: string;
}) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=createNotification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(notificationData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating notification:", error);
    throw error;
  }
};

export const getNotificationsByUser = async (userId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=getNotificationsByUser&userId=${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data.notifications;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};

export const markNotificationAsRead = async (notificationId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=markNotificationAsRead`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ notificationId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error marking notification as read:", error);
    throw error;
  }
};

export const archiveNotification = async (notificationId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=archiveNotification`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ notificationId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error archiving notification:", error);
    throw error;
  }
};

export const deleteNotification = async (notificationId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=deleteNotification`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ notificationId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error deleting notification:", error);
    throw error;
  }
};
