export enum FieldID {
  // Existing fields
  buyer_first_name = 1,
  buyer_last_name = 2,
  buyer_middle_name = 3,
  buyer_email = 4,
  buyer_phone = 5,
  buyer_two_first_name = 6,
  buyer_two_last_name = 7,
  buyer_two_middle_name = 8,
  buyer_two_email = 9,
  buyer_two_phone = 10,
  buyer_mailing_address = 11,
  seller_first_name = 12,
  seller_last_name = 13,
  seller_middle_name = 14,
  seller_email = 15,
  seller_phone = 16,
  seller_two_first_name = 17,
  seller_two_last_name = 18,
  seller_two_middle_name = 19,
  seller_two_email = 20,
  seller_two_phone = 21,
  seller_mailing_address = 22,
  property_address_line_1 = 23,
  property_address_line_2 = 24,
  property_city = 25,
  property_state = 26,
  property_zip = 27,
  property_type = 28,
  property_price = 29,
  property_ground_rent = 30,
  property_inclusions = 31,
  property_exclusions = 32,
  purchase_price = 33,
  earnest_money_deposit = 34,
  earnest_money_held_by = 35,
  financing_contingency = 36,
  appraisal_contingency = 37,
  settlement_date = 38,
  home_inspection_contingency = 39,
  inspection_due_date = 40,
  contingencies = 41,
  escrow_company = 42,
  escrow_company_contact = 43,
  escrow_company_phone = 44,
  deposit_in_escrow = 45,
  payment_method = 46,
  disclosures = 47,
  general_contract_provisions = 48,
  buyer_pre_approval_documentation = 49,
  property_disclosures = 50,
  hoa_disclosure = 51,
  lead_based_paint_disclosure = 52,
  flood_zone_disclosure = 53,
  environmental_disclosure = 54,
  conservation_easement_disclosure = 55,
  foreclosure_protection_notice = 56,
  electronic_execution = 57,
  property_condition_as_is = 58,
  assignment_allowed = 59,
  mediation_agreement = 60,
  contract_summary = 61,
  // Newly added fields based on offers/deals table
  radon_inspection = 62,
  structural_mechanical_inspection = 63,
  mold_inspection = 64,
  environmental_inspection = 65,
  chimney_inspection = 66,
  wood_destroying_insect_inspection = 67,
  due_diligence_amount = 68,
  additional_offer_conditions = 69,
  task_type = 70,
  // New fields
  loan_amount = 71,
  term_of_note = 72,
  interest_rate = 73,
  loan_program = 74,
  loan_origination_fee = 75,
  loan_discount_fee = 76,
  base_loan_amount = 77,
  initial_interest_rate = 78,
  total_loan_amount = 79,
  mortgage_insurance_premium_amount = 80,
  lender_required_repair_amount = 81,
  buyer_appraisal_contingency_signature = 82,
  buyer_appraisal_contingency_signature_date = 83,
  buyer_two_appraisal_contingency_signature = 84,
  buyer_two_appraisal_contingency_signature_date = 85,
  buyer_fha_financing_signature = 86,
  buyer_fha_financing_signature_date = 87,
  buyer_two_fha_financing_signature = 88,
  buyer_two_fha_financing_signature_date = 89,
  buyer_va_financing_signature = 90,
  buyer_va_financing_signature_date = 91,
  buyer_two_va_financing_signature = 92,
  buyer_two_va_financing_signature_date = 93,
  inspection_days = 94,
  buyer_structural_mechanical_initial = 95,
  buyer_mold_initial = 96,
  buyer_environmental_initial = 97,
  buyer_radon_initial = 98,
  buyer_chimney_initial = 99,
  buyer_lead_based_paint_initial = 100,
  buyer_wood_destroying_insect_initial = 101,
  buyer_additional_inspections_initial = 102,
  buyer_two_structural_mechanical_initial = 103,
  buyer_two_mold_initial = 104,
  buyer_two_environmental_initial = 105,
  buyer_two_radon_initial = 106,
  buyer_two_chimney_initial = 107,
  buyer_two_lead_based_paint_initial = 108,
  buyer_two_wood_destroying_insect_initial = 109,
  buyer_two_additional_inspections_initial = 110,
  buyer_property_inspection_signature = 111,
  buyer_property_inspection_signature_date = 112,
  buyer_two_property_inspection_signature = 113,
  buyer_two_property_inspection_signature_date = 114,
  buyer_property_disclosure_signature = 115,
  buyer_property_disclosure_signature_date = 116,
  buyer_two_property_disclosure_signature = 117,
  buyer_two_property_disclosure_signature_date = 118,
  buyer_md_property_disclosure_signature = 119,
  buyer_md_property_disclosure_signature_date = 120,
  buyer_two_md_property_disclosure_signature = 121,
  buyer_two_md_property_disclosure_signature_date = 122,
  buyer_inclusions_exclusions_signature = 123,
  buyer_inclusions_exclusions_signature_date = 124,
  buyer_two_inclusions_exclusions_signature = 125,
  buyer_two_inclusions_exclusions_signature_date = 126,
  buyer_lead_notice_initial = 127,
  buyer_two_lead_notice_initial = 128,
  buyer_lead_waiver_initial = 129,
  buyer_two_lead_waiver_initial = 130,
  buyer_lead_pamphlet_initial = 131,
  buyer_two_lead_pamphlet_initial = 132,
  buyer_lead_information_initial = 133,
  buyer_two_lead_information_initial = 134,
  buyer_lead_disclosure_signature = 135,
  buyer_lead_disclosure_signature_date = 136,
  buyer_two_lead_disclosure_signature = 137,
  buyer_two_lead_disclosure_signature_date = 138,
  buyer_maryland_consumers_notice_signature = 139,
  buyer_maryland_consumers_notice_signature_date = 140,
  buyer_two_maryland_consumers_notice_signature = 141,
  buyer_two_maryland_consumers_notice_signature_date = 142,
  buyer_contribution_signature = 143,
  buyer_contribution_signature_date = 144,
  buyer_two_contribution_signature = 145,
  buyer_two_contribution_signature_date = 146,
  buyer_property_address = 147,
  buyer_property_contract_date = 148,
  buyer_property_financing_date = 149,
  buyer_property_settlement_date = 150,
  buyer_property_lease_date = 151,
  buyer_days_to_list_property = 152,
  seller_days_to_null_and_void = 153,
  buyer_hoa_acknowledgement_signature = 154,
  buyer_hoa_acknowledgement_signature_date = 155,
  end_of_occupancy_date = 156,
  buyer_comp_nominal_consideration_initial = 157,
  buyer_two_comp_nominal_consideration_initial = 158,
  buyer_occupancy_comp_carry_cost_initial = 159,
  buyer_two_occupancy_comp_carry_cost_initial = 160,
  buyer_occupancy_comp_flat_rate_initial = 161,
  buyer_two_occupancy_comp_flat_rate_initial = 162,
  occupancy_comp_day_rate = 163,
  occupancy_compensation_total = 164,
  post_occupancy_deposit = 165,
  post_occupancy_escrow_agent = 166,
  contract_of_sale_date = 167,
  final_inspection_date = 168,
  buyer_post_occupancy_signature = 169,
  buyer_post_occupancy_signature_date = 170,
  buyer_two_post_occupancy_signature = 171,
  buyer_two_post_occupancy_signature_date = 172,
  buyer_anne_arundel_signature = 173,
  buyer_anne_arundel_signature_date = 174,
  buyer_two_anne_arundel_signature = 175,
  buyer_two_anne_arundel_signature_date = 176,
  buyer_baltimore_signature = 177,
  buyer_baltimore_signature_date = 178,
  buyer_two_baltimore_signature = 179,
  buyer_two_baltimore_signature_date = 180,
  baltimore_tax_seller_responsibility = 181,
  baltimore_tax_buyer_responsibility = 182,
  baltimore_tax_split = 183,
  buyer_baltimore_tax_signature = 184,
  buyer_baltimore_tax_signature_date = 185,
  buyer_two_baltimore_tax_signature = 186,
  buyer_two_baltimore_tax_signature_date = 187,
  buyer_baltimore_acknowledge_signature = 188,
  buyer_baltimore_acknowledge_signature_date = 189,
  buyer_two_baltimore_acknowledge_signature = 190,
  buyer_two_baltimore_acknowledge_signature_date = 191,
  buyer_baltimore_master_plan_signature = 192,
  buyer_baltimore_master_plan_signature_date = 193,
  buyer_two_baltimore_master_plan_signature = 194,
  buyer_two_baltimore_master_plan_signature_date = 195,
  buyer_radionuclides_initials = 196,
  buyer_two_radionuclides_initials = 197,
  buyer_dewatering_zone_signature = 198,
  buyer_dewatering_zone_signature_date = 199,
  buyer_two_dewatering_zone_signature = 200,
  buyer_two_dewatering_zone_signature_date = 201,
  buyer_howard_county_signature = 202,
  buyer_howard_county_signature_date = 203,
  buyer_two_howard_county_signature = 204,
  buyer_two_howard_county_signature_date = 205,

  // Seller Fields
  seller_appraisal_contingency_signature = 206,
  seller_appraisal_contingency_signature_date = 207,
  seller_two_appraisal_contingency_signature = 208,
  seller_two_appraisal_contingency_signature_date = 209,
  seller_fha_financing_signature = 210,
  seller_fha_financing_signature_date = 211,
  seller_two_fha_financing_signature = 212,
  seller_two_fha_financing_signature_date = 213,
  seller_va_financing_signature = 214,
  seller_va_financing_signature_date = 215,
  seller_two_va_financing_signature = 216,
  seller_two_va_financing_signature_date = 217,
  seller_structural_mechanical_initial = 218,
  seller_mold_initial = 219,
  seller_environmental_initial = 220,
  seller_radon_initial = 221,
  seller_chimney_initial = 222,
  seller_lead_based_paint_inspection_initial = 223,
  seller_wood_destroying_insect_initial = 224,
  seller_additional_inspections_initial = 225,
  seller_two_structural_mechanical_initial = 226,
  seller_two_mold_initial = 227,
  seller_two_environmental_initial = 228,
  seller_two_radon_initial = 229,
  seller_two_chimney_initial = 230,
  seller_two_lead_based_paint_inspection_initial = 231,
  seller_two_wood_destroying_insect_initial = 232,
  seller_two_additional_inspections_initial = 233,
  seller_property_inspection_signature = 234,
  seller_property_inspection_signature_date = 235,
  seller_two_property_inspection_signature = 236,
  seller_two_property_inspection_signature_date = 237,
  water_supply = 238,
  sewage_disposal = 239,
  septic_system_number_of_bedrooms = 240,
  garbage_disposal = 241,
  dishwasher = 242,
  heating = 243,
  heating_other = 244,
  heat_pump_age = 245,
  air_conditioning = 246,
  air_conditioning_other = 247,
  hot_water = 248,
  hot_water_other = 249,
  foundation_issues = 250,
  basement_leaks_or_moisture = 251,
  roof_leaks_or_moisture = 252,
  type_of_roof = 253,
  roof_age = 254,
  fire_retardant_plywood = 255,
  other_structural_systems = 256,
  any_defects = 257,
  any_defects_comments = 258,
  plumbing_system_working = 259,
  heat_supply_to_all_rooms = 260,
  heat_system_working = 261,
  heat_system_comments = 262,
  ac_supply_to_all_rooms = 263,
  ac_system_working = 264,
  ac_system_comments = 265,
  electrical_system_issues = 266,
  electrical_system_comments = 267,
  smoke_alarms = 268,
  smoke_alarms_older_than_10_years = 269,
  smoke_alarms_md_compliant = 270,
  septic_system_working = 271,
  septic_system_pump_date = 272,
  septic_system_pump_date_unknown = 273,
  septic_system_comments = 274,
  water_supply_issues = 275,
  home_water_treatment_system = 276,
  home_water_treatment_system_comments = 277,
  fire_sprinkler_system = 278,
  fire_sprinkler_system_comments = 279,
  are_systems_operating = 280,
  exterior_walls_insulated = 281,
  ceiling_and_attic_insulated = 282,
  insulation_comments = 283,
  exterior_drainage_standing_water = 284,
  exterior_drainage_issues = 285,
  gutter_working = 286,
  gutter_comments = 287,
  wood_insect_damage = 288,
  wood_insect_comments = 289,
  wood_damage_repair = 290,
  wood_insect_warranty = 291,
  hazardous_materials = 292,
  hazardous_materials_comments = 293,
  monoxide_alarm = 294,
  monoxide_alarm_comments = 295,
  zoning_violations = 296,
  zoning_comments = 297,
  home_improvements = 298,
  property_in_flood_zone = 299,
  flood_zone_comments = 300,
  hoa_restrictions = 301,
  hoa_restriction_comments = 302,
  any_other_defects = 303,
  other_defect_comments = 304,
  seller_property_disclosure_signature = 305,
  seller_property_disclosure_signature_date = 306,
  seller_two_property_disclosure_signature = 307,
  seller_two_property_disclosure_signature_date = 308,
  seller_md_property_disclosure_signature = 309,
  seller_md_property_disclosure_signature_date = 310,
  seller_two_md_property_disclosure_signature = 311,
  seller_two_md_property_disclosure_signature_date = 312,
  seller_disclosure_of_inclusions_exclusions_signature = 313,
  seller_disclosure_of_inclusions_exclusions_signature_date = 314,
  seller_two_disclosure_of_inclusions_exclusions_signature = 315,
  seller_two_disclosure_of_inclusions_exclusions_signature_date = 316,
  seller_pre_1978_initial = 317,
  seller_two_pre_1978_initial = 318,
  seller_home_date_unknown_initial = 319,
  seller_two_home_date_unknown_initial = 320,
  seller_presence_of_lead_known = 321,
  seller_two_presence_of_lead_known = 322,
  seller_presence_of_lead_unknown = 323,
  seller_two_presence_of_lead_unknown = 324,
  seller_lead_records_provided = 325,
  seller_two_lead_records_provided = 326,
  seller_no_lead_records = 327,
  seller_two_no_lead_records = 328,
  seller_lead_disclosure_signature = 329,
  seller_lead_disclosure_signature_date = 330,
  seller_two_lead_disclosure_signature = 331,
  seller_two_lead_disclosure_signature_date = 332,
  seller_maryland_consumers_notice_signature = 333,
  seller_maryland_consumers_notice_signature_date = 334,
  seller_two_maryland_consumers_notice_signature = 335,
  seller_two_maryland_consumers_notice_signature_date = 336,
  seller_contribution_signature = 337,
  seller_contribution_signature_date = 338,
  seller_two_contribution_signature = 339,
  seller_two_contribution_signature_date = 340,
  property_neighborhood_name = 341,
  monthly_hoa_fees = 342,
  hoa_payment_basis = 343,
  total_yearly_hoa_fees = 344,
  other_hoa_fees = 345,
  hoa_fee_comments = 346,
  hoa_name = 347,
  hoa_address = 348,
  hoa_telephone = 349,
  seller_hoa_judgement_initials = 350,
  seller_two_hoa_judgement_initials = 351,
  seller_hoa_pending_claim_initials = 352,
  seller_two_hoa_pending_claim_initials = 353,
  seller_hoa_no_knowledge_initials = 354,
  seller_two_hoa_no_knowledge_initials = 355,
  hoa_documents = 356,
  seller_hoa_acknowledgement_signature = 357,
  seller_hoa_acknowledgement_signature_date = 358,
  seller_two_hoa_acknowledgement_signature = 359,
  seller_two_hoa_acknowledgement_signature_date = 360,
  seller_comp_nominal_consideration_initial = 361,
  seller_two_comp_nominal_consideration_initial = 362,
  seller_occupancy_comp_carry_cost_initial = 363,
  seller_two_occupancy_comp_carry_cost_initial = 364,
  seller_occupancy_comp_flat_rate_initial = 365,
  seller_two_occupancy_comp_flat_rate_initial = 366,
  seller_post_occupancy_signature = 367,
  seller_post_occupancy_signature_date = 368,
  seller_two_post_occupancy_signature = 369,
  seller_two_post_occupancy_signature_date = 370,
  seller_anne_arundel_signature = 371,
  seller_anne_arundel_signature_date = 372,
  seller_two_anne_arundel_signature = 373,
  seller_two_anne_arundel_signature_date = 374,
  seller_baltimore_city_signature = 375,
  seller_baltimore_city_signature_date = 376,
  seller_two_baltimore_city_signature = 377,
  seller_two_baltimore_city_signature_date = 378,
  seller_baltimore_tax_signature_date = 379,
  seller_two_baltimore_tax_signature = 380,
  seller_two_baltimore_tax_signature_date = 381,
  seller_baltimore_acknowledge_signature = 382,
  seller_baltimore_acknowledge_signature_date = 383,
  seller_two_baltimore_acknowledge_signature = 384,
  seller_two_baltimore_acknowledge_signature_date = 385,
  public_water_supply = 386,
  public_sewer_system = 387,
  removed_from_flood_plan = 388,
  within_historic_district = 389,
  on_landmark_list = 390,
  seller_dewatering_zone_signature = 391,
  seller_dewatering_zone_signature_date = 392,
  seller_two_dewatering_zone_signature = 393,
  seller_two_dewatering_zone_signature_date = 394,
  seller_howard_county_signature = 395,
  seller_howard_county_signature_date = 396,
  seller_two_howard_county_signature = 397,
  seller_two_howard_county_signature_date = 398,

  // new fields
  pre_approval_date = 399,
  min_down_payment = 400,
  loan_rate_type = 401,
  loan_term = 402,
  loan_type = 403,
  lender_name = 404,
  lender_point_of_contact = 405,
  nmls_number = 406,
  lender_phone = 407,
  lender_email = 408,
  lender_mailing_address = 409,
  seller_baltimore_tax_signature = 410,
  evidence_notice_days = 411,
  buyer_settlement_signature = 412,
  buyer_settlement_signature_date = 413,
  buyer_two_settlement_signature = 414,
  buyer_two_settlement_signature_date = 415,
  seller_settlement_signature = 416,
  seller_settlement_signature_date = 417,
  seller_two_settlement_signature = 418,
  seller_two_settlement_signature_date = 419,
  buyer_conventional_financing_signature = 420,
  buyer_conventional_financing_signature_date = 421,
  buyer_two_conventional_financing_signature = 422,
  buyer_two_conventional_financing_signature_date = 423,
  seller_conventional_financing_signature = 424,
  seller_conventional_financing_signature_date = 425,
  seller_two_conventional_financing_signature = 426,
  seller_two_conventional_financing_signature_date = 427,
  // generated fields
  alarm_system = 428,
  existing_carpet = 429,
  ceiling_fans = 430,
  ceiling_fans_number = 431,
  fireplace_screens = 432,
  central_vacuum = 433,
  fireplace_equipment = 434,
  clothes_dryer = 435,
  freezer = 436,
  clothes_washer = 437,
  furnace_humidifier = 438,
  cooktop = 439,
  garage_openers = 440,
  garage_openers_number = 441,
  garage_remotes = 442,
  garage_remotes_number = 443,
  drapery_rods = 444,
  draperies = 445,
  hot_tub = 446,
  electronic_air_filter = 447,
  intercom = 448,
  exhaust_fans = 449,
  exhaust_fans_number = 450,
  microwave = 451,
  playground_equipment = 452,
  tv_antenna = 453,
  pool_equipment = 454,
  trash_compactor = 455,
  refrigerator = 456,
  refrigerator_number = 457,
  ice_maker = 458,
  ice_maker_number = 459,
  satellite_dish = 460,
  screens = 461,
  water_filter = 462,
  shades_blinds = 463,
  water_softener = 464,
  storage_sheds = 465,
  storage_sheds_number = 466,
  window_ac_units = 467,
  window_ac_units_number = 468,
  window_fans = 469,
  window_fans_number = 470,
  storm_doors = 471,
  wood_stove = 472,
  storm_windows = 473,
  stove_range = 474,
  additional_inclusions = 475,
  additional_exclusions = 476,
  leased_fuel_tanks = 477,
  leased_solar_panels = 478,
  leased_alarm_system = 479,
  leased_water_treatment = 480,
  leased_other = 481,
  leased_other_specify = 482,
  additional_terms_leased_items = 483,
  utility_service_providers = 484,

  // new fields
  agent_informed_initials = 485,
  buyer_assessment_waived_initials = 486,
  seller_landlord_lead_initials = 487,
  seller_landlord_lead_uncertain_initials = 488,
  initial_deposit_days = 489,
  intial_deposit_days = 490,
  additional_deposit = 491,
  additional_deposit_date = 492,
  financing_application_days = 493,
  financing_commitment_days = 494,
  initial_deposit = 495,
}
