// src/taskModals/modals/DisclosureOfInclusionsExclusionsModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const DisclosureOfInclusionsExclusionsModal: Modal = {
  modalId: ModalID.DisclosureOfInclusionsExclusions,
  title: "Disclosure of Inclusions/Exclusions",
  formFields: [
    // Title
    {
      content: `<h1>DISCLOSURE OF INCLUSIONS/EXCLUSIONS</h1>`,
    },
    // Section 1: INCLUDED/EXCLUDED ITEMS
    {
      content: `<h2>1. INCLUDED/EXCLUDED ITEMS</h2>`,
    },
    {
      content: `
        <p>
          The purchase price includes all permanently attached fixtures, including
          all smoke detectors (and carbon monoxide detectors, if applicable).
          Certain existing items, which may be considered personal property but are
          either installed or stored on the property, are included if the
          corresponding box is checked below.
        </p>
      `,
    },
    {
      content: `<h3>Included Items:</h3>`,
    },
    // Included Items
    {
      fieldId: FieldID.alarm_system,
    },
    {
      fieldId: FieldID.existing_carpet,
    },
    {
      fieldId: FieldID.ceiling_fans,
    },
    {
      fieldId: FieldID.ceiling_fans_number,
    },
    {
      fieldId: FieldID.fireplace_screens,
    },
    {
      fieldId: FieldID.central_vacuum,
    },
    {
      fieldId: FieldID.fireplace_equipment,
    },
    {
      fieldId: FieldID.clothes_dryer,
    },
    {
      fieldId: FieldID.freezer,
    },
    {
      fieldId: FieldID.clothes_washer,
    },
    {
      fieldId: FieldID.furnace_humidifier,
    },
    {
      fieldId: FieldID.cooktop,
    },
    {
      fieldId: FieldID.garage_openers,
    },
    {
      fieldId: FieldID.garage_openers_number,
    },
    {
      fieldId: FieldID.garage_remotes,
    },
    {
      fieldId: FieldID.drapery_rods,
    },
    {
      fieldId: FieldID.garbage_disposal,
    },
    {
      fieldId: FieldID.draperies,
    },
    {
      fieldId: FieldID.hot_tub,
    },
    {
      fieldId: FieldID.electronic_air_filter,
    },
    {
      fieldId: FieldID.intercom,
    },
    {
      fieldId: FieldID.exhaust_fans,
    },
    {
      fieldId: FieldID.microwave,
    },
    {
      fieldId: FieldID.playground_equipment,
    },
    {
      fieldId: FieldID.tv_antenna,
    },
    {
      fieldId: FieldID.pool_equipment,
    },
    {
      fieldId: FieldID.trash_compactor,
    },
    {
      fieldId: FieldID.refrigerator,
    },
    {
      fieldId: FieldID.ice_maker,
    },
    {
      fieldId: FieldID.satellite_dish,
    },
    {
      fieldId: FieldID.screens,
    },
    {
      fieldId: FieldID.water_filter,
    },
    {
      fieldId: FieldID.shades_blinds,
    },
    {
      fieldId: FieldID.water_softener,
    },
    {
      fieldId: FieldID.storage_sheds,
    },
    {
      fieldId: FieldID.window_ac_units,
    },
    {
      fieldId: FieldID.window_fans,
    },
    {
      fieldId: FieldID.storm_doors,
    },
    {
      fieldId: FieldID.wood_stove,
    },
    {
      fieldId: FieldID.storm_windows,
    },
    {
      fieldId: FieldID.stove_range,
    },
    // Additional Inclusions
    {
      fieldId: FieldID.additional_inclusions,
    },
    // Additional Exclusions
    {
      fieldId: FieldID.additional_exclusions,
    },
    // Section 2: LEASED ITEMS INCLUDED
    {
      content: `<h2>2. LEASED ITEMS INCLUDED</h2>`,
    },
    {
      fieldId: FieldID.leased_fuel_tanks,
    },
    {
      fieldId: FieldID.leased_solar_panels,
    },
    {
      fieldId: FieldID.leased_alarm_system,
    },
    {
      fieldId: FieldID.leased_water_treatment,
    },
    {
      fieldId: FieldID.leased_other,
    },
    {
      fieldId: FieldID.leased_other_specify,
    },
    // Additional Terms/Information Regarding Leased Items
    {
      fieldId: FieldID.additional_terms_leased_items,
    },
    // Section 3: UTILITIES: WATER, SEWAGE, HEATING, AND AIR CONDITIONING
    {
      content: `<h2>3. UTILITIES: WATER, SEWAGE, HEATING, AND AIR CONDITIONING</h2>`,
    },
    {
      fieldId: FieldID.water_supply,
    },
    {
      fieldId: FieldID.sewage_disposal,
    },
    {
      fieldId: FieldID.heating,
    },
    {
      fieldId: FieldID.hot_water,
    },
    {
      fieldId: FieldID.air_conditioning,
    },
    {
      fieldId: FieldID.utility_service_providers,
    },
    // Final Paragraph
    {
      content: `
        <p>
          All other terms and conditions of the Contract of Sale remain unchanged
          and in full effect.
        </p>
      `,
    },
    // Buyer Signatures
    {
      content: `<div style="display: flex; justify-content: space-between">`,
    },
    {
      content: `<div>`,
    },
    {
      fieldId: FieldID.buyer_inclusions_exclusions_signature,
      options: { buyerOnly: true },
    },
    {
      content: `<p>_______________________________________</p>`,
    },
    {
      content: `<p>Buyer Signature</p>`,
    },
    {
      fieldId: FieldID.buyer_inclusions_exclusions_signature_date,
      options: { buyerOnly: true },
    },
    {
      content: `</div>`,
    },
    {
      content: `<div>`,
    },
    {
      fieldId: FieldID.buyer_two_inclusions_exclusions_signature,
      options: { buyerOnly: true },
    },
    {
      content: `<p>_______________________________________</p>`,
    },
    {
      content: `<p>Buyer Signature</p>`,
    },
    {
      fieldId: FieldID.buyer_two_inclusions_exclusions_signature_date,
      options: { buyerOnly: true },
    },
    {
      content: `</div>`,
    },
    {
      content: `</div>`,
    },
    // Line Break
    {
      content: `<br />`,
    },
    // Seller Signatures
    {
      content: `<div style="display: flex; justify-content: space-between">`,
    },
    {
      content: `<div>`,
    },
    {
      fieldId: FieldID.seller_disclosure_of_inclusions_exclusions_signature,
      options: { sellerOnly: true },
    },
    {
      content: `<p>_______________________________________</p>`,
    },
    {
      content: `<p>Seller Signature</p>`,
    },
    {
      fieldId:
        FieldID.seller_disclosure_of_inclusions_exclusions_signature_date,
      options: { sellerOnly: true },
    },
    {
      content: `</div>`,
    },
    {
      content: `<div>`,
    },
    {
      fieldId: FieldID.seller_two_disclosure_of_inclusions_exclusions_signature,
      options: { sellerOnly: true },
    },
    {
      content: `<p>_______________________________________</p>`,
    },
    {
      content: `<p>Seller Signature</p>`,
    },
    {
      fieldId:
        FieldID.seller_two_disclosure_of_inclusions_exclusions_signature_date,
      options: { sellerOnly: true },
    },
    {
      content: `</div>`,
    },
    {
      content: `</div>`,
    },
  ],
};
