import React from "react";
import { Link } from "react-router-dom";

interface NavLinkProps {
  children: React.ReactNode;
  to: string;
}

const NavLink: React.FC<NavLinkProps> = ({ children, to }) => {
  const linkStyle = {
    textDecoration: "none",
    color: "#3b4144",
    fontWeight: "bold",
    fontSize: "15px",
    textTransform: "none" as React.CSSProperties["textTransform"],
    padding: "8px 16px",
    display: "inline-block",
  };

  const hoverStyle = {
    backgroundColor: "#007882",
    color: "#fff",
  };

  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Link
      to={to}
      style={{
        ...linkStyle,
        ...(isHovered ? hoverStyle : {}),
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
    </Link>
  );
};

export default NavLink;
