import React from "react";
import { FormData } from "../utilities/formDataTypes";

// Import each field group component
import HomeLocationFields from "./FieldGroups/HomeLocationFields";
import ContactFields from "./FieldGroups/ContactFields";
import DetailsFields from "./FieldGroups/DetailsFields";
import LayoutFields from "./FieldGroups/LayoutFields";
import FeaturesFields from "./FieldGroups/FeaturesFields";
import PhotosFields from "./FieldGroups/PhotosFields";
import VideoFields from "./FieldGroups/VideoFields";
import DescriptionFields from "./FieldGroups/DescriptionFields";
import AskingPriceFields from "./FieldGroups/AskingPriceFields";

interface Props {
  step: number;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const FormStepContent: React.FC<Props> = ({ step, formData, setFormData }) => {
  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <HomeLocationFields formData={formData} setFormData={setFormData} />
        );
      case 1:
        return <ContactFields formData={formData} setFormData={setFormData} />;
      case 2:
        return <DetailsFields formData={formData} setFormData={setFormData} />;
      case 3:
        return <LayoutFields formData={formData} setFormData={setFormData} />;
      case 4:
        return <FeaturesFields formData={formData} setFormData={setFormData} />;
      case 5:
        return <PhotosFields formData={formData} setFormData={setFormData} />;
      case 6:
        return <VideoFields formData={formData} setFormData={setFormData} />;
      case 7:
        return (
          <DescriptionFields formData={formData} setFormData={setFormData} />
        );
      case 8:
        return (
          <AskingPriceFields formData={formData} setFormData={setFormData} />
        );
      default:
        return null;
    }
  };

  return <>{renderStep()}</>;
};

export default FormStepContent;
