// styles.ts
import styled from "styled-components";

export const StyledCard = styled.div`
  display: flex;
  margin-left: 80px;
  margin-right: 80px;
  max-width: 1200px;
  margin-top: 60px;
  max-height: 75vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); // Optional: Adding a subtle shadow for better UI
`;

export const CategoryList = styled.div`
  flex: 1;
  background-color: #9d86f4; // Purple shade, adjust if necessary
  padding: 20px;
  padding-top: 40px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

export const FormFields = styled.div`
  flex: 2;
  padding: 20px;
  position: relative;
  min-height: 400px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;
