// src/pages/createListing/createListing.tsx
import React from "react";
import Navbar from "../../components/navigationbar/Navbar";
import SellersForm from "../../components/sellersForm/SellersForm"; // Import SellersForm
import styled from "styled-components";

const CreateListingPageContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #f5f3fe;
`;

const CreateListingPage = () => {
  return (
    <CreateListingPageContainer>
      <Navbar />
      <SellersForm />
    </CreateListingPageContainer>
  );
};
export default CreateListingPage;
