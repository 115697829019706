import React, { useState } from "react";
import styled from "styled-components";
import { GoogleLogin } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../api/userApi";
import { useUser } from "../../context/UserContext";
import HomeImage from "../../images/signInHome.png";
import ProfileImage from "../../images/signInProfile.png";
import Avatar from "@mui/material/Avatar";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f5f3fe;
  align-items: center;
  justify-content: center;
`;

const SignInBox = styled.div`
  display: flex;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 1200px;
  height: 700px;
`;

const LeftContainer = styled.div`
  flex: 1;
  background-color: #ffffff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const RightContainer = styled.div`
  flex: 1;
  background-color: #e1d9fc;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CenteredContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Logo = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #6b4ef0;
  margin-bottom: 40px;
  position: absolute;
  top: 40px;
  left: 40px;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const Label = styled.label`
  font-size: 14px;
  color: #6b4ef0;
  margin-bottom: 4px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 100%;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #9d86f4;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
  width: 100%;

  &:hover {
    background-color: #5e35b1;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 10px;
`;

const GoogleLoginButton = styled.div`
  margin-bottom: 20px;
`;

const PromoText = styled.h2`
  font-size: 20px;
  color: #333;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 0;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: -50px;
  width: 260px;
  text-align: left;
`;

const CardImage = styled.img`
  width: 100%;
  border-radius: 12px;
  margin-bottom: 10px;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const CardButton = styled.div`
  background-color: #9d86f4;
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
`;

const SmallCard = styled(Card)`
  width: 170px;
  position: relative;
  top: -80px;
  left: 75px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SmallCardAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
`;

const SmallCardText = styled.div`
  font-size: 14px;
  margin-bottom: 5px;

  &:first-child {
    font-weight: bold;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  max-width: 80%;
  text-align: center;
`;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const { login } = useUser();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await loginUser(email, password);
      if (response.message === "Login successful") {
        login(response.user);
        navigate("/");
      } else {
        setErrorMessage(response.message || "Login failed. Please try again.");
        setShowPopup(true);
      }
    } catch (error) {
      console.error("Login error", error);
      setErrorMessage("An error occurred during login. Please try again.");
      setShowPopup(true);
    }
  };

  const handleGoogleSuccess = async (tokenResponse: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}?url=googleSignIn`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: tokenResponse.credential }),
      });
      const data = await response.json();
      if (data && data.user) {
        login(data.user);
        navigate("/");
      } else {
        setErrorMessage("Failed to log in with Google. Please try again.");
        setShowPopup(true);
      }
    } catch (error) {
      console.error("Login with Google error", error);
      setErrorMessage("An error occurred. Please try again.");
      setShowPopup(true);
    }
  };

  const handleGoogleFailure = (error: any) => {
    console.error("Google login failure!", error);
    setErrorMessage("Google login failed. Please try again.");
    setShowPopup(true);
  };

  return (
    <PageContainer>
      <SignInBox>
        <LeftContainer>
          <Logo>OFFMKT</Logo>
          <CenteredContentWrapper>
            <h2>Sign In</h2>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <LoginForm onSubmit={handleSubmit}>
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Label>Password</Label>
              <Input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button type="submit">Login</Button>
            </LoginForm>
            <GoogleLoginButton>
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                // @ts-expect-error possible typing error
                onError={handleGoogleFailure}
              />
            </GoogleLoginButton>
            <p>
              Don't have an account? <Link to="/signup">Sign up</Link>
            </p>
          </CenteredContentWrapper>
        </LeftContainer>

        <RightContainer>
          <PromoText>Where Private Deals Find Their Perfect Match</PromoText>
          <Card>
            <CardImage src={HomeImage} alt="Home" />
            <CardTitle>5822 Ruthlenn St, Los Angeles, CA 90062</CardTitle>
            <CardButton>Request to Connect</CardButton>
          </Card>
          <SmallCard>
            <SmallCardAvatar
              src={ProfileImage}
              alt="User Avatar"
              sx={{ width: 56, height: 56 }}
            />
            <SmallCardText>Build Connection</SmallCardText>
            <SmallCardText>
              Build Connections and Get Suitable Offers
            </SmallCardText>
            <CardButton>Connect</CardButton>
          </SmallCard>
        </RightContainer>
      </SignInBox>
    </PageContainer>
  );
};

export default Login;
