import React from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider, SearchProvider } from "./context";
import GlobalStyles from "./globalStyles";

// Import your pages
import HomePage from "./pages/home/Home";
import BuyPage from "./pages/buy/Buy";
import SellPage from "./pages/sell/Sell";
import CreateListingPage from "./pages/createListing/createListing"; // Import CreateListingPage
import Login from "./pages/login/Login";
import SignUp from "./pages/signup/SignUp";
import ViewHome from "./pages/viewHome/ViewHome";
import PdfAnnotationPage from "./pages/PdfAnnotation/PdfAnnotationPage";
// import PdfUpdatePage from "./pages/PdfAnnotation/PdfUpdatePage";
import Dashboard from "./pages/dashboard/Dashboard";
import { DashboardProvider } from "./context/DashboardContext"; // Import DashboardProvider
import ProfilePage from "./pages/profile/Profile";

// Create a theme instance.
const theme = createTheme({
  // Your theme settings go here
});

function App() {
  return (
    <>
      <GlobalStyles />
      <UserProvider>
        <SearchProvider>
          <DashboardProvider>
            <Router>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/buy" element={<BuyPage />} />
                <Route path="/sell" element={<SellPage />} />
                <Route
                  path="/create-listing"
                  element={<CreateListingPage />}
                />{" "}
                {/* New route for CreateListingPage */}
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/view-home/:homeId" element={<ViewHome />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/pdf" element={<PdfAnnotationPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                {/* Add other routes here */}
              </Routes>
            </Router>
          </DashboardProvider>
        </SearchProvider>
      </UserProvider>
    </>
  );
}

export default App;
