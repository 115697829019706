// src/taskModals/modals/VAFinancingModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const VAFinancingModal: Modal = {
  modalId: ModalID.VAFinancing,
  title: "VA Financing",
  formFields: [
    { fieldId: FieldID.loan_amount },
    { fieldId: FieldID.interest_rate },
    { fieldId: FieldID.term_of_note },
    // Add other VA-specific fields
  ],
};
