import React from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { FormData } from "../../utilities/formDataTypes";

interface Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const FeaturesFields: React.FC<Props> = ({ formData, setFormData }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const sectionData = {
    appliances: [
      { name: "dishWasher", label: "Dish Washer" },
      { name: "garbageDisposal", label: "Garbage Disposal" },
      { name: "refrigerator", label: "Refrigerator" },
      { name: "microwave", label: "Microwave" },
      { name: "dryer", label: "Dryer" },
      { name: "trashCompactor", label: "Trash Compactor" },
      { name: "freezer", label: "Freezer" },
      { name: "rangeOven", label: "Range Oven" },
      { name: "washer", label: "Washer" },
    ],
    floor: [
      { name: "carpet", label: "Carpet" },
      { name: "laminate", label: "Laminate" },
      { name: "softwood", label: "Softwood" },
      { name: "concrete", label: "Concrete" },
      { name: "linoleumVinyl", label: "Linoleum - Vinyl" },
      { name: "tile", label: "Tile" },
      { name: "hardwood", label: "Hardwood" },
      { name: "slate", label: "Slate" },
      { name: "otherFloor", label: "Other" },
    ],
    others: [
      { name: "security_systems", label: "Security Systems" },
      { name: "patio", label: "Patio" },
      { name: " central_heating", label: "Central Heating" },
      { name: " central_ac", label: "Central AC" },
      { name: "furnished", label: "Furnished" },
      { name: "deck", label: "Deck" },
      { name: "jettedBathtub", label: "Jetted Bathtub" },
      { name: "fireplace", label: "Fireplace" },
      { name: "porch", label: "Porch" },
      { name: "spaJacuzzi", label: "Spa/Jacuzzi" },
      { name: "fencedYard", label: "Fenced Yard" },
      { name: "sprinklerSystem", label: "Sprinkler System" },
      { name: "motherInLaw", label: "Mother-In-Law" },
      { name: "pool", label: "Pool" },
    ],
    parking: [
      { name: "carport", label: "Carport" },
      { name: "garageDetached", label: "Garage Detached" },
      { name: "garageAttached", label: "Garage Attached" },
      { name: "onStreet", label: "On Street" },
      { name: "offStreet", label: "Off Street" },
      { name: "noParking", label: "None" },
    ],
    rooms: [
      { name: "breakfastNook", label: "Breakfast Nook" },
      { name: "primaryBath", label: "Primary Bath" },
      { name: "workshop", label: "Workshop" },
      { name: "diningRoom", label: "Dining Room" },
      { name: "mud_room", label: "Mud Room" },
      { name: "solariumAtrium", label: "Solarium-Atrium" },
      { name: "familyRoom", label: "Family Room" },
      { name: "office", label: "Office" },
      { name: "sunRoom", label: "Sun Room" },
      { name: "laundryRoom", label: "Laundry Room" },
      { name: "pantry", label: "Pantry" },
      { name: "walkInCloset", label: "Walk-in-Closet" },
      { name: "library", label: "Library" },
      { name: "recreationRoom", label: "Recreation Room" },
    ],
  };

  return (
    <Grid container spacing={2}>
      {Object.entries(sectionData).map(([section, items]) => (
        <Grid item xs={12} key={section}>
          <h3>{section.charAt(0).toUpperCase() + section.slice(1)}</h3>
          <hr style={{ width: "100%", marginBottom: "10px" }} />
          <Grid container spacing={2}>
            {items.map((feature) => (
              <Grid item xs={4} key={feature.name}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        formData[feature.name as keyof FormData] as boolean
                      } // Assert the key and value types
                      onChange={handleCheckboxChange}
                      name={feature.name}
                    />
                  }
                  label={feature.label}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default FeaturesFields;
