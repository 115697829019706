// ConnectionsColumn.tsx
import React from "react";
import styled from "styled-components";
import { updateConnectionStatus } from "../../api/connectionApi";
import { createDeal } from "../../api/dealApi"; // Import the createDeal API
import { useDashboard } from "../../context/DashboardContext";
import { useUser } from "../../context/UserContext";
import FilterListIcon from "@mui/icons-material/FilterList";

export interface Connection {
  connection_id: number;
  buyer_id: number;
  seller_id: number;
  home_id: number;
  connection_status: string;
  connection_date: string;
  username: string;
  role?: string;
  connectionUserId: number;
}

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: white;
  margin-top: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9d86f4;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 18px;
`;

const CustomTypography = styled.h6`
  font-size: 18px;
  margin: 0;
  color: black;
`;

const CustomButton = styled.button`
  border-radius: 8px;
  padding: 6px 16px;
  text-transform: none;
  box-shadow: none;
  cursor: pointer;
  border: none;
  font-size: 14px;

  &.disconnect {
    background-color: #ffffff;
    color: #9d86f4;
    border: 1px solid #9d86f4;

    &:hover {
      background-color: #f3e5f5;
      border-color: #6a45ae;
    }
  }

  &.accept {
    background-color: #9d86f4;
    color: white;

    &:hover {
      background-color: #5e35b1;
    }
  }

  &.decline {
    background-color: #ebebeb;
    color: black;

    &:hover {
      background-color: #d6d6d6;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const CustomIconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
`;

const MessageItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background-color: ${({ selected }) =>
    selected ? "rgba(157, 134, 244, 0.1)" : "#ffffff"};
  border: ${({ selected }) =>
    selected ? "2px solid #9d86f4" : "2px solid transparent"};
  border-radius: 12px; /* Rounded corners */
  padding: 10px;
  transition: background-color 0.3s ease, border 0.3s ease;

  &:hover {
    background-color: ${({ selected }) =>
      selected ? "rgba(157, 134, 244, 0.15)" : "#f9f9f9"};
  }

  /* Optional: Add focus styles for accessibility */
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(157, 134, 244, 0.3);
  }
`;

const MessageDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const UserName = styled.span`
  font-weight: bold;
`;

const MessageText = styled.span`
  color: grey;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ConnectionsColumn = () => {
  const {
    connectionId,
    setConnectionId,
    allConnections,
    viewMode,
    setRecipientId,
    setCurrentConnection,
    setAllConnections, // Destructure setAllConnections from context
  } = useDashboard();

  const { user } = useUser();

  // console.log("allConnections column:", allConnections);

  const handleConnectionClick = (connectionUserId: number) => {
    console.log("Connection clicked:", connectionUserId);
    setConnectionId(connectionUserId);
    setRecipientId(connectionUserId);
    const connection = allConnections.find(
      (conn) => conn.connectionUserId === connectionUserId
    );
    console.log("Connection selected:", connection);

    setCurrentConnection(connection);
  };

  const handleUpdateStatus = async (connectionId: number, status: string) => {
    try {
      await updateConnectionStatus(connectionId, status);

      // Update the connection status in context
      setAllConnections((prevConnections) =>
        prevConnections.map((conn) =>
          conn.connection_id === connectionId
            ? { ...conn, connection_status: status }
            : conn
        )
      );
    } catch (error) {
      console.error("Error updating connection status:", error);
      alert("Failed to update connection status. Please try again.");
    }
  };

  return (
    <MessageContainer>
      <HeaderContainer>
        <CustomTypography>Connections</CustomTypography>
        <CustomIconButton aria-label="filter">
          <FilterListIcon />
        </CustomIconButton>
      </HeaderContainer>
      <div style={{ width: "95%", backgroundColor: "#e0e0e0", height: 2 }} />
      {allConnections
        .filter((connection) => connection.role === "Seller") // Filter connections here
        .map((connection) => (
          <MessageItem
            key={connection.connection_id} // Use connection_id as key for better performance
            onClick={() => handleConnectionClick(connection.connectionUserId)}
            selected={connection.connectionUserId === connectionId} // Pass 'selected' prop
          >
            <CustomAvatar>
              {connection.username ? connection.username[0] : "?"}
            </CustomAvatar>

            <MessageDetails>
              <UserName>{connection.username || "Unknown"}</UserName>
              <CustomTypography as="p">
                {connection.connection_status === "accepted"
                  ? `Connected: ${new Date(
                      connection.connection_date
                    ).toLocaleDateString()}`
                  : connection.connection_status === "rejected"
                  ? `Disconnected: ${new Date(
                      connection.connection_date
                    ).toLocaleDateString()}`
                  : connection.connection_status === "closed"
                  ? `Closed: ${new Date(
                      connection.connection_date
                    ).toLocaleDateString()}`
                  : "Pending"}
              </CustomTypography>
              {/* The following role check is now redundant due to the filter, but kept for button logic */}
              {connection.role === "Seller" && (
                <ButtonContainer>
                  {connection.connection_status === "accepted" ? (
                    <CustomButton
                      className="disconnect"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering handleConnectionClick
                        handleUpdateStatus(connection.connection_id, "closed");
                      }}
                    >
                      Disconnect
                    </CustomButton>
                  ) : connection.connection_status === "rejected" ||
                    connection.connection_status === "closed" ? (
                    <CustomButton className="disconnect" disabled>
                      {connection.connection_status === "rejected"
                        ? "Rejected"
                        : "Closed"}
                    </CustomButton>
                  ) : (
                    <>
                      <CustomButton
                        className="accept"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering handleConnectionClick
                          handleUpdateStatus(
                            connection.connection_id,
                            "accepted"
                          );
                        }}
                      >
                        Accept
                      </CustomButton>
                      <CustomButton
                        className="decline"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering handleConnectionClick
                          handleUpdateStatus(
                            connection.connection_id,
                            "rejected"
                          );
                        }}
                      >
                        Decline
                      </CustomButton>
                    </>
                  )}
                </ButtonContainer>
              )}
            </MessageDetails>
          </MessageItem>
        ))}
    </MessageContainer>
  );
};

export default ConnectionsColumn;
