import React from "react";
import { TextField, Grid } from "@mui/material";
import { FormData } from "../../utilities/formDataTypes";

interface Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const HomeLocationFields: React.FC<Props> = ({ formData, setFormData }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <TextField
        label="Address"
        name="address"
        value={formData.address}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
        placeholder="Enter your address"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Address 2"
        name="address2"
        value={formData.address2}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
        placeholder="Enter your address line 2"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="State"
        name="state"
        value={formData.state}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
        placeholder="Enter your state"
        InputLabelProps={{ shrink: true }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="City"
            name="city"
            value={formData.city}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            placeholder="Enter your city"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Zip Code"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            placeholder="Enter your zip code"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default HomeLocationFields;
