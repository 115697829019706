// src/api/userApi.ts

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const registerUser = async (email: string, password: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during user registration:", error);
    throw error;
  }
};

export const loginUser = async (email: string, password: string) => {
  const response = await fetch(`${API_BASE_URL}?url=login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  const data = await response.json();
  return data;
};

export const getFavorites = async (userId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=getFavorites`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch favorites");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching favorites:", error);
    throw error;
  }
};

export const testApiConnection = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=test`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during API test:", error);
    throw error;
  }
};
export const getUserSignatureAndInitials = async (userId: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}?url=getSignatureAndInitials`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: userId }),
      }
    );

    // Directly parse the JSON without checking response.ok or data.status
    const data = await response.json();

    // Check if 'data' field exists
    if (data.data) {
      return data.data;
    } else {
      throw new Error(data.message || "No data found");
    }
  } catch (error) {
    console.error("Failed to fetch signature and initials:", error);
    throw error;
  }
};

// Remove checks for response.ok and data.status in other functions as needed

// Save signature as base64
export const saveUserSignature = async (
  userId: number,
  signatureBase64: string
) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=saveSignature`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_id: userId,
        signature_base64: signatureBase64,
      }),
    });

    const data = await response.json();

    if (data.message === "Signature saved successfully") {
      return data;
    } else {
      throw new Error(data.message || "Failed to save signature");
    }
  } catch (error) {
    console.error("Error saving signature:", error);
    throw error;
  }
};

// Save initials as base64
export const saveUserInitials = async (
  userId: number,
  initialsBase64: string
) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=saveInitials`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_id: userId,
        initials_base64: initialsBase64,
      }),
    });

    const data = await response.json();

    if (data.message === "Initials saved successfully") {
      return data;
    } else {
      throw new Error(data.message || "Failed to save initials");
    }
  } catch (error) {
    console.error("Error saving initials:", error);
    throw error;
  }
};

// Remove signature
export const removeUserSignature = async (userId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=removeSignature`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user_id: userId }),
    });

    const data = await response.json();

    if (data.message === "Signature removed successfully.") {
      return data;
    } else {
      throw new Error(data.message || "Failed to remove signature");
    }
  } catch (error) {
    console.error("Error removing signature:", error);
    throw error;
  }
};

// Remove initials
export const removeUserInitials = async (userId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=removeInitials`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user_id: userId }),
    });

    const data = await response.json();

    if (data.message === "Initials removed successfully.") {
      return data;
    } else {
      throw new Error(data.message || "Failed to remove initials");
    }
  } catch (error) {
    console.error("Error removing initials:", error);
    throw error;
  }
};
