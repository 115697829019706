import React, { createContext, useContext, useState } from "react";

type HoverContextType = {
  hoveredHomeId: string | null;
  setHoveredHomeId: (id: string | null) => void;
};

const HoverContext = createContext<HoverContextType | undefined>(undefined);

// @ts-expect-error
export const HoverProvider: React.FC = ({ children }) => {
  const [hoveredHomeId, setHoveredHomeId] = useState<string | null>(null);

  return (
    <HoverContext.Provider value={{ hoveredHomeId, setHoveredHomeId }}>
      {children}
    </HoverContext.Provider>
  );
};

export const useHover = () => {
  const context = useContext(HoverContext);
  if (context === undefined) {
    throw new Error("useHover must be used within a HoverProvider");
  }
  return context;
};
