import React from "react";
import { Typography, TextField } from "@mui/material";
import { FormData } from "../../utilities/formDataTypes";

interface Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const AskingPriceFields: React.FC<Props> = ({ formData, setFormData }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (/^\d*$/.test(event.target.value)) {
      setFormData({ ...formData, price: event.target.value });
    }
  };

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Set Your Asking Price
      </Typography>
      <TextField
        label="Asking Price"
        name="askingPrice"
        variant="outlined"
        value={formData.price}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        helperText="Enter the price in USD. Only numbers are allowed."
      />
    </>
  );
};

export default AskingPriceFields;
