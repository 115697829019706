import { Subtask, Task, UserRoleRequirements, FormField, Modal } from "./tasks";
import { ModalID } from "../taskModals/modalIds";

export const purchaseAgreementTask: Task = {
  taskId: 1, // Assign a unique taskId or null if it will be generated
  offerId: 1, // No specific offer associated
  dealId: 1, // Will be set when generating tasks
  taskType: "standard", // Or "purchase_agreement" if you have that type
  taskTitle: "Complete Purchase Agreement",
  label: "Complete Purchase Agreement",
  date: null,
  status: "pending",
  state: "MD",
  modalId: null, // Parent task doesn't have a modal
  description:
    "Complete the purchase agreement by filling out all required sections.",
  seller: {
    required: true,
    optional: false,
    inputType: "header",
    documentId: null,
    modalId: null,
    completed: false,
  },
  buyer: {
    required: true,
    optional: false,
    inputType: "header",
    documentId: null,
    modalId: null,
    completed: false,
  },
  agent: {
    required: false,
    optional: true,
    inputType: "header",
    documentId: null,
    modalId: null,
    completed: false,
  },
  subtasks: [
    // GENERAL CONTRACT PROVISIONS
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "General Contract Provisions",
      taskType: "standard",
      label: "Complete General Contract Provisions",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.GeneralContractProvisions,
      description: "Fill out the general contract provisions.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.GeneralContractProvisions,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.GeneralContractProvisions,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.GeneralContractProvisions,
        completed: false,
      },
    },
    // BUYER PRE-APPROVAL DOCUMENTATION
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Buyer Pre-Approval Documentation",
      taskType: "standard",
      label: "Submit Buyer Pre-Approval Documentation",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.BuyerPreApprovalDocumentation,
      description: "Submit your pre-approval documentation.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.BuyerPreApprovalDocumentation,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.BuyerPreApprovalDocumentation,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.BuyerPreApprovalDocumentation,
        completed: false,
      },
    },
    // CONVENTIONAL FINANCING
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Conventional Financing",
      taskType: "standard",
      label: "Complete Conventional Financing Section",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.ConventionalFinancing,
      description: "Provide details for conventional financing.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.ConventionalFinancing,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.ConventionalFinancing,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.ConventionalFinancing,
        completed: false,
      },
    },
    // FHA FINANCING
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "FHA Financing",
      taskType: "standard",
      label: "Complete FHA Financing Section",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.FHAFinancing,
      description: "Provide details for FHA financing.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.FHAFinancing,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.FHAFinancing,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.FHAFinancing,
        completed: false,
      },
    },
    // VA FINANCING
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "VA Financing",
      taskType: "standard",
      label: "Complete VA Financing Section",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.VAFinancing,
      description: "Provide details for VA financing.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.VAFinancing,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.VAFinancing,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.VAFinancing,
        completed: false,
      },
    },
    // PROPERTY INSPECTION(S)
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Property Inspections",
      taskType: "standard",
      label: "Schedule and Complete Property Inspections",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.PropertyInspections,
      description: "Arrange for property inspections as required.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.PropertyInspections,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.PropertyInspections,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.PropertyInspections,
        completed: false,
      },
    },
    // MD RESIDENTIAL PROPERTY DISCLOSURES AND DISCLAIMERS
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "MD Residential Property Disclosures and Disclaimers",
      taskType: "standard",
      label: "Complete MD Property Disclosures",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.MDResidentialPropertyDisclosures,
      description: "Complete Maryland property disclosures and disclaimers.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.MDResidentialPropertyDisclosures,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.MDResidentialPropertyDisclosures,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.MDResidentialPropertyDisclosures,
        completed: false,
      },
    },
    // MARYLAND RESIDENTIAL PROPERTY DISCLAIMER STATEMENT
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Maryland Residential Property Disclaimer Statement",
      taskType: "standard",
      label: "Complete MD Property Disclaimer Statement",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.MarylandResidentialPropertyDisclaimer,
      description: "Complete the Maryland property disclaimer statement.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.MarylandResidentialPropertyDisclaimer,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.MarylandResidentialPropertyDisclaimer,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.MarylandResidentialPropertyDisclaimer,
        completed: false,
      },
    },
    // DISCLOSURE OF INCLUSIONS/EXCLUSIONS
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Disclosure of Inclusions/Exclusions",
      taskType: "standard",
      label: "Complete Inclusions/Exclusions Disclosure",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.DisclosureOfInclusionsExclusions,
      description: "Specify items included or excluded from the sale.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.DisclosureOfInclusionsExclusions,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.DisclosureOfInclusionsExclusions,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.DisclosureOfInclusionsExclusions,
        completed: false,
      },
    },
    // BUYER’S SALE, FINANCING, SETTLEMENT OF OTHER REAL ESTATE
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Buyer's Sale, Financing, Settlement of Other Real Estate",
      taskType: "standard",
      label: "Provide Details of Other Real Estate Transactions",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.BuyersSaleFinancingSettlement,
      description:
        "Provide information about any other real estate transactions.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.BuyersSaleFinancingSettlement,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.BuyersSaleFinancingSettlement,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.BuyersSaleFinancingSettlement,
        completed: false,
      },
    },
    // NOTICE TO BUYER OF MARYLAND HOMEOWNERS ASSOCIATION ACT
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Notice to Buyer of Maryland Homeowners Association Act",
      taskType: "standard",
      label: "Acknowledge HOA Act Notice",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.NoticeToBuyerOfHOAAct,
      description: "Acknowledge receipt of the Maryland HOA Act notice.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.NoticeToBuyerOfHOAAct,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.NoticeToBuyerOfHOAAct,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.NoticeToBuyerOfHOAAct,
        completed: false,
      },
    },
    // POST-SETTLEMENT OCCUPANCY AGREEMENT
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Post-Settlement Occupancy Agreement",
      taskType: "standard",
      label: "Complete Post-Settlement Occupancy Agreement",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.PostSettlementOccupancyAgreement,
      description:
        "Fill out the post-settlement occupancy agreement if applicable.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.PostSettlementOccupancyAgreement,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.PostSettlementOccupancyAgreement,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.PostSettlementOccupancyAgreement,
        completed: false,
      },
    },
    // ANNE ARUNDEL COUNTY DISCLOSURES
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Anne Arundel County Disclosures",
      taskType: "standard",
      label: "Complete Anne Arundel County Disclosures",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.AnneArundelCountyDisclosures,
      description: "Complete required disclosures for Anne Arundel County.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.AnneArundelCountyDisclosures,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.AnneArundelCountyDisclosures,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.AnneArundelCountyDisclosures,
        completed: false,
      },
    },
    // BALTIMORE CITY DISCLOSURES
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Baltimore City Disclosures",
      taskType: "standard",
      label: "Complete Baltimore City Disclosures",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.BaltimoreCityDisclosures,
      description: "Complete required disclosures for Baltimore City.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.BaltimoreCityDisclosures,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.BaltimoreCityDisclosures,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.BaltimoreCityDisclosures,
        completed: false,
      },
    },
    // BALTIMORE COUNTY DISCLOSURES
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Baltimore County Disclosures",
      taskType: "standard",
      label: "Complete Baltimore County Disclosures",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.BaltimoreCountyDisclosures,
      description: "Complete required disclosures for Baltimore County.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.BaltimoreCountyDisclosures,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.BaltimoreCountyDisclosures,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.BaltimoreCountyDisclosures,
        completed: false,
      },
    },
    // HOWARD COUNTY DISCLOSURES
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Howard County Disclosures",
      taskType: "standard",
      label: "Complete Howard County Disclosures",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.HowardCountyDisclosures,
      description: "Complete required disclosures for Howard County.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.HowardCountyDisclosures,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.HowardCountyDisclosures,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.HowardCountyDisclosures,
        completed: false,
      },
    },
    // MONTGOMERY COUNTY DISCLOSURES
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Montgomery County Disclosures",
      taskType: "standard",
      label: "Complete Montgomery County Disclosures",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.MontgomeryCountyDisclosures,
      description: "Complete required disclosures for Montgomery County.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.MontgomeryCountyDisclosures,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.MontgomeryCountyDisclosures,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.MontgomeryCountyDisclosures,
        completed: false,
      },
    },
    // QUEEN ANNE’S COUNTY DISCLOSURES
    {
      taskId: null,
      offerId: null,
      dealId: null,
      taskTitle: "Queen Anne's County Disclosures",
      taskType: "standard",
      label: "Complete Queen Anne's County Disclosures",
      date: null,
      status: "pending",
      state: "MD",
      modalId: ModalID.QueenAnnesCountyDisclosures,
      description: "Complete required disclosures for Queen Anne's County.",
      seller: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.QueenAnnesCountyDisclosures,
        completed: false,
      },
      buyer: {
        required: true,
        optional: false,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.QueenAnnesCountyDisclosures,
        completed: false,
      },
      agent: {
        required: false,
        optional: true,
        inputType: "modal",
        documentId: null,
        modalId: ModalID.QueenAnnesCountyDisclosures,
        completed: false,
      },
    },
  ],
};
