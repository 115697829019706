// src/components/dashboard/DetailsColumn.tsx

import React from "react";
import MockHomeList from "../../mocks/mockData/mockHomeList.json"; // Ensure the JSON file is updated
import { useDashboard } from "../../context/DashboardContext";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const DetailsContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  flex: 0 0 auto; // Don't grow, don't shrink, be the size of your content.
  overflow: auto;
`;

const HomeImage = styled.img`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 5px;
`;

const Address = styled.h3`
  font-size: 1em;
  margin-bottom: 10px;
`;

const Price = styled.div`
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Stat = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9em;
`;

const Description = styled.p`
  font-size: 0.9em;
  margin-bottom: 20px;
  color: #666;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background: #eee;
  cursor: pointer;

  &:hover {
    background: #ddd;
  }
`;

const DetailsColumn = () => {
  const { propertySelected } = useDashboard();
  const [mockHomes, setMockHomes] = React.useState(MockHomeList);
  const [home, setHome] = React.useState(
    mockHomes.find((home) => home.id === propertySelected)
  );

  React.useEffect(() => {
    setHome(mockHomes.find((home) => home.id === propertySelected));
  }, [propertySelected, mockHomes]);

  if (!home) return null;

  return (
    <DetailsContainer>
      <Typography variant="h6">Details</Typography>
      <HomeImage src={home.imgUrl} alt="Home" />
      <Address>{home.address}</Address>
      <Price>{home.price}</Price>
      <StatsContainer>
        <Stat>
          <i className="fas fa-bed"></i> {home.beds} beds
        </Stat>
        <Stat>
          <i className="fas fa-bath"></i> {home.baths} baths
        </Stat>
        <Stat>
          <i className="fas fa-ruler-combined"></i> {home.sqft} sq ft
        </Stat>
      </StatsContainer>
      <Description>{home.description}</Description>
      <ButtonGroup>
        <Button>Tax Details</Button>
        <Button>School Details</Button>
      </ButtonGroup>
    </DetailsContainer>
  );
};

export default DetailsColumn;
