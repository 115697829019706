import React, { useState } from "react";
import styled from "styled-components";
import NavLink from "./NavLink";
import Logo from "./Logo";
import AuthButton from "./AuthButton";
import MenuIcon from "@mui/icons-material/Menu"; // Import MenuIcon from @mui/icons-material
import Avatar from "@mui/material/Avatar"; // Import Avatar from @mui/material
import Popover from "@mui/material/Popover"; // Import Popover from @mui/material
import IconButton from "@mui/material/IconButton"; // Import IconButton from @mui/material
import { useDashboard } from "../../context/DashboardContext";
import Badge from "@mui/material/Badge";

const Nav = styled.nav`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 0 20px;
  height: 64px;
`;

const NavItemContainer = styled.div`
  display: flex;
`;

const NavItemContainerRight = styled.div`
  display: flex;
  margin-left: auto;
`;

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { unreadNotificationCount } = useDashboard(); // Get unreadNotificationCount from context

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Nav>
      <Logo />
      <NavItemContainer>
        <NavLink to="/buy">Buy</NavLink>
        <NavLink to="/sell">Sell</NavLink>
        <NavLink to="/pdf">Docs</NavLink>
      </NavItemContainer>
      <NavItemContainerRight>
        <NavLink to="/buy">Saved Homes</NavLink>
        <Badge
          badgeContent={unreadNotificationCount}
          color="error"
          invisible={unreadNotificationCount === 0}
        >
          <NavLink to="/dashboard">Dashboard</NavLink>
        </Badge>
      </NavItemContainerRight>
      <AuthButton />
      <IconButton onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <NavLink to="/profile">
          <Avatar />
        </NavLink>
      </Popover>
    </Nav>
  );
};

export default Navbar;
