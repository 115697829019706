// AuthButton.tsx
import React from "react";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext"; // Import useUser from UserContext

const AuthButtonContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  border-color: transparent;
  &:hover {
    border-color: #ccc;
  }
`;

const AuthButton = () => {
  const navigate = useNavigate();
  const { user, logout } = useUser(); // Get user and logout function from context

  const handleAuthClick = () => {
    if (user) {
      logout(); // Call logout function from context
      navigate("/"); // Redirect to home page after logout
    } else {
      navigate("/login"); // Navigate to /login route when button is clicked
    }
  };

  return (
    <AuthButtonContainer>
      <StyledButton variant="outlined" onClick={handleAuthClick}>
        {user ? "Log Out" : "Sign up or Log In"}
      </StyledButton>
    </AuthButtonContainer>
  );
};

export default AuthButton;
