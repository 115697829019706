// src/taskSystem/tasks/sellerReviewOfferTask.tsx

import { Subtask, Task, UserRoleRequirements, FormField, Modal } from "./tasks";

export const sellerReviewOfferTask: Subtask[] = [
  {
    taskId: null,
    offerId: null,
    dealId: null,
    taskTitle: "Seller Review Offer",
    label: "Seller Review Offer",
    date: null,
    status: "pending",
    state: "MD",
    modalId: 2,
    taskType: "review",
    description: "Seller Review Offer",
    seller: {
      required: true,
      optional: false,
      inputType: "modal",
      documentId: null,
      modalId: null,
      completed: true,
    },
    buyer: {
      required: false,
      optional: false,
      inputType: "modal",
      documentId: null,
      modalId: 1,
      completed: true,
    },
    agent: {
      required: false,
      optional: true,
      inputType: "modal",
      documentId: null,
      modalId: null,
      completed: true,
    },
  },
];
