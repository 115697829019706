export interface FormData {
  user_id: number | undefined;
  latitude: string;
  longitude: string;
  home_type: string;
  baths: string;
  beds: string;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  state: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  floors: string;
  basement: boolean;
  garage: boolean;
  garage_spots: string;
  square_footage: string;
  lot_size: string;
  pool: boolean;
  shed: boolean;
  high_ceilings: boolean;
  mud_room: boolean;
  fireplace: boolean;
  hardwood: boolean;
  centralAir: boolean;
  solarPanels: boolean;
  hoa: boolean;
  hoaFees: string;
  clubHouse: boolean;
  yearBuilt: string;
  roofAge: string;
  homeExterior: string;
  garden: boolean;
  updatedKitchen: boolean;
  photos: Array<File>;
  videos: string;
  description: string;
  price: string;
  dishWasher: boolean;
  garbageDisposal: boolean;
  refrigerator: boolean;
  microwave: boolean;
  dryer: boolean;
  trashCompactor: boolean;
  freezer: boolean;
  rangeOven: boolean;
  washer: boolean;
  carpet: boolean;
  laminate: boolean;
  softwood: boolean;
  concrete: boolean;
  linoleumVinyl: boolean;
  tile: boolean;
  slate: boolean;
  otherFloor: boolean;
  patio: boolean;
  central_heating: boolean;
  central_ac: boolean;
  furnished: boolean;
  deck: boolean;
  jettedBathtub: boolean;
  porch: boolean;
  spaJacuzzi: boolean;
  fencedYard: boolean;
  sprinklerSystem: boolean;
  motherInLaw: boolean;
  carport: boolean;
  garageDetached: boolean;
  garageAttached: boolean;
  onStreet: boolean;
  offStreet: boolean;
  noParking: boolean;
  breakfastNook: boolean;
  primaryBath: boolean;
  workshop: boolean;
  diningRoom: boolean;
  solariumAtrium: boolean;
  familyRoom: boolean;
  office: boolean;
  sunRoom: boolean;
  laundryRoom: boolean;
  pantry: boolean;
  walkInCloset: boolean;
  library: boolean;
  recreationRoom: boolean;
  security_systems: boolean;
}

export const categories = [
  "Location",
  "Contact",
  "Details",
  "Layout",
  "Features",
  "Photos",
  "Video",
  "Description",
  "Asking Price",
  "Post Your Home",
];

// In your formDataTypes.ts
export const initialFormData: FormData = {
  user_id: undefined,
  latitude: "",
  longitude: "",
  home_type: "",
  baths: "",
  beds: "",
  address: "",
  address2: "",
  zipCode: "",
  city: "",
  state: "",
  description: "",
  price: "", // named price in params
  square_footage: "",
  lot_size: "",
  garage_spots: "",
  basement: false,
  pool: false,
  shed: false,
  high_ceilings: false,
  mud_room: false,
  fireplace: false,
  solarPanels: false,
  clubHouse: false,
  homeExterior: "", // named exterior tyoe in params
  garden: false,
  updatedKitchen: false,
  yearBuilt: "",
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  dishWasher: false,
  garbageDisposal: false,
  refrigerator: false,
  microwave: false,
  dryer: false,
  trashCompactor: false,
  freezer: false,
  rangeOven: false,
  washer: false,
  carpet: false,
  laminate: false,
  softwood: false,
  concrete: false,
  linoleumVinyl: false,
  tile: false,
  hardwood: false,
  slate: false,
  otherFloor: false,
  patio: false,
  central_heating: false,
  central_ac: false,
  furnished: false,
  deck: false,
  jettedBathtub: false,
  porch: false,
  spaJacuzzi: false,
  fencedYard: false,
  sprinklerSystem: false,
  motherInLaw: false,
  carport: false,
  garageDetached: false,
  garageAttached: false,
  onStreet: false,
  offStreet: false,
  noParking: false,
  breakfastNook: false,
  primaryBath: false,
  workshop: false,
  diningRoom: false,
  solariumAtrium: false,
  familyRoom: false,
  office: false,
  sunRoom: false,
  laundryRoom: false,
  pantry: false,
  walkInCloset: false,
  library: false,
  recreationRoom: false,
  videos: "",

  // unused values
  floors: "",
  garage: false,
  centralAir: false,
  hoa: false,
  hoaFees: "",
  roofAge: "",
  photos: [],
  security_systems: false,
};
