// src/globalStyles.ts
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  /* Firefox scrollbar styles */
  * {
    scrollbar-width: thin; /* Makes scrollbars thinner in Firefox */
    scrollbar-color: #888 #f1f1f1; /* Thumb and track color in Firefox */
  }
`;

export default GlobalStyles;
