// src/taskModals/modalRegistry.ts
import { ModalID } from "./modalIds"; // Import ModalID from the new file

import { InitialOffer } from "./modals/InitialOffer";
import { SellerReviewOffer } from "./modals/SellerReviewOffer";
import { SellerCounterOffer } from "./modals/SellerCounterOffer";
import { BuyerCounterOffer } from "./modals/BuyerCounterOffer";
import { BuyerReviewOffer } from "./modals/BuyerReviewOffer";
import { GeneralContractProvisionsModal } from "./modals/GeneralContractProvisionsModal";
import { BuyerPreApprovalDocumentationModal } from "./modals/BuyerPreApprovalDocumentationModal";
import { ConventionalFinancingModal } from "./modals/ConventionalFinancingModal";
import { FHAFinancingModal } from "./modals/FHAFinancingModal";
import { VAFinancingModal } from "./modals/VAFinancingModal";
import { PropertyInspectionsModal } from "./modals/PropertyInspectionsModal";
import { MDResidentialPropertyDisclosuresModal } from "./modals/MDResidentialPropertyDisclosuresModal";
import { MarylandResidentialPropertyDisclaimerModal } from "./modals/MarylandResidentialPropertyDisclaimerModal";
import { DisclosureOfInclusionsExclusionsModal } from "./modals/DisclosureOfInclusionsExclusionsModal";
import { BuyersSaleFinancingSettlementModal } from "./modals/BuyersSaleFinancingSettlementModal";
import { NoticeToBuyerOfHOAActModal } from "./modals/NoticeToBuyerOfHOAActModal";
import { PostSettlementOccupancyAgreementModal } from "./modals/PostSettlementOccupancyAgreementModal";
import { AnneArundelCountyDisclosuresModal } from "./modals/AnneArundelCountyDisclosuresModal";
import { BaltimoreCityDisclosuresModal } from "./modals/BaltimoreCityDisclosuresModal";
import { BaltimoreCountyDisclosuresModal } from "./modals/BaltimoreCountyDisclosuresModal";
import { HowardCountyDisclosuresModal } from "./modals/HowardCountyDisclosuresModal";
import { MontgomeryCountyDisclosuresModal } from "./modals/MontgomeryCountyDisclosuresModal";
import { QueenAnnesCountyDisclosuresModal } from "./modals/QueenAnnesCountyDisclosuresModal";

// export const ModalID = {
//   InitialOffer: 1,
//   SellerReviewOffer: 2,
//   SellerCounterOffer: 3,
//   BuyerCounterOffer: 4,
//   BuyerReviewOffer: 5,
//   // New modal IDs for purchase agreement sections
//   GeneralContractProvisions: 6,
//   BuyerPreApprovalDocumentation: 7,
//   ConventionalFinancing: 8,
//   FHAFinancing: 9,
//   VAFinancing: 10,
//   PropertyInspections: 11,
//   MDResidentialPropertyDisclosures: 12,
//   MarylandResidentialPropertyDisclaimer: 13,
//   DisclosureOfInclusionsExclusions: 14,
//   BuyersSaleFinancingSettlement: 15,
//   NoticeToBuyerOfHOAAct: 16,
//   PostSettlementOccupancyAgreement: 17,
//   AnneArundelCountyDisclosures: 18,
//   BaltimoreCityDisclosures: 19,
//   BaltimoreCountyDisclosures: 20,
//   HowardCountyDisclosures: 21,
//   MontgomeryCountyDisclosures: 22,
//   QueenAnnesCountyDisclosures: 23,

//   // other modal IDs...
// };

export const modalRegistry = {
  [ModalID.InitialOffer]: InitialOffer,
  [ModalID.SellerReviewOffer]: SellerReviewOffer,
  [ModalID.SellerCounterOffer]: SellerCounterOffer,
  [ModalID.BuyerCounterOffer]: BuyerCounterOffer,
  [ModalID.BuyerReviewOffer]: BuyerReviewOffer,
  // other modals...
  [ModalID.GeneralContractProvisions]: GeneralContractProvisionsModal,
  [ModalID.BuyerPreApprovalDocumentation]: BuyerPreApprovalDocumentationModal,
  [ModalID.ConventionalFinancing]: ConventionalFinancingModal,
  [ModalID.FHAFinancing]: FHAFinancingModal,
  [ModalID.VAFinancing]: VAFinancingModal,
  [ModalID.PropertyInspections]: PropertyInspectionsModal,
  [ModalID.MDResidentialPropertyDisclosures]:
    MDResidentialPropertyDisclosuresModal,
  [ModalID.MarylandResidentialPropertyDisclaimer]:
    MarylandResidentialPropertyDisclaimerModal,
  [ModalID.DisclosureOfInclusionsExclusions]:
    DisclosureOfInclusionsExclusionsModal,
  [ModalID.BuyersSaleFinancingSettlement]: BuyersSaleFinancingSettlementModal,
  [ModalID.NoticeToBuyerOfHOAAct]: NoticeToBuyerOfHOAActModal,
  [ModalID.PostSettlementOccupancyAgreement]:
    PostSettlementOccupancyAgreementModal,
  [ModalID.AnneArundelCountyDisclosures]: AnneArundelCountyDisclosuresModal,
  [ModalID.BaltimoreCityDisclosures]: BaltimoreCityDisclosuresModal,
  [ModalID.BaltimoreCountyDisclosures]: BaltimoreCountyDisclosuresModal,
  [ModalID.HowardCountyDisclosures]: HowardCountyDisclosuresModal,
  [ModalID.MontgomeryCountyDisclosures]: MontgomeryCountyDisclosuresModal,
  [ModalID.QueenAnnesCountyDisclosures]: QueenAnnesCountyDisclosuresModal,
};
