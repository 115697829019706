import React from "react";

const CalendarPage = () => {
  return (
    <div>
      <h2>Calendar</h2>
      <p>This is the Status Board page content.</p>
    </div>
  );
};

export default CalendarPage;
