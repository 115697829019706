import React from "react";
import { Typography, TextField } from "@mui/material";
import { FormData } from "../../utilities/formDataTypes";

interface Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const DescriptionFields: React.FC<Props> = ({ formData, setFormData }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, description: event.target.value });
  };

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Describe Your Home
      </Typography>
      <TextField
        label="Home Description"
        name="description"
        variant="outlined"
        value={formData.description}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
        helperText="Include details like the condition of your home, recent upgrades, and what you love about your home."
      />
    </>
  );
};

export default DescriptionFields;
