import React, { useEffect, useState } from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Favorite,
  FavoriteBorder,
  LocationOn,
  KingBed,
  Bathtub,
  Crop,
} from "@mui/icons-material";
import { useHover } from "../../pages/buy/HoverContext";
import { updateFavorite } from "../../api";
import { useUser } from "../../context/UserContext";

const HomeCard = styled(Card)<{ isHovered: boolean }>`
  margin: 10px;
  flex: 1 1 calc(50% - 20px);
  box-shadow: 0px 0px 7.36111px rgba(0, 42, 70, 0.07);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  border: ${({ isHovered }) => (isHovered ? "2px solid #9d86f4" : "none")};
  transition: border 0.3s ease;
  box-sizing: border-box;
`;

const Media = styled(CardMedia)`
  height: 171px;
  border-radius: 12px 12px 0 0;
  position: relative;
`;

const PriceTag = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  gap: 10px;
  position: absolute;
  left: 8px;
  top: 139px;
  background: #14ae5c;
  border-radius: 4px;
  color: #ffffff;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

const HeartIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

const IconBackground = styled(Box)`
  width: 20px;
  height: 20px;
  background: #f5f3fe;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoRow = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #494454;
`;

const Address = styled(Typography)`
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #3f4650;
  display: flex;
  align-items: center;
`;

const Container = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const HomeListContainer = ({ homes: initialHomes }: { homes: Array<any> }) => {
  const [homes, setHomes] = useState(initialHomes);
  const navigate = useNavigate();
  const { hoveredHomeId, setHoveredHomeId } = useHover();
  const { user, fetchFavorites } = useUser();

  useEffect(() => {
    // Update homes when initialHomes prop changes (e.g., after an API call)
    setHomes(initialHomes);
    if (fetchFavorites) {
      if (user?.user_id) {
        fetchFavorites(user?.user_id);
      }
    }
  }, [initialHomes]);

  const handleCardClick = (homeId: string) => {
    navigate(`/view-home/${homeId}`);
  };

  const handleFavoriteClick = async (homeId: number, isFavorite: boolean) => {
    if (user?.user_id) {
      // Optimistically update the local state
      setHomes((prevHomes) =>
        prevHomes.map((home) =>
          home.home_id === homeId
            ? { ...home, is_favorite: !home.is_favorite }
            : home
        )
      );

      try {
        await updateFavorite(user.user_id, homeId);
        console.log("Favorite status updated successfully");
      } catch (error) {
        console.error("Error updating favorite status:", error);
        // Revert the change if the API request fails
        setHomes((prevHomes) =>
          prevHomes.map((home) =>
            home.home_id === homeId
              ? { ...home, is_favorite: !home.is_favorite }
              : home
          )
        );
      }
    } else {
      console.error("User is not logged in");
    }
  };

  return (
    <Container>
      {homes.map((home: any, index: any) => (
        <HomeCard
          key={index}
          isHovered={home.home_id === hoveredHomeId}
          onMouseEnter={() => setHoveredHomeId(home.home_id)}
          onMouseLeave={() => setHoveredHomeId(null)}
          onClick={() => handleCardClick(home.home_id)}
        >
          <Media
            image={process.env.PUBLIC_URL + home.default_image}
            title="Home Image"
          >
            <PriceTag>Low price</PriceTag>
            <HeartIcon
              onClick={(e) => {
                e.stopPropagation();
                handleFavoriteClick(home.home_id, home.is_favorite);
              }}
            >
              {home.is_favorite ? (
                <Favorite style={{ color: "#9d86f4", fontSize: "24px" }} />
              ) : (
                <FavoriteBorder style={{ color: "white", fontSize: "24px" }} />
              )}
            </HeartIcon>
          </Media>
          <CardContent>
            <Address>
              <LocationOn style={{ color: "#3A3A3A", fontSize: "16px" }} />
              {home.address}
            </Address>
            <Box display="flex" justifyContent="space-between" mt={1}>
              <InfoRow>
                <IconBackground>
                  <KingBed style={{ color: "#9d86f4", fontSize: "12px" }} />
                </IconBackground>
                {home.beds} Beds
              </InfoRow>
              <InfoRow>
                <IconBackground>
                  <Bathtub style={{ color: "#9d86f4", fontSize: "12px" }} />
                </IconBackground>
                {home.baths} Baths
              </InfoRow>
              <InfoRow>
                <IconBackground>
                  <Crop style={{ color: "#9d86f4", fontSize: "12px" }} />
                </IconBackground>
                {home.square_footage} sqft
              </InfoRow>
            </Box>
            <Typography
              variant="h6"
              component="h2"
              sx={{ color: "#9d86f4", marginTop: "12px" }}
            >
              ${Number(home.price).toLocaleString()}
            </Typography>
          </CardContent>
        </HomeCard>
      ))}
    </Container>
  );
};

export default HomeListContainer;
