// src/context/SearchContext.tsx

import React, { createContext, useContext, useState, ReactNode } from "react";

interface SearchParams {
  city?: string;
  zipCode?: string;
  address?: string;
  beds?: string;
  baths?: string;
  minPrice?: string;
  maxPrice?: string;
  square_footage?: string;
  home_type?: string;
  lot_size?: string;
  garage_spots?: string;
  basement?: boolean | null;
  waterfront?: boolean | null;
  hoa?: boolean | null;
  pictures_available?: boolean | null;
  year_built?: string;
  floors?: string;
  pool?: boolean | null;
  high_ceilings?: boolean | null;
  userId?: number;
}

interface SearchContextType {
  searchParams: SearchParams;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
}

interface SearchProviderProps {
  children: ReactNode;
}

const initialSearchParams: SearchParams = {
  city: "",
  zipCode: "",
  address: "",
};

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const SearchProvider = ({ children }: SearchProviderProps) => {
  const [searchParams, setSearchParams] =
    useState<SearchParams>(initialSearchParams);

  return (
    <SearchContext.Provider value={{ searchParams, setSearchParams }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};
