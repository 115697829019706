// src/taskModals/modals/PropertyInspectionsModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const PropertyInspectionsModal: Modal = {
  modalId: ModalID.PropertyInspections,
  title: "Property Inspections",
  formFields: [
    { fieldId: FieldID.home_inspection_contingency },
    { fieldId: FieldID.inspection_due_date },
    // Add other inspection-related fields
  ],
};
