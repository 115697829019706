import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const LogoContainer = styled(Link)`
  font-family: "Lora"; // Replace 'YourFontFamily' with the actual font family if it's a custom one
  font-size: 2rem; // Increased font size
  font-weight: 300; // Lighter font weight, adjust as needed
  letter-spacing: 0.15em; // Adjust letter spacing to match the design
  margin-right: 30px;
  text-decoration: none;
  color: #9d86f4; // Adjust the color to match the image
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); // Optional: adds a subtle shadow for depth, adjust as needed
`;

const Logo = () => {
  return <LogoContainer to={"/"}>OFFMKT</LogoContainer>;
};

export default Logo;
