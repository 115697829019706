import React from "react";
import { TextField } from "@mui/material";
import { FormData } from "../../utilities/formDataTypes";

interface Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const VideoFields: React.FC<Props> = ({ formData, setFormData }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <TextField
        label="Video URL"
        name="videos" // Ensure this name aligns with your formData structure
        value={formData.videos} // Ensure formData has a videoUrl property
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
        placeholder="Enter video URL here"
      />
    </>
  );
};

export default VideoFields;
