import React from "react";
import { Button, Typography, Box, Paper, IconButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormData } from "../../utilities/formDataTypes";

interface Props {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const PhotosFields: React.FC<Props> = ({ formData, setFormData }) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files);
      setFormData((prev) => ({
        ...prev,
        photos: [...prev.photos, ...fileArray],
      }));
    }
  };

  const handleRemovePhoto = (index: number) => {
    const filteredPhotos = formData.photos.filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, photos: filteredPhotos }));
  };

  return (
    <>
      <Typography variant="body2" gutterBottom>
        Note: White Glove clients benefit from complimentary professional
        photography and a tailored marketing plan, designed to accelerate the
        process and maximize profits.
      </Typography>
      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          cursor: "pointer",
          borderStyle: "dashed",
          borderColor: "primary.main",
          bgcolor: "background.paper",
          minHeight: "120px",
          marginTop: "10px",
        }}
      >
        <input
          accept="image/png, image/jpeg, image/jpg"
          style={{ display: "none" }}
          id="photo-upload"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="photo-upload">
          <Box sx={{ textAlign: "center" }}>
            <CloudUploadIcon color="primary" sx={{ fontSize: 40 }} />
            <Typography variant="body1">
              Drag and drop or browse your files
            </Typography>
            <Typography variant="caption">PNG, JPEG and JPG only</Typography>
          </Box>
        </label>
      </Paper>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      >
        {formData.photos.map((file, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{ position: "relative", width: "100px" }}
          >
            <img
              src={URL.createObjectURL(file)}
              alt={`Preview ${index}`}
              style={{ width: "100%", height: "auto" }}
            />
            <Typography variant="caption" sx={{ textAlign: "center" }}>
              {file.name}
            </Typography>
            <IconButton
              size="small"
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "error.main",
              }}
              onClick={() => handleRemovePhoto(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Paper>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      ></Box>
    </>
  );
};

export default PhotosFields;
