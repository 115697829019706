import React, { useEffect, useState } from "react";
import { useDashboard } from "../../context/DashboardContext";
import { useUser } from "../../context/UserContext";
import { getUserHomes } from "../../api";
import KingBedOutlinedIcon from "@mui/icons-material/KingBedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import CropOutlinedIcon from "@mui/icons-material/CropOutlined";

const HomeColumn = () => {
  const {
    setPropertySelected,
    viewMode,
    setViewMode,
    allConnections,
    setRecipientId,
    setCurrentConnection,
  } = useDashboard();
  const [selectedHome, setSelectedHome] = useState<number | null>(null);
  const [homes, setHomes] = useState<any[]>([]);
  const { user } = useUser();

  useEffect(() => {
    if (viewMode === "seller") {
      const fetchUserHomes = async () => {
        if (!user) return;
        const fetchHomes = await getUserHomes(user.user_id);
        const userHomes = fetchHomes?.data || [];
        setHomes(userHomes);
      };
      fetchUserHomes();
    } else if (viewMode === "buyer") {
      const buyerHomes = allConnections
        .filter((connection) => connection.role === "Buyer")
        .map((connection: any) => ({
          ...connection,
          address: connection.address,
          price: connection.price,
          beds: connection.beds,
          baths: connection.baths,
          square_footage: connection.square_footage,
          user_id: connection.user_id,
          images: [
            { is_default: 1, image_src: "https://via.placeholder.com/44" },
          ],
        }));
      setHomes(buyerHomes);
    }
  }, [user, viewMode, allConnections]);

  const handleSelectHome = (homeId: number, userId: number) => {
    console.log("Home selected:", homeId, "User ID:", userId);
    setSelectedHome(homeId);
    setPropertySelected(homeId);
    if (viewMode === "buyer") {
      console.log("test on user id");
      setRecipientId(userId);
    } else {
      setRecipientId(null);
    }
    const connection = allConnections.find((conn) => conn.home_id === homeId);
    console.log("Connection selected:", connection);

    setCurrentConnection(connection);
  };

  const getHomeImage = (home: any) => {
    if (home.images && home.images.length > 0) {
      const defaultImage = home.images.find((img: any) => img.is_default === 1);
      return defaultImage ? defaultImage.image_src : home.images[0].image_src;
    }
    return "https://via.placeholder.com/44";
  };

  const handleViewModeChange = (mode: string) => {
    setViewMode(mode);
  };

  // Inline styles
  const styles = {
    scrollableContainer: {
      height: "calc(100vh - 200px)",
      overflowY: "auto",
      display: "flex",
      flex: 0.5,
      marginBottom: "20px",
      borderRadius: "12px",
      flexDirection: "column" as const,
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundColor: "white",
    },
    buttonsContainer: {
      borderRadius: "10px",
      minHeight: "46px",
      width: "289px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      border: "1px solid #c5cae9",
      marginBottom: "16px",
      padding: "0 8px",
      boxSizing: "border-box" as const,
      marginTop: "16px",
    },
    actionButton: (selected: boolean) => ({
      borderRadius: "8px",
      width: "136px",
      height: "30px",
      fontWeight: "bold",
      backgroundColor: selected ? "#9d86f4" : "#f5f5f5",
      color: selected ? "white" : "#9e9e9e",
      border: "none",
      cursor: "pointer",
    }),
    homeCard: (selected: boolean) => ({
      marginBottom: "10px",
      display: "flex",
      padding: "8px",
      minHeight: "104px",
      alignItems: "flex-start",
      backgroundColor: selected ? "#eceff1" : "#fff",
      border: "1px solid",
      borderColor: selected ? "#B19EF6" : "#605F5F1A",
      width: "310px",
      cursor: "pointer",
    }),
    imageBox: {
      width: "44px",
      height: "44px",
      borderRadius: "50%",
      overflow: "hidden" as const,
      marginRight: "10px",
      display: "flex",
    },
    cardInfo: {
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "space-between",
      width: "240px",
    },
    detailIconWrapper: {
      display: "flex",
      alignItems: "center",
      marginRight: "12px",
      backgroundColor: "#f5f3fe",
      padding: "2px 10px",
      borderRadius: "4px",
    },
    detailText: {
      fontSize: "12px",
      fontWeight: "bold" as const,
      color: "#5f4bb6",
      whiteSpace: "nowrap" as const,
      marginLeft: "4px",
    },
    priceTypography: {
      paddingTop: "5px",
      fontWeight: 800,
      color: "#37474f",
      marginTop: "4px",
      fontSize: "15px",
    },
    h2: {
      fontSize: "12px",
      fontWeight: "bold" as const,
      margin: 0,
    },
    noHomesMessage: {
      fontSize: "16px",
      color: "#9e9e9e",
      marginTop: "40px",
      textAlign: "center" as const,
      padding: "0 20px",
    },
  };

  return (
    <div style={styles.scrollableContainer as React.CSSProperties}>
      <div style={styles.buttonsContainer}>
        <button
          style={styles.actionButton(viewMode === "buyer")}
          onClick={() => handleViewModeChange("buyer")}
        >
          Buy
        </button>
        <button
          style={styles.actionButton(viewMode === "seller")}
          onClick={() => handleViewModeChange("seller")}
        >
          Sell
        </button>
      </div>
      {homes.length === 0 ? (
        <div style={styles.noHomesMessage}>
          {viewMode === "buyer"
            ? "You have no homes available to buy at the moment."
            : "You have no homes listed for sale at the moment."}
        </div>
      ) : (
        homes.map((home) => (
          <div
            key={home.home_id || home.connection_id}
            style={styles.homeCard(
              home.home_id === selectedHome ||
                home.connection_id === selectedHome
            )}
            onClick={() => handleSelectHome(home.home_id, home.user_id)}
          >
            <div style={styles.imageBox}>
              <img
                src={getHomeImage(home)}
                alt={home.address}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div style={styles.cardInfo}>
              <h2 style={styles.h2}>{home.address || "Unknown Address"}</h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <div style={styles.detailIconWrapper}>
                  <KingBedOutlinedIcon
                    fontSize="small"
                    style={{ color: "#5f4bb6" }}
                  />
                  <span style={styles.detailText}>{home.beds}</span>
                </div>
                <div style={styles.detailIconWrapper}>
                  <BathtubOutlinedIcon
                    fontSize="small"
                    style={{ color: "#5f4bb6" }}
                  />
                  <span style={styles.detailText}>{home.baths}</span>
                </div>
                <div style={styles.detailIconWrapper}>
                  <CropOutlinedIcon
                    fontSize="small"
                    style={{ color: "#5f4bb6" }}
                  />
                  <span style={styles.detailText}>
                    {home.square_footage} sq ft
                  </span>
                </div>
              </div>
              <div style={styles.priceTypography}>
                $
                {Number(home.price).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default HomeColumn;
