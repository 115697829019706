// src/taskModals/modals/PostSettlementOccupancyAgreementModal.tsx

import { Modal } from "../../tasks/tasks";
import { FieldID } from "../../taskFields/fieldIds";
import { ModalID } from "../modalIds";

export const PostSettlementOccupancyAgreementModal: Modal = {
  modalId: ModalID.PostSettlementOccupancyAgreement,
  title: "Post-Settlement Occupancy Agreement",
  formFields: [
    { fieldId: FieldID.buyer_first_name },
    // Add other relevant fields
  ],
};
