import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/navigationbar/Navbar";
import { getHome, checkUserRole, isOwner } from "../../api/homeApi";
import {
  getConnectionsByHome,
  createConnection,
  getConnectionByBuyerAndHome,
} from "../../api/connectionApi";
import { useUser } from "../../context/UserContext";
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  Card,
  CardMedia,
  Modal,
  IconButton,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HomeIcon from "@mui/icons-material/Home";
import BuildIcon from "@mui/icons-material/Build";
import StraightenIcon from "@mui/icons-material/Straighten";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { Crop } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)({
  padding: 16,
  margin: "16px 0",
  "&:first-of-type": {
    marginTop: 0,
  },
});

const HomeDetailsCard = styled(Card)({
  marginTop: 20,
});

const Media = styled(CardMedia)({
  height: 400,
});

const ImageGrid = styled(Box)({
  display: "flex",
  overflowX: "auto",
  alignItems: "center",
  marginTop: "16px",
  gap: "10px",
  position: "relative",
});

const Thumbnail = styled("img")({
  width: "100px",
  height: "auto",
  cursor: "pointer",
  borderRadius: "5px",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const StyledArrowLeft = styled(IconButton)({
  position: "absolute",
  left: 0,
  zIndex: 1,
  backgroundColor: "rgba(255,255,255,0.7)",
});

const StyledArrowRight = styled(IconButton)({
  position: "absolute",
  right: 0,
  zIndex: 1,
  backgroundColor: "rgba(255,255,255,0.7)",
});

const HomeHeader = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const HomeInfo = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "16px",
  alignItems: "center",
  marginTop: "16px",
});

const HomeInfoItem = styled(Box)({
  textAlign: "center",
});

const DetailIcon = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginBottom: "8px",
});

const FlexRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  flex: 1,
});

const FlexRowContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

const FlexRowItem = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingRight: 30,
});

const IconWithPadding = styled(Box)({
  color: "#9d86f4",
  paddingRight: 5,
});

const SuccessModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 24px;
  padding: 20px;
  text-align: center;
`;

const ImageModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  background-color: white;
  border-radius: 12px;
  box-shadow: 24px;
  padding: 20px;
  text-align: center;
`;

const ViewHome = () => {
  const { homeId } = useParams<{ homeId: string }>();
  const [home, setHome] = useState<any>(null);
  const [role, setRole] = useState<string | null>(null);
  const [connections, setConnections] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [connectionStatus, setConnectionStatus] = useState<string | null>(null);

  const { user } = useUser();

  // Helper function to map connection status to display text
  const getConnectionStatusText = (status: string | null) => {
    switch (status) {
      case "sent":
        return "Connection Sent";
      case "accepted":
        return "Connection Accepted";
      case "rejected":
        return "Connection Rejected";
      case "closed":
        return "Connection Closed";
      default:
        return "Unknown Status";
    }
  };

  // Fetch home details
  useEffect(() => {
    const fetchHome = async () => {
      try {
        const response = await getHome(homeId || "");
        setHome(response.data);
        // Set default image as the main image
        setSelectedImage(response.data.default_image);
      } catch (error) {
        console.error("Error fetching home details:", error);
      }
    };
    fetchHome();
  }, [homeId]);

  const fetchUserRole = async () => {
    if (user && homeId) {
      try {
        // Check if the user is the owner
        const ownerResponse = await isOwner(user.user_id, parseInt(homeId));
        if (ownerResponse.data) {
          setRole("owner");
          return; // Exit early since the user is the owner
        }

        // Check if the user is a buyer or seller
        const response = await checkUserRole(user.user_id, parseInt(homeId));
        if (response.data && response.data.role) {
          setRole(response.data.role);
        } else {
          setRole(null); // User is neither buyer nor seller
        }

        if (response.data && response.data.role === "seller") {
          const connectionsResponse = await getConnectionsByHome(
            parseInt(homeId)
          );
          setConnections(connectionsResponse.data.connections);
        } else {
          // Attempt to fetch the connection status
          const connectionResponse = await getConnectionByBuyerAndHome(
            user.user_id,
            parseInt(homeId)
          );
          if (connectionResponse && connectionResponse.connection) {
            setConnectionStatus(
              connectionResponse.connection.connection_status
            );
            setRole("buyer"); // Set role to buyer since there's a connection
          }
        }
      } catch (error) {
        console.error("Error checking user role:", error);
      }
    }
  };

  useEffect(() => {
    fetchUserRole();
  }, [user, homeId]);

  useEffect(() => {
    const checkOwnership = async () => {
      if (user && homeId) {
        try {
          const response = await isOwner(user.user_id, parseInt(homeId));
          if (response.data) {
            setRole("owner");
          }
        } catch (error) {
          console.error("Error checking ownership:", error);
        }
      }
    };

    checkOwnership();
  }, [user, homeId]);

  const handleRequestConnection = async () => {
    if (user && home) {
      try {
        const response = await createConnection(
          user.user_id,
          parseInt(homeId || "0")
        );
        setModalOpen(true); // Open modal after connection request
        // Update role and connection status
        setConnectionStatus("sent");
        setRole("buyer");
      } catch (error) {
        console.error("Error requesting connection:", error);
      }
    }
  };

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
  };

  // Handle main media click to open modal
  const handleMediaClick = () => {
    setModalImage(selectedImage);
    setImageModalOpen(true);
  };

  // Scroll grid function for navigation arrows
  const scrollLeft = () => {
    const imageGrid = document.getElementById("imageGrid");
    if (imageGrid) {
      imageGrid.scrollBy({ left: -150, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    const imageGrid = document.getElementById("imageGrid");
    if (imageGrid) {
      imageGrid.scrollBy({ left: 150, behavior: "smooth" });
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    fetchUserRole();
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
  };

  if (!home) {
    return <div>Loading...</div>;
  }

  const calculateDaysOnMarket = (createdAt: any) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDiff = Number(currentDate) - Number(createdDate);
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  };
  const daysOnMarket = calculateDaysOnMarket(home.created_at);
  const pricePerSqFt = (home.price / home.square_footage).toFixed(2);

  return (
    <>
      <Navbar />
      <Container>
        <Typography variant="h6" component="h2" fontWeight="bold">
          Property Details
        </Typography>
        <Card sx={{ marginTop: 2 }}>
          <CardMedia
            component="img"
            height="400"
            image={process.env.PUBLIC_URL + "/" + selectedImage}
            alt="Home Image"
            onClick={handleMediaClick}
          />
        </Card>
        {/* Image Thumbnails Section */}
        <Box sx={{ position: "relative", marginTop: 2 }}>
          <Box
            id="imageGrid"
            sx={{
              display: "flex",
              overflowX: "auto",
              alignItems: "center",
              gap: 2,
            }}
          >
            {home.images.map((img: any, index: number) => (
              <img
                key={index}
                src={process.env.PUBLIC_URL + "/" + img.image_src}
                alt={`Thumbnail ${index}`}
                style={{
                  width: "100px",
                  height: "auto",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
                onClick={() => handleImageClick(img.image_src)}
              />
            ))}
          </Box>
          <IconButton
            sx={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(255,255,255,0.7)",
            }}
            onClick={scrollLeft}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(255,255,255,0.7)",
            }}
            onClick={scrollRight}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        <Paper sx={{ padding: 2, marginTop: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontSize: 32, fontWeight: "normal" }}
              >
                {`${home.city}, ${home.state}`}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontSize: 40, fontWeight: "normal" }}
              >
                ${Number(home.price).toLocaleString()}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <BedOutlinedIcon sx={{ color: "#9d86f4" }} />
                  <Typography variant="h6" sx={{ fontSize: 14 }}>
                    {home.beds} Beds
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <BathtubOutlinedIcon sx={{ color: "#9d86f4" }} />
                  <Typography variant="h6" sx={{ fontSize: 14 }}>
                    {home.baths} Baths
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <Crop sx={{ color: "#9d86f4" }} />
                  <Typography variant="h6" sx={{ fontSize: 14 }}>
                    {home.square_footage.toLocaleString()} Sq Ft
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                <RoomOutlinedIcon sx={{ paddingRight: 0.5 }} />
                <Typography variant="subtitle1" color="textSecondary">
                  {home.address}
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              {(() => {
                if (role === "seller" || role === "owner") {
                  // Do not display any button
                  return null;
                } else if (connectionStatus) {
                  return (
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#9D86F4", color: "white" }}
                      disabled
                    >
                      {getConnectionStatusText(connectionStatus)}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#9D86F4" }}
                      onClick={handleRequestConnection}
                    >
                      Request Connection
                    </Button>
                  );
                }
              })()}
            </Box>
          </Box>
          <Divider sx={{ marginY: 2 }} />
          <Typography variant="h6" gutterBottom>
            Description
          </Typography>
          <Typography variant="body1" paragraph>
            {home.description || "No description provided."}
          </Typography>
          <Button variant="text">Show more</Button>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={6} sm={3}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <AccessTimeIcon />
                <Typography variant="body1">
                  {daysOnMarket} days on market
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <HomeIcon />
                <Typography variant="body1">{home.home_type}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <BuildIcon />
                <Typography variant="body1">{home.year_built}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <StraightenIcon />
                <Typography variant="body1">
                  ${pricePerSqFt} per sq ft
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <MonetizationOnIcon />
                <Typography variant="body1">${home.hoa_fee} HOA fee</Typography>
              </Box>
            </Grid>
          </Grid>
          {role === "seller" && (
            <Box mt={4}>
              <Typography variant="h6" gutterBottom>
                Connections for this home
              </Typography>
              {connections.length > 0 ? (
                connections.map((connection) => (
                  <Typography key={connection.connection_id} variant="body1">
                    {connection.buyer_name} - {connection.connection_status}
                  </Typography>
                ))
              ) : (
                <Typography variant="body1">No connections found.</Typography>
              )}
            </Box>
          )}
        </Paper>
      </Container>
      {/* Modal for Connection Request Sent */}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 24,
            padding: 4,
            textAlign: "center",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Connection Request Sent!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your connection request has been sent to the homeowner. You will be
            notified once they respond.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleModalClose}
            sx={{ mt: 3 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
      {/* Modal for larger image view */}
      <Modal
        open={imageModalOpen}
        onClose={handleImageModalClose}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 24,
            padding: 4,
            textAlign: "center",
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/" + modalImage}
            alt="Selected"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ViewHome;
