import { offerTask } from "./tasks/offerTasks";
import { createTask } from "../api/taskApi";
import { sellerReviewOfferTask } from "./tasks/sellerReviewOfferTask";
import { buyerReviewOfferTask } from "./tasks/buyerReviewOfferTask";
import { sellerCounterTask } from "./tasks/sellerCounterTask";
import { buyerCounterTask } from "./tasks/buyerCounterTask";
import { purchaseAgreementTask } from "./tasks/purchaseAgreementTasks";

export const generatePurchaseAgreementTasks = async (
  dealId: number,
  offerId: number
) => {
  // Create the parent task first
  const parentTaskData = {
    offerId: offerId, // No offer associated with this task
    dealId: dealId,
    taskType: purchaseAgreementTask.taskType,
    taskTitle: purchaseAgreementTask.taskTitle,
    label: purchaseAgreementTask.label,
    date: purchaseAgreementTask.date,
    status: purchaseAgreementTask.status,
    state: purchaseAgreementTask.state,
    description: purchaseAgreementTask.description,
    seller: purchaseAgreementTask.seller,
    buyer: purchaseAgreementTask.buyer,
    agent: purchaseAgreementTask.agent,
    modalId: purchaseAgreementTask.modalId,
  };

  const parentTaskResponse = await createTask(parentTaskData);
  const parentTaskId = parentTaskResponse.taskId;

  // Create subtasks with the parentTaskId
  for (const subtask of purchaseAgreementTask.subtasks) {
    await createTask({
      parentTaskId: parentTaskId,
      offerId: offerId,
      dealId: dealId,
      taskType: subtask.taskType,
      taskTitle: subtask.taskTitle,
      label: subtask.label,
      date: subtask.date,
      status: subtask.status,
      state: subtask.state,
      description: subtask.description,
      seller: subtask.seller,
      buyer: subtask.buyer,
      agent: subtask.agent,
      modalId: subtask.modalId,
    });
  }
};

// Function to generate tasks based on offerTasks object
export const generateOfferTasks = async (offerId: number, dealId: number) => {
  // Create the parent task first
  const parentTaskData = {
    offerId: offerId,
    dealId: dealId,
    taskType: offerTask.taskType,
    taskTitle: offerTask.taskTitle,
    label: offerTask.label,
    date: offerTask.date,
    status: offerTask.status,
    state: offerTask.state,
    description: offerTask.description,
    seller: offerTask.seller,
    buyer: offerTask.buyer,
    agent: offerTask.agent,
    modalId: offerTask.modalId,
  };

  const parentTaskResponse = await createTask(parentTaskData);
  const parentTaskId = parentTaskResponse.taskId; // Assuming createTask returns the newly created task's ID

  // Create subtasks with the parentTaskId
  for (const subtask of offerTask.subtasks) {
    await createTask({
      parentTaskId: parentTaskId, // Set parentTaskId to the newly created parent task's ID
      offerId: offerId,
      dealId: dealId,
      taskType: offerTask.taskType,
      taskTitle: subtask.taskTitle,
      label: subtask.label,
      date: subtask.date,
      status: subtask.status,
      state: subtask.state,
      description: subtask.description,
      seller: subtask.seller,
      buyer: subtask.buyer,
      agent: subtask.agent,
      modalId: subtask.modalId,
    });
  }
};

export const generateInitialOfferReviewTask = async (
  offerId: number | undefined,
  dealId: number | undefined,
  parentTaskId: number | undefined
) => {
  console.log("Generating initial offer review...");
  console.log("initial offer parentTaskId", parentTaskId);

  sellerReviewOfferTask.forEach(async (subtask) => {
    await createTask({
      parentTaskId: parentTaskId,
      offerId: offerId,
      dealId: dealId,
      taskType: subtask.taskType,
      taskTitle: subtask.taskTitle,
      label: subtask.label,
      date: subtask.date,
      status: subtask.status,
      state: subtask.state,
      description: subtask.description,
      seller: subtask.seller,
      buyer: subtask.buyer,
      agent: subtask.agent,
      modalId: subtask.modalId,
    });
  });
};

export const generateCounterOffer = async (
  offerId: number,
  dealId: number,
  parentTaskId: number,
  userType: string
) => {
  if (userType === "seller") {
    sellerCounterTask.forEach(async (subtask) => {
      await createTask({
        parentTaskId: parentTaskId,
        offerId: offerId,
        dealId: dealId,
        taskType: subtask.taskType,
        taskTitle: subtask.taskTitle,
        label: subtask.label,
        date: subtask.date,
        status: subtask.status,
        state: subtask.state,
        description: subtask.description,
        seller: subtask.seller,
        buyer: subtask.buyer,
        agent: subtask.agent,
        modalId: subtask.modalId,
      });
    });
  } else if (userType === "buyer") {
    buyerCounterTask.forEach(async (subtask) => {
      await createTask({
        parentTaskId: parentTaskId,
        offerId: offerId,
        dealId: dealId,
        taskType: subtask.taskType,
        taskTitle: subtask.taskTitle,
        label: subtask.label,
        date: subtask.date,
        status: subtask.status,
        state: subtask.state,
        description: subtask.description,
        seller: subtask.seller,
        buyer: subtask.buyer,
        agent: subtask.agent,
        modalId: subtask.modalId,
      });
    });
  }
};

export const generateReviewOfferTasks = async (
  offerId: number,
  dealId: number,
  parentTaskId: number,
  userType: string
) => {
  console.log("Generating review offer call...");
  if (userType === "buyer") {
    sellerReviewOfferTask.forEach(async (subtask) => {
      await createTask({
        parentTaskId: parentTaskId,
        offerId: offerId,
        dealId: dealId,
        taskType: subtask.taskType,
        taskTitle: subtask.taskTitle,
        label: subtask.label,
        date: subtask.date,
        status: subtask.status,
        state: subtask.state,
        description: subtask.description,
        seller: subtask.seller,
        buyer: subtask.buyer,
        agent: subtask.agent,
        modalId: subtask.modalId,
      });
    });
  } else if (userType === "seller") {
    buyerReviewOfferTask.forEach(async (subtask) => {
      await createTask({
        parentTaskId: parentTaskId,
        offerId: offerId,
        dealId: dealId,
        taskType: subtask.taskType,
        taskTitle: subtask.taskTitle,
        label: subtask.label,
        date: subtask.date,
        status: subtask.status,
        state: subtask.state,
        description: subtask.description,
        seller: subtask.seller,
        buyer: subtask.buyer,
        agent: subtask.agent,
        modalId: subtask.modalId,
      });
    });
  }
};
