import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getMessagesBetweenUsers, sendMessage } from "../../../api/messageApi";
import { useUser } from "../../../context/UserContext";
import { useDashboard } from "../../../context/DashboardContext";

interface Message {
  id: string;
  sender: string;
  content: string;
  timestamp: string;
  from_id: number;
  sender_name?: string;
  recipient_name?: string;
}

const ChatContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 70vh;
  box-sizing: border-box;
`;

const MessageList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
`;

const MessageBubble = styled.div<{ isUser: boolean }>`
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 70%;
  align-self: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  background-color: ${(props) => (props.isUser ? "#9d86f4" : "#f4f4f8")};
  color: ${(props) => (props.isUser ? "#ffffff" : "#000000")};
  border: 1px solid ${(props) => (props.isUser ? "#9d86f4" : "#e0e0e0")};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const MessageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Sender = styled.span`
  font-weight: bold;
  font-size: 0.85rem;
`;

const Timestamp = styled.span`
  font-size: 0.75rem;
  color: #999999;
`;

const MessageContent = styled.p`
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
`;

const MessageInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 22px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const MessageInput = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
  padding: 10px;
  border-radius: 22px;
  &::placeholder {
    color: #c4c4c4;
  }
`;

const SendButton = styled.button`
  background-color: #9d86f4;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 22px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #6a45ae;
  }
`;

const ChatPage: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useUser();
  const { recipientId } = useDashboard();

  useEffect(() => {
    console.log("Recipient ID:", recipientId);
    if (user && recipientId) {
      getMessagesBetweenUsers(user.user_id, recipientId)
        .then((data: any) => {
          setMessages(data.messages);
          setLoading(false);
        })
        .catch((err: any) => {
          setError(err.message);
          setLoading(false);
        });
    }
  }, [user, recipientId]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    try {
      const content = newMessage;
      if (!user?.user_id || !recipientId) return;
      const recipients = [
        { from_id: user.user_id, to_id: recipientId },
        { from_id: recipientId, to_id: user.user_id },
      ];
      const attachments: any[] = []; // Add attachments if needed

      const response = await sendMessage(
        user.user_id,
        content,
        recipients,
        attachments,
        recipientId
      );
      const sentMessage: Message = {
        id: response.messageId,
        sender: "User",
        content,
        timestamp: new Date().toLocaleTimeString(),
        from_id: user.user_id,
      };

      setMessages([...messages, sentMessage]);
      setNewMessage(""); // Clear the input field after sending a message
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <ChatContainer>
      <MessageList>
        {messages.map((message) => (
          <MessageBubble
            key={message.id}
            isUser={message.from_id === user?.user_id}
          >
            <MessageInfo>
              <Sender>
                {message.from_id === user?.user_id
                  ? "You"
                  : message.sender_name}
              </Sender>
              <Timestamp>{message.timestamp}</Timestamp>
            </MessageInfo>
            <MessageContent>{message.content}</MessageContent>
          </MessageBubble>
        ))}
      </MessageList>
      <MessageInputContainer>
        <MessageInput
          type="text"
          placeholder="Type something..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
        />
        <SendButton onClick={handleSendMessage}>Send</SendButton>
      </MessageInputContainer>
    </ChatContainer>
  );
};

export default ChatPage;
